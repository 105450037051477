import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {

  notifications({ commit, dispatch, state }, user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`notifications/${user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  notification({ commit, dispatch, state }, user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`notification/${user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteNotifications({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('notification-delete', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  lastNotifications({ commit, dispatch, state }, user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`last-notification/${user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setIsSee({ commit, dispatch, state }, notification_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-is-see/${notification_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
