import axios from 'axios';
import {
    BASE_API_URL
} from '../services/api';

const axiosWithHeaders = axios.create({
    baseURL: BASE_API_URL
  });
  
  // Add a request interceptor to add the access token to each request
  axiosWithHeaders.interceptors.request.use(
    config => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  

const state = {
    // state properties
    tasks: [],
    task: null,
    taskStatistique: null
};

const getters = {
    // getters
    tasks: state => state.tasks,
    task: state => state.task,
    taskStatistique: state => state.taskStatistique
};

const mutations = {
    // mutations
    SET_TASKS: (state, tasks) => {
        state.tasks = tasks
    },
    SET_TASK: (state, task) => {
        state.task = task
    },
    SET_TASK_STATISTIQUE: (state, taskStatistique) => {
        state.taskStatistique = taskStatistique
    }
};


const actions = {
    createOrUpdateTask({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(`create-task/project`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    createTask({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(`add-task/project/${taskData.project}`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getTask({
        commit
    }, taskId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`show-task/${taskId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    taskHistory({
        commit
    }, taskId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`task-history/${taskId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    updateTask({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-task/${taskData.task_id}`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    updateTaskDependanceState({
        commit
    }, task_id) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`make-dependance/${task_id}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    listProjectTasks({
        commit
    }, projectId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`project/${projectId}/tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    assigneeTaskToUser({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`assignee-task/${taskData.task_id}/user/${taskData.user}`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addSupervisorToTask({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`add-supervisor/${taskData.supervisor}/task/${taskData.task_id}`, {})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addStartOrEndDate({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(`add-date/${taskData.task_id}`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addStartOrEndTime({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`add-time/${taskData.task_id}`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    validateTask({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`validate-task/${data.task_id}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    rejetedTask({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`rejeted-task/${data.task_id}`, data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    setTaskStatus({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-task/${taskData.task_id}/status`, taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getUserTasks({
        commit
    }, userId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`get-user/${userId}/tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getSupervisorTasks({
        commit
    }, supervisorId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`get-supervisor/${supervisorId}/tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    totalTask({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`get-total-task`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getTaskStatistique({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`statique-tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};