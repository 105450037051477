<template>
    <div class="container">
        <div  v-if="!loaded">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('component.AuthPageComponent.HeadPageComponent.dashboard') }}</strong></h3>
                </div>
                <div class="col-auto ms-auto text-end">
                    <select v-if="defaultType == 'general'" class="form-select" v-model="defaultStatus">
                        <option value="In progress">{{ $t('general.in_progress') }}</option>
                        <option value="Pending">{{ $t('general.pending') }}</option>
                        <option value="Completed">{{ $t('general.completed') }}</option>
                        <option value="Aborded">{{ $t('general.aborded') }}</option>
                    </select>
                    <div v-else class="col-auto ms-auto text-end">
                        <input type="text" v-model="search" class="form-control" :placeholder="$t('general.recherche')">
                    </div>
                </div>
                <div class="col-auto d-none d-sm-block p-0 m-0 me-2">
                    <select class="form-select" v-model="defaultType">
                        <option value="general">{{ $t('general.general') }}</option>
                        <option value="byparent">{{ $t('general.groupe') }}</option>
                    </select>
                </div>
                <div v-if="$can('projects_create')" class="col-auto d-none d-sm-block p-0 m-0 me-2">
                    <router-link to="/add-project" class="btn btn-primary float-end mt-n1">{{ $t('component.AuthPageComponent.HeadPageComponent.new_project') }}</router-link>
                </div>
            </div>
            <div class="modal fade" id="project-description-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ this_project ? this_project.name : 'Description' }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <p>
                                {{ this_project ? this_project.description : 'No description' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <HelloComponentVue :username="getCurrentUser.name" :message="$t('general.message_welcome')">
            </HelloComponentVue>
            <div class="row">
                <div v-if="(projects?.length) && (defaultType == 'general')">
                    <div class="tab tab-vertical">
                        <div v-if="!getFilteredProject.length" class="loader">
                            <h4>No project found for this type of status</h4>
                            <div class="mt-4 mb-4">
                                <div class="spinner-grow text-dark me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-primary me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-secondary me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-success me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-danger me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-warning me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                                <div class="spinner-grow text-info me-2" role="status">
                                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div v-for="(project, key) in getFilteredProject" class="col-sm-6" :key="key">
                                <div class="card">
                                    <div class="card-header px-4 pt-4">
                                        <div class="card-actions float-end">
                                            <router-link v-if="$can('projects_view')" class="btn btn-primary rounded me-2 text-white"
                                                :to="{ name: 'project-detail', params: { id: project.id } }">{{ $t('general.view') }}</router-link>
                                            <router-link v-if="(project.status == 'Pending') && ($can('projects_update'))"
                                                class="btn btn-success rounded text-white me-2"
                                                :to="{ name: 'edit-project', params: { id: project.id } }">Edit</router-link>
                                            <button data-bs-toggle="modal" title="View comment"
                                                data-bs-target="#project-description-modal"
                                                class="btn btn-outline-info eye_btn_project" :data-id="project.id"><i
                                                    class="align-middle fas fa-fw fa-comment-alt"
                                                    :data-id="project.id"></i></button>
                                        </div>
                                        <h5 class="card-title mb-0">{{ project.name }}</h5>
                                        <div>
                                            <span v-if="project.status == 'In progress'" class="badge bg-warning my-2">{{
                                                project.status }}</span>
                                            <span v-if="project.status == 'Pending'" class="badge bg-danger my-2">{{
                                                project.status }}</span>
                                            <span v-if="project.status == 'Aborded'" class="badge bg-danger my-2">{{
                                                project.status }}</span>
                                            <span v-if="project.status == 'Completed'" class="badge bg-success my-2">{{
                                                project.status }}</span>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="card-body">
                                        <p class="mb-0 mt-0 pb-0 pt-0 font-weight-bold">{{ $t('page.services.type_service') }} <span
                                                class="float-end badge bg-info">{{ project.service.name }}</span></p>
                                        <p class="mb-0 mt-0 pb-0 pt-0 font-weight-bold">{{ $t('general.manager') }} <span class="float-end">{{
                                            project.manager ? project.manager.name : '--- ---' }}</span>
                                        </p>
                                        <p class="mb-0 mt-0 pb-0 pt-0 font-weight-bold">{{ $t('general.start_date') }}-{{ $t('general.end_date') }} <span class="float-end">{{
                                            $formatDateDDMMYYYYHHMM(project.start_date) }} -
                                                {{ $formatDateDDMMYYYYHHMM(project.end_date) }}</span></p>
                                        <p class="mb-0 mt-0 pb-0 pt-0 font-weight-bold">{{ $t('general.duration') }} <span class="float-end">
                                                {{ getFormattedDateDiff(project.start_date, project.end_date) }}</span>
                                        </p>
                                        <p class="mb-0 mt-0 pb-0 pt-0 font-weight-bold">{{ $t('general.frequency') }} <span class="float-end">
                                            {{ project.service.type }} : {{ project.service.cyclique_number }} fois</span>
                                        </p>
                                    </div>
                                    <ul class="list-group list-group-flush"
                                        v-if="(project.status != 'Pending') && (project.status != 'Aborded')">
                                        <li class="list-group-item px-4 pb-4">
                                            <p class="mb-2 font-weight-bold">{{ $t('component.AuthPageComponent.HeadPageComponent.progress') }}
                                                <span class="float-end text-danger" v-if="project.is_delayed">
                                                    {{ project.progress }}% (Is delayed)
                                                </span>
                                                <span class="float-end" v-else>
                                                    {{ project.progress }}%
                                                </span>
                                            </p>
                                            <div class="progress progress-sm">
                                                <div v-if="project.progress <= 25"
                                                    class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                                    role="progressbar" :aria-valuenow="project.progress" aria-valuemin="0"
                                                    aria-valuemax="100" :style="'width: ' + project.progress + '%;'">
                                                </div>
                                                <div v-if="(project.progress > 25) && (project.progress <= 90)"
                                                    class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                                                    role="progressbar" :aria-valuenow="project.progress" aria-valuemin="0"
                                                    aria-valuemax="100" :style="'width: ' + project.progress + '%;'">
                                                </div>
                                                <div v-if="project.progress > 90"
                                                    class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                    role="progressbar" :aria-valuenow="project.progress" aria-valuemin="0"
                                                    aria-valuemax="100" :style="'width: ' + project.progress + '%;'">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="list-group list-group-flush" v-else>
                                        <li class="list-group-item px-4 pb-4">
                                            <p class="mb-2 font-weight-bold">Progress
                                                <span class="float-end" v-if="project.status == 'Pending'">
                                                    0%
                                                </span>
                                                <span class="float-end text-warning" v-else>
                                                    {{ project.progress }}%
                                                </span>
                                            </p>
                                            <div class="progress progress-sm">
                                                <div v-if="project.status == 'Pending'" class="progress-bar "
                                                    role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                                    aria-valuemax="100" style="width: 0%;">
                                                </div>
                                                <div v-else class="progress-bar bg-warning" role="progressbar"
                                                    :aria-valuenow="project.progress" aria-valuemin="0" aria-valuemax="100"
                                                    :style="'width: ' + project.progress + '%;'">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="(getFilteredProjectParents.length) && (defaultType == 'byparent')">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ $t('component.AuthPageComponent.HeadPageComponent.title_projet') }}</h3>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div v-for="(parent, index) in getFilteredProjectParents" :key="index" class="col-sm-6">
                            <div class="col-12">
                                <div class="card mb-3">
                                    <div class="card-header text-white" :class="isParentActive(parent) ? 'bg-info' : 'bg-secondary'"><b>{{ parent.parent_name }}</b></div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <p class="mb-0 font-weight-bold">Start Date:</p>
                                                <p class="mb-0">{{ $formatDateDDMMYYYYHHMM(parent.date_start) }}</p>
                                            </div>
                                            <div class="col-sm-2">
                                                <p class="mb-0 font-weight-bold">Country:</p>
                                                <p class="mb-0">{{ parent.country ? parent.country : '-----' }}</p>
                                            </div>
                                            <div class="col-sm-3">
                                                <p class="mb-0 font-weight-bold">Type:</p>
                                                <p class="mb-0">
                                                    <span class="badge bg-warning" v-if="parent.type == 'standard'">STANDARD</span>
                                                    <span class="badge bg-success" v-else>CYCLIQUE</span>
                                                </p>
                                            </div>
                                            <div class="col-sm-2">
                                                <p class="mb-0 font-weight-bold">Nbre projets:</p>
                                                <p class="mb-0 badge bg-secondary">{{ parent.cyclique_number }}</p>
                                            </div>
                                            <div class="col-sm-2 text-end">
                                                <span data-bs-toggle="modal" data-bs-target="#sizedModalLg" class="btn btn-primary" :title="$t('general.view')" @click="showProjects(parent.projects, parent.type)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-eye align-middle btn-link">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="loader">
                    <div class="mt-4 mb-4">
                        <div class="spinner-grow text-dark me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-primary me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-secondary me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-success me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-danger me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-warning me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-info me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                    </div>
                    <router-link to="/add-project" class="btn btn-primary float-end mt-n1">{{ $t('component.AuthPageComponent.HeadPageComponent.create_new_project') }}</router-link>
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-dark me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-secondary me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-success me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-danger me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-warning me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
                <div class="spinner-grow text-info me-2" role="status">
                    <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                </div>
            </div>
            <router-link to="/add-project" class="btn btn-primary float-end mt-n1">{{ $t('component.AuthPageComponent.HeadPageComponent.create_new_project') }}</router-link>
        </div>
        <div class="modal fade" id="sizedModalLg" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="childProject.length">Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <ul v-if="childProject.length" class="list-unstyled">
                            <li v-for="(project, index) in childProject" :key="index">
                                <div class="card mb-3">
                                    <div class="card-header bg-primary text-white">{{ project.name }}</div>
                                    <div class="card-body row">
                                        <div class="col-md-4">
                                            <p><strong>{{ $t('general.description') }}:</strong> {{ project.description }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>{{ $t('general.start_date') }}:</strong> {{ $formatDateDDMMYYYYHHMM(project.start_date) }}</p>
                                            <p><strong>{{ $t('general.end_date') }}:</strong> {{ $formatDateDDMMYYYYHHMM(project.end_date) }}</p>
                                            <p><strong>{{ $t('general.status') }}:</strong> <span class="badge bg-primary">{{ project.status }}</span></p>
                                        </div>
                                        <div class="col-md-4">
                                            <p><strong>{{ $t('component.AuthPageComponent.HeadPageComponent.total_task') }}:</strong> {{ project.total_tasks }}</p>
                                            <p><strong>{{ $t('component.AuthPageComponent.HeadPageComponent.completed_task') }}:</strong> {{ project.completed_tasks }}</p>
                                            <p><strong>{{ $t('component.AuthPageComponent.HeadPageComponent.progress') }}:</strong> {{ project.progress }}%</p>
                                            <p><strong>{{ $t('component.AuthPageComponent.HeadPageComponent.point') }}:</strong> <span class="badge bg-success">{{ project.point }}/20</span></p>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div v-if="childrenParentType.type !== 'cyclique'">
                                            <router-link class="btn btn-primary rounded me-2 text-white" @click="closeModal"
                                                :to="{ name: 'project-detail', params: { id: project.id } }">{{ $t('general.view') }}</router-link>
                                            <router-link @click="closeModal" v-if="project.status === 'Pending'" class="btn btn-success rounded text-white me-2"
                                                :to="{ name: 'edit-project', params: { id: project.id } }">{{ $t('general.edit') }}</router-link>
                                        </div>
                                        <div v-else>
                                            <router-link @click="closeModal" v-if="isTodayBetweenDates(project.start_date, project.end_date)" class="btn btn-primary rounded me-2 text-white"
                                                :to="{ name: 'project-detail', params: { id: project.id } }">{{ $t('general.view') }}</router-link>
                                            <router-link @click="closeModal" v-if="project.status === 'Pending'" class="btn btn-success rounded text-white me-2"
                                                :to="{ name: 'edit-project', params: { id: project.id } }">{{ $t('general.edit') }}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-else class="mt-4 mb-4">
                            <div class="spinner-grow text-dark me-2" role="status">
                                <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-primary me-2" role="status">
                                <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-secondary me-2" role="status">
                                <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('general.fermer') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import $ from 'jquery'
import HelloComponentVue from '../General/HelloComponent.vue'

export default {
    name: 'HeadPageComponent',
    components: {
        HelloComponentVue,
    },
    data() {
        return {
            projects: [],
            parent_projects: [],
            this_project: null,
            services: null,
            loaded: true,
            search: null,
            defaultStatus: "In progress",
            defaultType: 'general',
            childProject: [],
            childrenParentType: "standard"
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        getFilteredProject() {
            if (this.defaultStatus) {
                return this.projects.filter(project => {
                    return project.status === this.defaultStatus;
                })
            }
            return this.allProjects;
        },
        getFilteredProjectParents() {
            if (this.search) {
                return this.parent_projects.filter(project => {
                    return this.search
                        .toLowerCase()
                        .split(" ")
                        .every(x => project.parent_name?.toLowerCase().includes(x))
                })
            }
            return this.parent_projects
        }
    },
    async mounted() {
        await this.initProjects(),
            await this.initServices(),
            await this.initParentProject(),
            $(document).on('click', '.eye_btn_project', (e) => {
                let projectId = e.target.getAttribute('data-id')
                this.projectDetail(projectId)
            }),
            this.loaded = false
    },
    methods: {
        ...mapActions('project', ['getProjects', 'getProject', 'projectByParents']),
        ...mapActions('service', ['getServiceUnarchives']),
        async initParentProject() {
            this.parent_projects = await this.projectByParents()
        },
        async initProjects() {
            try {
                this.projects = await this.getProjects()
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        showProjects(projectList, type) {
            this.childProject = projectList
            this.childrenParentType = type
        },
        isTodayBetweenDates(start_date, end_date) {
            const today = moment();
            const startDate = moment(start_date);
            const endDate = moment(end_date);
            return today.isBetween(startDate, endDate, null, '[]');
        },
        isTodayBetweenDates2(start_date, end_date) {
            const today = moment();
            const startDate = moment(start_date);
            const endDate = moment(end_date);
            return today.isBetween(startDate, endDate, null, '[]');
        },
        isParentActive(parent) {
            for (let project of parent.projects) {
                if (this.isTodayBetweenDates2(project.start_date, project.end_date)) {
                    return true;
                }
            }
            return false;
        },
        closeModal(){
            jQuery('#sizedModalLg').modal('toggle'); // eslint-disable-line no-undef
        },
        async initServices() {
            try {
                this.services = await this.getServiceUnarchives()
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async projectDetail(projectId) {
            let response = await this.getProject(projectId)
            this.this_project = response
        },
        getFormattedDateDiff(date1, date2) {
            if (!date1 || !date2) {
                return "--";
            }

            const diffInMs = new Date(date2) - new Date(date1);
            const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);
            const diffInMonths = Math.floor(diffInDays / 30);
            const diffInYears = Math.floor(diffInMonths / 12);

            if (diffInYears > 0) {
                return diffInYears + " year" + (diffInYears > 1 ? "s" : "");
            } else if (diffInMonths > 0) {
                return diffInMonths + " month" + (diffInMonths > 1 ? "s" : "");
            } else if (diffInDays > 0) {
                return diffInDays + " day" + (diffInDays > 1 ? "s" : "");
            } else if (diffInHours > 0) {
                return diffInHours + " hour" + (diffInHours > 1 ? "s" : "");
            } else {
                return diffInMinutes + " minute" + (diffInMinutes > 1 ? "s" : "");
            }
        }
    }
}
</script>
<style>
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: #f8f8f8;
}

.loader h1 {
    font-size: 36px;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}</style>
    