<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.users.user_type') }}</strong></h3>
                </div>
            </div>
            <div class="row card">
                <div class="card-body">
                    <table class="table datatables-reponsive-usertypes" style="width:100%">
                        <thead>
                            <tr>
                                <th>{{ $t('page.users.user_type') }}</th>
                                <th>{{ $t('page.users.number') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="userType in userTypes" :key="userType.id">
                                <td>{{ userType.user_type }}</td>
                                <td>{{ userType.count }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'usersComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            userTypes: null,
            isDispo: false,
            isStart: false,
        };
    },
    async mounted() {
        await this.initPage()
        const options = {
            responsive: true,
            lengthChange: true,
            lengthMenu:[5,10,15,20,25, 50],
        };
        new DataTable('.datatables-reponsive-usertypes', options);  // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('user_type', ['fetchUserTypes', 'addUserType']),
        async initPage() {            
            try {
                this.userTypes = await this.fetchUserTypes()
            } catch (error) {
                this.this.$errorNotyf(error)
            }
            this.isDispo = true
        },
        handleSuccess(message){
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    