<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.users.grade') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('grades_create')" class="btn btn-light border my-1 me-2"  @click="changeSubmitType('create')" data-bs-toggle="modal" data-bs-target="#grade-modal">{{ $t('page.users.add_grade') }}</button>
                </div>
            </div>
            <div class="modal fade" id="grade-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.grade') }} </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addOrUpdateGrade" class="row g-3 needs-validation">
                                <div v-if="typeSubmitGrade == 'create'">
                                    <div v-for="(grade, key) in gradeFormCreateData" :key="key">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}  <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="grade.name" class="form-control"
                                                    id="validationCustom01" placeholder="Name" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('page.users.please_enter_grade_name') }} 
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <label class="form-label">{{ $t('page.users.cost') }}  <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="number" min="0" v-model="grade.cost" class="form-control"
                                                   placeholder="Cost" required>
                                                <div class="invalid-feedback">
                                                     {{ $t('page.users.please_enter_grade_cost') }} 
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <label class="form-label">{{ $t('page.users.currency') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <select v-model="grade.currency_id" class="form-control">
                                                    <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                                    <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                                        {{ currency.name }} ({{ currency.code }})
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-1 mt-4 pt-1">
                                                <span @click="dropStoreLine(key)" class="btn btn-sm btn-outline-danger"><i
                                                        class="align-middle fas fa-fw fa-trash"></i></span>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <span @click="addLigne" class="btn btn-outline-success"><i
                                            class="align-middle fas fa-fw fa-plus"></i></span>
                                </div>
                                <div v-else class="row">
                                    <div class="col-md-6">
                                        <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}  <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="text" v-model="gradeFormUpdateData.name" class="form-control"
                                            id="validationCustom01" placeholder="Name" required>
                                        <div class="invalid-feedback">
                                                    {{ $t('page.users.please_enter_grade_name') }} 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">{{ $t('page.users.cost') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="number" min="0" v-model="gradeFormUpdateData.cost" class="form-control"
                                           placeholder="Cost" required>
                                        <div class="invalid-feedback">
                                            {{ $t('page.users.please_enter_grade_cost') }} 
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="form-label">{{ $t('page.users.currency') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <select v-model="gradeFormUpdateData.currency_id" class="form-control">
                                            <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                            <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                                {{ currency.name }} ({{ currency.code }})
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">  {{ $t('page.users.save') }} </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row card">
                <div class="card-body">
                    <table class="table datatables-reponsive-grades" style="width:100%">
                        <thead>
                            <tr>
                                <th>{{ $t('page.users.name') }}</th>
                                <th>{{ $t('page.users.cost') }}</th>
                                <th>{{ $t('page.users.currency') }}</th>
                                <th>{{ $t('page.users.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="grade in listGrades" :key="grade.id">
                                <td>{{ grade.grade.name }}</td>
                                <td><span class="badge bg-info">{{ grade.grade.cost }}</span></td>
                                <td><span class="badge bg-info">{{ grade.grade.currency ? grade.grade.currency.code : '' }}</span></td>
                                <td>
                                    <a v-if="$can('grades_update')" href="#" data-bs-toggle="modal" data-bs-target="#grade-modal"
                                        @click="changeSubmitType('update', grade.grade.id)"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-2 align-middle">
                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                            </path>
                                        </svg>
                                    </a>
                                    <span v-else>--</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import { nextTick } from 'vue';
export default {
    name: 'usersComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false,
            user_id: null,
            isStart: false,
            gradeFormCreateData: [
                {name: null, cost: 0, currency_id: '' }
            ],
            gradeFormUpdateData: {
                id: null,
                name: null,
                cost: 0,
                currency_id: ''
            },
            typeSubmitGrade: 'create',
            listGrades: [],
            currencies:[]
        };
    },
    async mounted() {
        await this.initGrades()
        await this.loadCurrencies()
        const options = {
            responsive: true,
            lengthChange: true,
            lengthMenu:[5,10,15,20,25, 50],
        };
        nextTick(() => {
            new DataTable('.datatables-reponsive-grades', options); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('auth', ['getCurrencies']),
        ...mapActions('grade', ['addGrade', 'updateGrade', 'grades', 'grade']),
        async initGrades() {
            this.isDispo = false;
            let response = await this.grades();
            this.listGrades = response;
            this.isDispo = true;
        },
        async loadCurrencies(){
            let response = await this.getCurrencies()
            this.currencies = response
        },
        async changeSubmitType(type, gradeId=null){
            this.typeSubmitGrade = type;
            if (gradeId != null) {
                let response = await this.grade(gradeId);
                if (response) { // Ajout de cette vérification
                    const { id, name, cost, currency_id } = response;
                    this.gradeFormUpdateData = { id, name, cost, currency_id };
                } else {
                    console.error('Grade data not found');
                    // Vous pouvez gérer l'erreur ici, par exemple afficher un message à l'utilisateur
                }
            }
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        dropStoreLine(key) {
            if (this.gradeFormCreateData.length >= 2) {
                this.gradeFormCreateData.splice(key, 1)
            }
        },
        addLigne() {
            this.gradeFormCreateData.push(
                {
                    name: null,
                    cost: 0,
                    currency_id: ''
                }
            )
        },
        async addOrUpdateGrade() {
            try {
                this.isStart = true;
                if (this.typeSubmitGrade == 'create') {
                    await this.addGrade(this.gradeFormCreateData);
                    this.initGrades();
                    this.gradeFormCreateData = [{ name: null, cost: 0, currency_id: '' }];
                } else {
                    if (this.gradeFormUpdateData.id) { // Ajout de cette vérification
                        await this.updateGrade(this.gradeFormUpdateData);
                        this.initGrades();
                        this.gradeFormUpdateData = { name: null, cost: 0, currency_id: '' };
                    } else {
                        console.error('Grade ID not found');
                        // Vous pouvez gérer l'erreur ici, par exemple afficher un message à l'utilisateur
                    }
                }
                this.handleSuccess('Grade successfully added');
                nextTick(() => {
                    jQuery('#grade-modal').modal('toggle'); // eslint-disable-line no-undef
                });
            } catch (error) {
                this.$errorNotyf(error);
            }
            this.isStart = false;
        },
        handleSuccess(message){
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    