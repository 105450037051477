import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import jQuery from 'jquery'
import Helper from '@/mixins/Helper'
import Permissions from '@/mixins/Permissions'

window.i18n = i18n
window.$ = jQuery
global.$ = jQuery

createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .mixin(Helper)
    .mixin(Permissions)
    .mount('#app')