import axiosWithHeaders from '../services/api';

const state = {
    serviceTasks: [],
    serviceTask: null,
};

const getters = {
    getServiceTasks: (state) => state.serviceTasks,
    getServiceTask: (state) => state.serviceTask,
};

const mutations = {
    setServiceTasks: (state, serviceTasks) => {
        state.serviceTasks = serviceTasks;
    },
    setServiceTask: (state, serviceTask) => {
        state.serviceTask = serviceTask;
    },
};

const actions = {
    getServiceTasks({
        commit
    }, serviceId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`services/${serviceId}/service/tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    getServiceTask({
        commit
    }, taskId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`service/task/${taskId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    addServiceTask({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post('create-service/task', taskData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    updateServiceTask({ commit }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-service/task/${taskData.id}`, taskData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
        });
    },
    getServiceTasksByServices({
        commit
    }, serviceId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`services/${serviceId}/service/tasks`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    getServiceTasksByServiceUnarchives({
        commit
    }, serviceId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`services/${serviceId}/service/tasks/unarchive`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    getServiceTasksUnarchive({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('service/tasks/unarchives')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    updateServiceTaskStatus({
        commit
    }, taskData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-service-task-status/${taskData.task_id}/${taskData.status}`, )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};