import axiosWithHeaders from '../services/api';
  
const state = {
    userRoles: [],
};

const getters = {
    getUserRoles: (state) => state.userRoles,
};

const mutations = {
    setUserRoles(state, userRoles) {
        state.userRoles = userRoles;
    },
};

const actions = {

    // User Roles
    fetchUserRoles({
        commit
    }, userId) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`user_roles/${userId}`)
                .then(response => {
                    commit('setUserRoles', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addUserRole({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post(`add-user-role/${data.user_id}/${data.permission_id}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    removeUserRole({
        commit
    }, {
        userId,
        permissionId
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete(`remove-user-role/${userId}/${permissionId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};