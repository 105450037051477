<template>
    <div class="row vh-80">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div class="d-table-cell align-middle">
                <div class="text-center mt-6">
                    <p class="h1">{{ message }}</p>
                    <div class="mb-2 mt-4">
                        <div class="spinner-grow text-dark me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-primary me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-secondary me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-success me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-danger me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-warning me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                        <div class="spinner-grow text-info me-2" role="status">
                            <span class="visually-hidden">{{ $t('page.statistiques.loading') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'EmptyPageComponent',
  props:{
    message:{
        required: false, default: 'Not found',  type: String,
    }
  }
}
</script>
<style></style>