<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.parteners.entreprises') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('entreprises_create')" class="btn btn-primary border" data-bs-toggle="modal"
                        data-bs-target="#new-entreprise-modal">{{ $t('page.parteners.new_entreprise') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-entreprise-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.new_entreprise') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addNewEntreprise" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-6">
                                    <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.name" class="form-control"
                                        id="validationCustom01" :placeholder="$t('general.name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.name') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.phone" class="form-control"
                                        id="validationCustom02" :placeholder="$t('general.phone')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.email" class="form-control"
                                        id="validationCustom03" :placeholder="$t('general.email')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                    <input type="text" v-model="entrepriseFormData.address" class="form-control"
                                        id="validationCustom04" :placeholder="$t('general.address')">
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{
                                        $t('page.parteners.add_new_entreprise') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="update-entreprise-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.update_entreprise') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="_updateEntreprise" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-6">
                                    <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.name" class="form-control"
                                        id="validationCustom01" :placeholder="$t('general.name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.name') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.phone" class="form-control"
                                        id="validationCustom02" :placeholder="$t('general.phone')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.email" class="form-control"
                                        id="validationCustom03" :placeholder="$t('general.email')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.email') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                    <input type="text" v-model="entrepriseFormUpdateData.address" class="form-control"
                                        id="validationCustom04" :placeholder="$t('general.address')">
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{
                                        $t('page.parteners.update_entreprise') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row card">
                <div class="card-body">
                    <table class="table datatables-reponsive-entreprise" style="width:100%">
                        <thead>
                            <tr>
                                <th>{{ $t('page.users.name') }}</th>
                                <th>{{ $t('page.users.number') }}</th>
                                <th>{{ $t('general.email') }}</th>
                                <th>{{ $t('general.address') }}</th>
                                <th>{{ $t('general.status') }}</th>
                                <th>{{ $t('page.users.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entreprise in entreprises" :key="entreprise.id">
                                <td>{{ entreprise.name }}</td>
                                <td>{{ entreprise.phone }}</td>
                                <td>{{ entreprise.email }}</td>
                                <td>{{ entreprise.address }}</td>
                                <td>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input"
                                            :disabled="!$can('entreprises_update')"
                                            :checked="entreprise.status == 'active'"
                                            type="checkbox">
                                    </div>
                                </td>
                                <td>
                                    <a v-if="$can('entreprises_update')" href="#" data-bs-toggle="modal" data-bs-target="#update-entreprise-modal"
                                        @click="loadUpdateData(entreprise)"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-2 align-middle">
                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                            </path>
                                        </svg>
                                    </a>
                                    <span v-else>--</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'EntrepriseComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false,
            user_id: null,
            isStart: false,
            entrepriseFormUpdateData: {},
            typeSubmitEntreprise: 'create',
            entreprises: [],
            entrepriseFormData:{
                name: null,
                address : null,
                phone : null,
                email: null,
                status : 'active'
            },
        };
    },
    async mounted() {
        await this.initEntreprises()
        const options = {
            responsive: true,
            lengthChange: true,
            lengthMenu: [5, 10, 15, 20, 25, 50],
        };
        new DataTable('.datatables-reponsive-entreprise', options);  // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('entreprise', ['fetchEntreprises', 'addEntreprise', 'fetchEntreprise', 'updateEntreprise']),
        async initEntreprises() {
            this.isDispo = false
            let response = await this.fetchEntreprises()
            this.entreprises = response
            this.isDispo = true
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        loadUpdateData(entreprise){
            this.entrepriseFormUpdateData = {
                id: entreprise.id,
                name: entreprise.name,
                address : entreprise.address,
                phone : entreprise.phone,
                email: entreprise.email,
            }
        },
        async addNewEntreprise(){
            try {
                this.isStart = true
                await this.addEntreprise(this.entrepriseFormData)
                this.$successNotyf(this.$t('message.entreprise_success'))
                this.entrepriseFormData.name = null
                this.entrepriseFormData.address = null
                this.entrepriseFormData.email = null
                this.entrepriseFormData.phone = null
                jQuery('#new-entreprise-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initEntreprises()
        },
        async _updateEntreprise(){
            try {
                this.isStart = true
                await this.updateEntreprise(this.entrepriseFormUpdateData)
                this.$successNotyf(this.$t('message.update_entreprise_success'))
                jQuery('#update-entreprise-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initEntreprises()
        },
        handleSuccess(message) {
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    