import axios from 'axios';
import { BASE_API_URL } from '../services/api';
import axiosWithHeaders from '../services/api';
import router from '../router/index'

const state = {
  accessToken: null,
  refreshToken: null,
  currentUser: null,
  accessDeviceToken: null
};

const getters = {
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getCurrentUser: (state) => state.currentUser,
  getAccessDeviceToken: (state) => state.accessDeviceToken
};

const mutations = {
  setTokens(state, { access, refresh, deviceToken }) {
    state.accessToken = access;
    state.refreshToken = refresh;
    state.accessDeviceToken = deviceToken;

    localStorage.setItem('access_token', access);
    localStorage.setItem('access_device_token', deviceToken);
    localStorage.setItem('refresh_token', refresh);
    localStorage.setItem('user', true);
  },

  setDeviceTokens(state, { deviceToken }) {
    state.accessDeviceToken = deviceToken;
    localStorage.setItem('access_device_token', deviceToken);
  },

  removeTokens(state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.accessDeviceToken = null;

    localStorage.removeItem('access_token');
    localStorage.removeItem('access_device_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  },

  setCurrentUser(state, user ){
    state.currentUser = user
  }

  // autres mutations
};

const dataLogout = {
  refresh_token: localStorage.getItem('refresh_token')
}

const actions = {

  // User
  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      loginData.access_device_token = localStorage.getItem('access_device_token');
      axios
        .post(BASE_API_URL + 'login', loginData)
        .then((response) => {
          commit('setTokens', response.data);
          commit('setCurrentUser', response.data.user);
          if (
            !localStorage.getItem('access_device_token') ||
            localStorage.getItem('access_device_token') === 'null'
          ) {
            commit('setDeviceTokens', { deviceToken: response.data.access_device_token });
          }
          resolve(response.data);
        })
        .catch((error) => {
          if (
            !localStorage.getItem('access_device_token') ||
            localStorage.getItem('access_device_token') === 'null'
          ) {
            commit('setDeviceTokens', { deviceToken: error.response.data.access_device_token });
          }
          reject(error.response.data);
        });
    });
  },

  resetPassword({ commit, dispatch, state },email) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'reset-password', { email: email })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
          router.push('/login')
        });
    });
  },
  
  logout({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete('logout', dataLogout)
        .then(response => {
          commit('removeTokens');
          router.push('/login')
        })
        .catch(error => {
          reject(error.response);
          router.push('/login')
        });
    });
  },

  registerUser({ commit, dispatch, state }, userData) {
    return new Promise((resolve, reject) => {
      userData.access_device_token = localStorage.getItem('access_device_token');
      axiosWithHeaders.post('register', userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateUser({ commit, dispatch, state }, userData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update/user/' + userData.id, userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  userRoles({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('user_roles/' + userId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  _currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-current-user')
        .then(response => {
          commit('setCurrentUser', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  checked({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axios.get(BASE_API_URL + 'checked')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getCountries({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('countries')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getCurrencies({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('currencies')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },


  getCriticalities({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('criticalities')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateCriticalitySettings({ commit }, criticalities) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-criticality-settings', criticalities)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },  

  getUserPermissions({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('user-permissions/' + userId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updatePassword({ commit, dispatch, state },passwordData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update/user/' + passwordData.id + '/password', passwordData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  activateOrDeactivateUser({ commit, dispatch, state },userData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('status/user/' + userData.id + '/update', userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  users({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('users')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  auditTrails({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('audit-trails')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  userAuditTrails({ commit, dispatch, state }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('list-audit-trail/user/' + userId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  sendUsersReports({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('send-users-report?/month='+data.month+'&year='+data.year)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUserCompanyOrIndividual({ commit, dispatch, state },type) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('individual-company/' + type + '/users')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  userStat({ commit, dispatch, state },user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('user-statics/' + user_id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUsersByEntreprise({ commit, dispatch, state },entreprise_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('users/entreprise/' + entreprise_id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUsersByStatus({ commit, dispatch, state },status) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('list/users/' + status + '/status')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getUsersByUserTypeName({ commit, dispatch, state }, user_type_name) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('users-types/' + user_type_name)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getAllUsersByUserTypeName({ commit, dispatch, state }, user_type_name) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('all-users-types/' + user_type_name)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUsersByUserType({ commit, dispatch, state }, userTypeId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('users/user-type/' + userTypeId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
