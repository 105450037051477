<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <div class="col-auto d-none d-sm-block">
                        <h3><strong>{{ $t('evaluation.evaluation') }}</strong> <span class="text-muted" v-if="evaluation_delail"> - {{ evaluation_delail.month_evaluation }}</span></h3>
                    </div>
                </div>
            </div>
            <div v-if="dataMonth.length" class="row">
                <MonthStat :dataMonth="dataMonth"></MonthStat>
            </div>
            <div v-if="listEvaluations.length">
                <div v-show="!evaluationContents.evaluation" class="row">
                    <div class="col-md-12 ">
                        <div class="card">
                            <div class="card-body">
                                <div class="row  pt-0 pb-0 mt-0 mb-0" v-for="(my_evaluation, index) in listEvaluations"
                                    :key="index">
                                    <div class="col-md-5">
                                        <h5 class="card-title">{{ my_evaluation.name }}</h5>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="card-text">{{ $t('evaluation.month_evaluation') }} {{ my_evaluation.month_evaluation }}</p>
                                    </div>
                                    <div class="col-md-2">
                                        <p class="card-text"><span class="badge bg-info">{{ $t('evaluation.type') }} {{
                                            my_evaluation.type?.toUpperCase() }}</span></p>
                                    </div>
                                    <div class="col-md-2 d-flex align-items-center justify-content-end">
                                        <button @click="changeEvaluationData(my_evaluation)"
                                            class="btn btn-primary">{{ $t('evaluation.evaluer') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="evaluationContents.evaluation" class="row">
                    <div class="alert alert-danger alert-dismissible" role="alert">
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        <div class="alert-icon">
                            <i class="far fa-fw fa-bell"></i>
                        </div>
                        <div class="alert-message">
                            <strong>{{ $t('evaluation.alert') }}</strong>{{ $t('evaluation.verification') }}
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <h2 class="col-md-10">{{ $t('evaluation.formulaire_evaluation') }}</h2>
                            <div class="col-md-2 d-flex align-items-center justify-content-end">
                                <button @click="closeEvaluationData" class="btn btn-outline-danger"><i
                                        class="align-middle fas fa-fw fa-window-close"></i></button>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="submitForm">
                        <hr>
                        <div class="row">
                            <div class="col-sm-6" v-for="rubrique in collaboratorDatas" :key="rubrique.id">
                                <div class="card mb-3">
                                    <div class="card-header mt-0 mb-0 pb-0">
                                        <h3 class="card-title">{{ rubrique.name }}</h3>
                                    </div>
                                    <div class="card-body pt-0">
                                        <div v-for="template in rubrique.templates" :key="template.id">
                                            <div class="row g-3">
                                                <div
                                                    v-if="template.type_field === 'input' || template.type_field === 'textarea'">
                                                    <label>{{ template.question }}</label>
                                                    <div v-if="template.type_field === 'input'">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                v-model="template.inputValue"
                                                                placeholder="Saisissez votre réponse" required>
                                                            <span v-show="template.notes" class="input-group-text"
                                                                data-bs-toggle="popover" data-bs-placement="top"
                                                                :data-bs-content="template.notes">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="template.type_field === 'textarea'">
                                                        <div class="input-group">
                                                            <textarea class="form-control" v-model="template.textareaValue"
                                                                rows="1" placeholder="Saisissez votre réponse"
                                                                required></textarea>
                                                            <span v-show="template.notes" class="input-group-text"
                                                                data-bs-toggle="popover" data-bs-placement="top"
                                                                :data-bs-content="template.notes">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="template.type_field === 'select'">
                                                    <label>{{ template.question }}
                                                        <span v-show="template.notes" class="text-muted"
                                                            data-bs-toggle="popover" data-bs-placement="top"
                                                            :data-bs-content="template.notes">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </label>
                                                    <select class="form-select" v-model="template.selectValue" required>
                                                        <option v-for="(option, index) in template.data.split(',')"
                                                            :key="index">
                                                            {{ option }}</option>
                                                    </select>
                                                </div>
                                                <div v-else-if="template.type_field === 'radio'">
                                                    <label>{{ template.question }}
                                                        <span v-show="template.notes" class="text-muted"
                                                            data-bs-toggle="popover" data-bs-placement="top"
                                                            :data-bs-content="template.notes">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </label>
                                                    <div v-for="(option, index) in template.data.split(',')" :key="index"
                                                        class="form-check">
                                                        <input class="form-check-input" type="radio" required
                                                            :name="'radio-' + template.id" :id="'radio-' + index"
                                                            :value="option" v-model="template.radioValue">
                                                        <label class="form-check-label" :for="'radio-' + index">{{ option
                                                        }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">{{ $t('evaluation.enregistrer') }}</button>
                    </form>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('evaluation.not_evaluation') }}</h4>
                <span>{{ $t('evaluation.aucune_evaluation') }}</span>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <h4>{{ $t('evaluation.not_evaluation') }}</h4>
            <span>{{ $t('evaluation.chargement') }}</span>
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-dark me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-secondary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-success me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-danger me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-warning me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-info me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MonthStat from '@/components/General/user_month_stat.vue'
export default {
    name: 'EvaluationPage',
    data() {
        return {
            isDispo: false,
            collaboratorDatas: [],
            supervisorDatas: [],
            isLoad: false,
            listEvaluations: [],
            evaluationContents: {
                evaluation: null,
                type: 'collaborator',
                contents: []
            },
            dataMonth: []
        };
    },
    components: {
        MonthStat,
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        await this.initEvaluationsTemplate()
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('evaluation', [
            'getUserListEvaluationNotSubmit',
            'addEvaluationContent',
            'getUserStaticMonth'
        ]),
        ...mapActions('auth', ['_currentUser']),
        async changeEvaluationData(my_eval) {
            this.evaluationContents.evaluation = my_eval.id
            let month_evaluation = my_eval.month_evaluation
            let user_id = this.getCurrentUser.id
            let response = await this.getUserStaticMonth({ id: user_id, date: month_evaluation })
            this.dataMonth = response
        },
        closeEvaluationData() {
            this.evaluationContents.evaluation = null
            this.evaluationContents.contents = []
        },
        async initEvaluationsTemplate() {
            if (this.getCurrentUser) {
                let output = await this.getUserListEvaluationNotSubmit(this.getCurrentUser.id)
                this.listEvaluations = output.evaluation_list
                this.supervisorDatas = output.template.supervisor_evaluation_templates
                this.collaboratorDatas = output.template.collaborator_evaluation_templates
            }
            this.isDispo = true
        },
        formatFormData() {
            for (const rubrique of this.collaboratorDatas) {
                for (const template of rubrique.templates) {
                    const evaluationContent = {
                        template: template.id,
                        reponse: '',
                        justification: ''
                    };

                    if (template.type_field === 'input') {
                        evaluationContent.reponse = template.inputValue;
                    } else if (template.type_field === 'textarea') {
                        evaluationContent.reponse = template.textareaValue;
                    } else if (template.type_field === 'select') {
                        evaluationContent.reponse = template.selectValue;
                    } else if (template.type_field === 'radio') {
                        evaluationContent.reponse = template.radioValue;
                    }
                    if (template.justify) {
                        evaluationContent.justification = template.textareaValue;
                    }
                    this.evaluationContents.contents.push(evaluationContent);
                }
            }
            return this.evaluationContents
        },
        async submitForm() {
            let data = this.formatFormData()
            if (data) {
                try {
                    await this.addEvaluationContent(data)
                    await this.initEvaluationsTemplate()
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.successResponse('Evaluation successful')
                } catch (error) {
                    this.errorResponse(error)
                }
            } else {
                this.errorResponse({ message: 'Erreur! Try again' })
            }
        },
        errorResponse(error) {
            this.$errorNotyf(error)
        },
        successResponse(message) {
            this.$successNotyf(message)
        },
    },
}
</script>