<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <h2 class="col-md-10">{{ $t('evaluation.resultat_evaluation') }} <span class="text-muted" v-if="evaluation_delail"> - {{ evaluation_delail.month_evaluation }}</span></h2>
                    <div v-if="evaluation_delail" class="col-md-2 d-flex align-items-center justify-content-end">
                        <button @click="closeEvaluationData" class="btn btn-outline-danger"><i class="align-middle fas fa-fw fa-window-close"></i></button>
                    </div>
                </div>
            </div>
            <div v-if="listEvaluations.length">
                <div v-show="!evaluationContents.evaluation" class="row">
                    <div class="col-md-6" v-for="(my_evaluation, index) in listEvaluations" :key="index">
                        <div class="card">
                            <div class="card-body">
                                <div class="row  pt-0 pb-0 mt-0 mb-0">
                                    <div class="col-md-6">
                                        <h5 class="card-title">{{ my_evaluation.name }}</h5>
                                        <p class="card-text">{{ $t('evaluation.month_evaluation') }} {{ my_evaluation.month_evaluation }}</p>
                                        <p class="card-text"><span class="badge bg-info">{{ $t('evaluation.type') }} {{ my_evaluation.type?.toUpperCase() }}</span></p>
                                    </div>
                                    <div class="col-md-6 text-end">
                                        <button @click="changeEvaluationData(my_evaluation)" class="btn btn-primary">{{ $t('evaluation.voir') }}</button>
                                        <h5 class="card-title mt-2">{{ my_evaluation.user.name }}</h5>
                                        <p class="card-text">Envoyé: {{ formatCommentDate(my_evaluation.updated_at) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="evaluationContents.evaluation" class="row">
                    <div v-if="dataMonth.length" class="row">
                        <MonthStat :dataMonth="dataMonth"></MonthStat>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body m-sm-3 m-md-5">
                                    <div class="row mb-4">
                                        <div class="col-md-6">
                                            <strong>{{ evaluation_delail?.name }}</strong><br>
                                            <strong>{{ $t('evaluation.collaborateur') }} </strong> {{ evaluation_delail?.user.name }}
                                                <br>
                                            <em>{{ $t('evaluation.evaluation_collaborateur') }}</em>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <button :class="open1 ? 'btn btn-warning' : 'btn btn-primary'"
                                                @click="toggleDetails(1)">{{ open1 ? 'Fermer' : 'Voir' }}</button>
                                        </div>
                                    </div>

                                    <!-- Partie à fermer ou ouvrir -->
                                    <div class="row mb-4" v-show="open1" v-for="rubrique in collaboratorDatas"
                                        :key="rubrique.id">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="text-primary">{{ $t('evaluation.rubrique') }} <strong>{{ rubrique.name }}</strong>
                                                </div>
                                            </div>
                                            <hr class="my-2" />
                                            <div class="col-md-12">
                                                <div v-for="template in rubrique.templates" :key="template.id" class="row">
                                                    <div class="col-md-6">
                                                        <div class="text-muted">{{ template.question }}</div>
                                                    </div>
                                                    <div class="col-md-6 text-md-end">
                                                        <strong>{{ template.evaluation_content?.reponse }}</strong><br>
                                                        <span
                                                            v-show="template.evaluation_content.justification">{{ $t('evaluation.justification') }}
                                                            {{ template.evaluation_content?.justification }}</span>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body m-sm-3 m-md-5">
                                    <div class="row mb-4">
                                        <div class="col-md-6">
                                            <strong>{{ evaluation_delail?.name }}</strong><br>
                                            <strong>{{ $t('evaluation.collaborateur') }} </strong> {{ evaluation_delail?.user.name }} 
                                            <br><em>{{ $t('evaluation.evaluation_responsable') }}</em>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <button :class="open2 ? 'btn btn-warning' : 'btn btn-primary'"
                                                @click="toggleDetails(2)">{{ open2 ? 'Fermer' : 'Voir' }}</button>
                                        </div>
                                    </div>

                                    <!-- Partie à fermer ou ouvrir -->
                                    <div class="row mb-4" v-show="open2" v-for="rubrique in supervisorDatas"
                                        :key="rubrique.id">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="text-primary">{{ $t('evaluation.rubrique') }} <strong>{{ rubrique.name }}</strong>
                                                </div>
                                            </div>
                                            <hr class="my-2" />
                                            <div class="col-md-12">
                                                <div v-for="template in rubrique.templates" :key="template.id" class="row">
                                                    <div class="col-md-6">
                                                        <div class="text-muted">{{ template.question }}</div>
                                                    </div>
                                                    <div class="col-md-6 text-md-end">
                                                        <strong>{{ template.evaluation_content?.reponse }}</strong><br>
                                                        <span
                                                            v-show="template.evaluation_content.justification">{{ $t('evaluation.justification') }}
                                                            {{ template.evaluation_content?.justification }}</span>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('evaluation.not_evaluation_validate') }}</h4>
                <span>{{ $t('evaluation.aucune_evaluation') }}</span>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <h4>{{ $t('evaluation.not_evaluation_validate') }}</h4>
            <span>{{ $t('evaluation.chargement') }}</span>
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-dark me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-secondary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-success me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-danger me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-warning me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-info me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import MonthStat from '@/components/General/user_month_stat.vue'
export default {
    name: 'MyCollaboratorPage',
    data() {
        return {
            isDispo: false,
            collaboratorDatas: [],
            supervisorDatas: [],
            isLoad: false,
            listEvaluations: [],
            evaluationContents: {
                evaluation: null,
                type: 'supervisor',
                contents: []
            },
            evaluation_delail: null,
            open1: false,
            open2: false,
            dataMonth: []
        };
    },
    components: {
        MonthStat,
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        await this.initEvaluationsTemplate()
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('evaluation', [
            'setTemplateEvaluationStat',
            'getUsersEvaluations',
            'addEvaluationContent',
            'getManagerSubordinatesEvaluationContents',
            'getUserStaticMonth'
        ]),
        async changeEvaluationData(my_eval){
            this.isDispo = false
            this.evaluation_delail = my_eval
            let month_evaluation = my_eval.month_evaluation
            let user_id = this.getCurrentUser.id
            try {
                    let resp = await this.getUserStaticMonth({ id: user_id, date: month_evaluation })
                    this.dataMonth = resp
                    let response = await this.getManagerSubordinatesEvaluationContents(my_eval.id)
                    this.supervisorDatas = response.supervisor_evaluation_templates
                    this.collaboratorDatas = response.collaborator_evaluation_templates
                    this.evaluationContents.evaluation = my_eval.id
                } catch (error) {
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.errorResponse(error ? error : {message: 'Erreur! Try again'})
                }
            this.isDispo = true
        },
        closeEvaluationData(){
            this.evaluationContents.evaluation = null
            this.evaluationContents.contents = []
            this.evaluation_delail = null
        },
        toggleDetails(type=1) {
            if(type==1){
                this.open1 = !this.open1
            }else{
                this.open2 = !this.open2
            }
        },
        async initEvaluationsTemplate() {
            if (this.getCurrentUser) {
                let output = await this.getUsersEvaluations()
                this.listEvaluations = output
            }
            this.isDispo = true
        },
        formatCommentDate(date) {
            let formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
            return formattedDate.format('ddd DD MMM YYYY [at] h:mm');
        },
        formatFormData(){
            for (const rubrique of this.supervisorDatas) {
                for (const template of rubrique.templates) {
                    const evaluationContent = {
                        template: template.id,
                        reponse: '',
                        justification: '',
                        type: 'supervisor'
                    };

                    if (template.type_field === 'input') {
                        evaluationContent.reponse = template.inputValue;
                    } else if (template.type_field === 'textarea') {
                        evaluationContent.reponse = template.textareaValue;
                    } else if (template.type_field === 'select') {
                        evaluationContent.reponse = template.selectValue;
                    } else if (template.type_field === 'radio') {
                        evaluationContent.reponse = template.radioValue;
                    }
                    if (template.justify) {
                        evaluationContent.justification = template.textareaValue;
                    }
                    this.evaluationContents.contents.push(evaluationContent);
                }
            }
            return this.evaluationContents
        },
        async submitForm() {
            let data = this.formatFormData()
            if(data){
                try {
                    await this.addEvaluationContent(data)
                    await this.initEvaluationsTemplate()
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.successResponse('Evaluation successful')
                } catch (error) {
                    this.errorResponse(error) 
                }
            }else{
                this.errorResponse({message: 'Erreur! Try again'})
            }
        },
        errorResponse(error){
            this.$errorNotyf(error)
        },
        successResponse(message){
            this.$successNotyf(message)
        },
    },
}
</script>