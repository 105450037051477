import axiosWithHeaders from '../services/api';

const state = {
  permissionList: [],
};

const getters = {
  getPermissions: (state) => state.permissionList
};

const mutations = {
  setPermissions: (state, permissions) => (state.permissionList = permissions),
};

const actions = {
  // Permissions
  fetchPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('permissions')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  fetchPermission({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`permission/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addPermission({ commit }, permissionData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-permission', permissionData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updatePermission({ commit }, { id, permissionData }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-permission/${id}`, permissionData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  removePermission({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`remove-permission/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  currentUserPermissions({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('current-user-permissions')
        .then(response => {
          commit('setPermissions', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
