<template>
    <div>

        <div class="row mb-2 mb-xl-3">
            <div class="col-auto d-sm-block">
                <h3><strong>{{ $t('component.AuthPageComponent.PartenerPageComponent.project') }}</strong></h3>
            </div>

            <!-- <div class="col-auto ms-auto text-end mt-n1">
                <router-link to="/add-project" class="btn btn-primary float-end mt-n1" >{{ $t('component.AuthPageComponent.PartenerPageComponent.make_a_new_project') }}</router-link>
            </div> -->
        </div>
        <HelloComponentVue :username="getCurrentUser.name"
            :message="$t('general.message_welcome')">
        </HelloComponentVue>
        <div class="row">
            <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill p-4">
                    <div class="card-header">
                        <h4>{{ $t('component.AuthPageComponent.PartenerPageComponent.list_of_projects') }}</h4>
                    </div>
                    <table id="datatables-reponsive" class="table table-borderless my-0">
                        <thead>
                            <tr>
                                <th>{{ $t('component.AuthPageComponent.PartenerPageComponent.name') }}</th>
                                <th>{{ $t('component.AuthPageComponent.PartenerPageComponent.type_service') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('component.AuthPageComponent.PartenerPageComponent.date') }}</th>
                                <th>{{ $t('component.AuthPageComponent.PartenerPageComponent.status') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('component.AuthPageComponent.PartenerPageComponent.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="project in projects" :key="project.id">
                                <td>
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <strong>{{ project.name }}</strong>
                                            <div class="text-muted">
                                                <span v-show="project.status == 'Completed'" class="badge bg-success">{{
                                                    project.status }}</span>
                                                <span v-show="project.status == 'In progress'" class="badge bg-warning">{{
                                                    project.status }}</span>
                                                <span v-show="project.status == 'Pending'" class="badge bg-primary">{{
                                                    project.status }}</span>
                                                <span v-show="project.status == 'Aborded'" class="badge bg-danger">{{
                                                    project.status }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <strong>{{ project.service.name }}</strong>
                                </td>
                                <td v-if="project.status == 'Pending'" class="d-none d-xl-table-cell">
                                    <strong>-- -- ----</strong>
                                    <div class="text-muted">
                                        -- -- ----
                                    </div>
                                </td>
                                <td v-else class="d-none d-xl-table-cell">
                                    <strong>{{ $t('general.end') }} : {{ $formatDateDDMMYYYY(project.end_date) }}</strong>
                                    <div class="text-muted">Start :
                                        {{ $formatDateDDMMYYYY(project.start_date) }}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="project.status == 'Pending'">
                                        <div class="d-flex flex-column">
                                            <span class="badge bg-warning">{{ $t('general.pending') }}</span>
                                        </div>
                                    </div>
                                    <div v-if="project.status == 'In progress'">
                                        <div class="d-flex flex-column">
                                            <span class="badge bg-primary">{{ $t('general.in_progress') }}</span>
                                        </div>
                                    </div>
                                    <div v-if="project.status == 'Aborded'">
                                        <div class="d-flex flex-column">
                                            <span class="badge bg-danger">{{ $t('general.aborded') }}</span>
                                        </div>
                                    </div>
                                    <div v-if="project.status == 'Completed'">
                                        <div class="d-flex flex-column">
                                            <span class="badge bg-success">{{ $t('general.completed') }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell text-center">
                                    <router-link class="me-1" title="View" :to="{ name: 'project-detail', params: { id: project.id }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-eye align-middle me-2 btn-link">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </router-link>
                                    <span v-show="project.status == 'Pending'">
                                        <!-- <router-link title="Edit" v-if="project.status == 'Pending'"  class="text-warning me-2" :to="{ name: 'edit-project', params: { id: project.id }}">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-2 align-middle">
                                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                            </svg>
                                        </router-link> -->
                                        <a href="#" :data-id="project.id" class="text-danger delete_btn_pending_project" title="Remove"><svg :data-id="project.id"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-trash align-middle">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                </path>
                                            </svg></a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import $ from 'jquery'
import HelloComponentVue from '../General/HelloComponent.vue'

export default {
    name: 'PartenerPageComponent',
    components: {
        HelloComponentVue,
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    data() {
        return {
            projects: {},
            services: null,
            project_id: null
        }
    },
    mounted() {
        $(document).on('click', '.delete_btn_pending_project', (e) => {
        this.project_id = e.target.getAttribute('data-id')
        Swal.fire({
            title: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteProject_()
            }
        })

      })
        this.initProjects()
        this.initServices()

    },
    methods: {
        ...mapActions('project', ['getProjectFromAParteners', 'getProject', 'deleteProject']),
        ...mapActions('service', ['getServiceUnarchives']),
        async initProjects() {
            this.projects = await this.getProjectFromAParteners(this.getCurrentUser.id)
        },
        async initServices() {
            this.services = await this.getServiceUnarchives()
        },
        async deleteProject_(){
            try {
                let response = await this.deleteProject(this.project_id)
                this.$successNotyf('The project was successfully removed.')
                this.initProjects()
            } catch (error) {
                this.$errorNotyf(error)
            }
        }
    }
}
</script>
<style>

.my-swal-title {
  font-size: 18px;
}

.my-swal-content {
  font-size: 16px;
}

.my-swal-actions {
  margin-top: 10px;
}

.my-swal-confirm, .my-swal-cancel {
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
}

</style>
    