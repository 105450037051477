<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="mb-3">
                <h1 class="h3 d-inline align-middle">{{ $t('page.user-calendar.calendar') }}</h1>
            </div>

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">{{ $t('page.user-calendar.my_month_calendar') }}</h5>
                    <h6 class="card-subtitle text-muted">{{ $t('page.user-calendar.open_source') }}</h6>
                </div>
                <div class="card-body">
                    <div id="fullcalendar"></div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'UserCalendar',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false
        };
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    mounted() {
        this.initPage()
		// var calendarEl = document.getElementById("fullcalendar");
		// var calendar = new FullCalendar.Calendar(calendarEl, {
		// 	themeSystem: "bootstrap",
		// 	initialView: "dayGridMonth",
		// 	initialDate: "2021-07-07",
		// 	headerToolbar: {
		// 		left: "prev,next today",
		// 		center: "title",
		// 		right: "dayGridMonth,timeGridWeek,timeGridDay"
		// 	},
		// 	events: [{
		// 			title: "All Day Event",
		// 			start: "2021-07-01"
		// 		},
		// 		{
		// 			title: "Long Event",
		// 			start: "2021-07-07",
		// 			end: "2021-07-10"
		// 		},
		// 		{
		// 			groupId: "999",
		// 			title: "Repeating Event",
		// 			start: "2021-07-09T16:00:00"
		// 		},
		// 		{
		// 			groupId: "999",
		// 			title: "Repeating Event",
		// 			start: "2021-07-16T16:00:00"
		// 		},
		// 		{
		// 			title: "Conference",
		// 			start: "2021-07-11",
		// 			end: "2021-07-13"
		// 		},
		// 		{
		// 			title: "Meeting",
		// 			start: "2021-07-12T10:30:00",
		// 			end: "2021-07-12T12:30:00"
		// 		},
		// 		{
		// 			title: "Lunch",
		// 			start: "2021-07-12T12:00:00"
		// 		},
		// 		{
		// 			title: "Meeting",
		// 			start: "2021-07-12T14:30:00"
		// 		},
		// 		{
		// 			title: "Birthday Party",
		// 			start: "2021-07-13T07:00:00"
		// 		},
		// 		{
		// 			title: "Click for Google",
		// 			url: "http://google.com/",
		// 			start: "2021-07-28"
		// 		}
		// 	]
		// });
		// setTimeout(function() {
		// 	calendar.render();
		// }, 250)        
        // this.isDispo = true
    },
    methods: {
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        async initPage() {
        }
    }
}
</script>
<style></style>
    