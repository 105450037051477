<template>
    <div class="row text-center container">
        <div class="alert" role="alert">
            <div class="alert-message">
                <h5>{{ this_today }}</h5>
                <h1>{{ $t('general.hello') }}, {{ username }}</h1>
                {{ message }}
            </div>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment';

export default {
    name: 'HelloComponent',
    props:{
      username: { 
        type: String,
        required : true
      },
      message:{
        type: String,
        required : true
      }
    },
    data() {
    return {
      this_today: null,
    }
  },
  mounted() {
    let date = moment();
    let formattedDate = date.format('dddd D MMMM YYYY');
    this.this_today = formattedDate
  },
}
</script>
<style></style>
    