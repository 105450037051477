<template>
  <div class="container mt-5">
    <div class="card">
      <div class="row mt-2 mb-1 m-1">
        <div class="col-auto d-sm-block">
          <h1>{{ $t('page.statistiques.notification') }}</h1>
        </div>
        <div class="col-auto ms-auto text-end mt-n1">
          <button @click="goBack" class="btn btn-secondary" title="Back"><i
              class="align-middle fas fa-fw fa-arrow-left"></i></button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="selectedNotificationIds.length">
        <button class="btn btn-outline-danger" @click="deleteSelectedNotifications">
          <i class="align-middle fas fa-fw fa-trash"></i> Delete
        </button>
        <hr>
      </div>
      <table class="table table-striped" id="datatables-reponsive">
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>{{ $t('page.statistiques.date') }}</th>
            <th>{{ $t('page.statistiques.type') }}</th>
            <th>{{ $t('page.statistiques.notification') }}</th>
            <th>{{ $t('page.statistiques.status') }}</th>
            <th>{{ $t('page.statistiques.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notification_lists" :key="notification.id">
            <td><input class="form-check-input" type="checkbox" @change="updateSelectedNotifications(notification.id)"
                :id="notification.id"></td>
            <td><span class="badge bg-info">{{ $formatDateDDMMYYYYHHMM(notification.created_at) }}</span></td>
            <td><span class="badge bg-primary">{{ notification.type.toUpperCase() }}</span></td>
            <td>{{ notification.notification }}</td>
            <td>
              <span class="badge bg-success" v-if="notification.is_see">I{{ $t('page.statistiques.is_see') }}</span>
              <span class="badge bg-warning" v-else>{{ $t('page.statistiques.is_not_see') }}</span>
            </td>
            <td>
              <button class="btn btn-outline-primary me-2" @click="showDetails(notification)"> <i
                  class="align-middle far fa-fw fa-eye"></i></button>
              <button class="btn btn-outline-danger"
                @click="deleteNotification({ notification_ids: [notification.id] })"><i
                  class="align-middle fas fa-fw fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="showDetailsModal" class="modal" tabindex="-1" role="dialog"
        style="display: block; background-color: rgba(0,0,0,0.5);">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"> {{ $t('page.statistiques.details_de_la_notification') }}</h5>
              <button type="button" class="close" @click="closeDetailsModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p><strong>Date{{ $t('page.statistiques.date') }} : </strong>{{
                $formatDateDDMMYYYYHHMM(selectedNotification.created_at) }}</p>
              <p><strong>Type {{ $t('page.statistiques.type') }}: </strong>{{ selectedNotification.type }}</p>
              <p><strong>Notification{{ $t('page.statistiques.notification') }} : </strong>{{
                selectedNotification.notification }}</p>
              <p><strong>{{ $t('page.statistiques.id_de_donnees') }}: </strong>{{ selectedNotification.data_id }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeDetailsModal">{{ $t('page.statistiques.fermer')
              }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
export default {
  name: 'NotificationsPage',
  data() {
    return {
      notification_lists: [],
      showDetailsModal: false,
      selectedNotification: {},
      user_id: this.$route.params.id,
      selectedNotificationIds: [],
    }
  },
  async mounted() {
    await this.initUserNotifications()
    let table = new DataTable('#datatables-reponsive'); // eslint-disable-line no-undef
  },
  methods: {
    ...mapActions('notification', ['notifications', 'setIsSee', 'deleteNotifications']),
    async initUserNotifications() {
      let response = await this.notifications(this.user_id)
      this.notification_lists = response
    },
    updateSelectedNotifications(notificationId) {
      if (this.selectedNotificationIds.includes(notificationId)) {
        // Si l'ID est déjà dans la liste, le supprimer
        this.selectedNotificationIds = this.selectedNotificationIds.filter(id => id !== notificationId);
      } else {
        // Sinon, l'ajouter à la liste
        this.selectedNotificationIds.push(notificationId);
      }
    },
    deleteSelectedNotifications() {
      if (this.selectedNotificationIds.length === 0) {
        return;
      }
      const payload = { notification_ids: this.selectedNotificationIds };
      this.deleteNotification(payload)
    },
    async showDetails(notification) {
      this.selectedNotification = notification
      this.showDetailsModal = true
      if (!notification.is_see) {
        await this.setIsSee(notification.id)
        await this.initUserNotifications()
      }
    },
    async deleteNotification(data) {
      Swal.fire({
        text: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
        title: 'Attention',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      }).then(async (result) => {  // Ajoutez "async" ici
        if (result.isConfirmed) {
          try {
            await this.deleteNotifications(data);
            this.$successNotyf('Notifications supprimée avec succès');
            this.initUserNotifications();
          } catch (error) {
            this.$errorNotyf(error);
          }
        }
      });
    },

    goBack() {
      window.history.go(-1);
    },
    closeDetailsModal() {
      this.selectedNotification = {}
      this.showDetailsModal = false
    },
  }
}
</script>
<style>
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: #f8f8f8;
}

.loader h1 {
  font-size: 36px;
  margin-bottom: 5px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7986cb;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}</style>