<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-sm-block">
                    <h3><strong>{{ $t('page.tasks.my_tasks') }}</strong></h3>
                </div>
                <!-- <div class="col-auto ms-auto text-end mt-n1">
                    <router-link class="btn btn-primary" title="View" :to="{ name: 'user-calendar', params: { id: getCurrentUser.id }}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-calendar align-middle">
                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="16" y1="2" x2="16" y2="6"></line>
                            <line x1="8" y1="2" x2="8" y2="6"></line>
                            <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg><span class="align-middle"> Calendar</span>
                    </router-link>
                </div> -->
            </div>
            <div v-if="tasks.length" class="row">
                <div class="card">
                    <table id="datatables-reponsive" class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('page.tasks.name') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('page.tasks.due_date') }}</th>
                                <th>{{ $t('page.tasks.status') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('page.tasks.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="task in tasks" :key="task.task.id">
                                <td>
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <strong>{{ task.task.service_task.name }}</strong>
                                            <div class="text-muted">
                                                {{ $t('page.tasks.duration') }} : {{ task.details.duration ? task.details.duration : '--- ---' }}
                                                {{ $t('page.tasks.days') }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell">
                                    <strong>{{ $t('page.tasks.end') }} {{ task.task.end_date ? formatDate(task.task.end_date) : '--- ---'
                                    }}</strong>
                                    <div class="text-muted">
                                        {{ $t('page.tasks.start') }} {{ task.task.start_date ? formatDate(task.task.start_date) : '--- ---' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex flex-column">
                                        <span class="badge bg-success" v-show="task.task.status == 'Completed'">{{
                                            task.task.status }}</span>
                                        <span class="badge bg-danger" v-show="task.task.status == 'Pending'">{{
                                            task.task.status }}</span>
                                        <span class="badge bg-warning" v-show="task.task.status == 'In progress'">{{
                                            task.task.status }}</span>
                                        <span class="badge bg-warning" v-show="task.task.status == 'On pause'">{{
                                            task.task.status }}</span>
                                        <span class="badge bg-danger" v-show="task.task.status == 'Rejected'">{{
                                            task.task.status }}</span>
                                        <span class="badge bg-dark" v-show="task.task.status === 'Aborted'">{{ 
                                            task.task.status }}</span>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell text-end">
                                    <span @click="startTask(task.task.id)" class="btn btn-primary me-1" title="Start"
                                        v-show="task.task.status == 'Pending'">{{ $t('page.tasks.start_task') }}</span>
                                    <span @click="restartTask(task.task.id)" class="btn btn-warning me-1" title="Start"
                                        v-show="task.task.status == 'On pause'">{{ $t('page.tasks.continious_task') }}</span>
                                    <span @click="restartTask(task.task.id)" class="btn btn-primary me-1" title="Start"
                                        v-show="task.task.status == 'Rejected'">{{ $t('page.tasks.back_work') }}</span>
                                    <span v-if="getCurrentUser.user_type.name == 'Head'" @click="pauseTask(task.task.id)" class="btn btn-danger me-1" title="On pause"
                                        v-show="task.task.status == 'In progress'">{{ $t('page.tasks.pause') }}</span>
                                    <span @click="CompletedTask(task.task.id)" title="Finish" class="me-1 btn btn-success"
                                        v-show="task.task.status == 'In progress'">{{ $t('page.tasks.completed') }}</span>
                                    <span class="me-1">
                                        <router-link class="btn btn-secondary" title="View"
                                            :to="{ name: 'task-detail', params: { id: task.task.id } }">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-eye align-middle btn-link">
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                        </router-link>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('page.tasks.no_task_found') }}</h4>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.tasks.loading') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'...'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'tasksComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false,
            tasks: []
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        this.initUserTasks()
        let table = new DataTable('#datatables-reponsive'); // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('auth', ['_currentUser', 'getUsersByUserTypeName']),
        ...mapActions('task_assignment', ['getUserTasks', 'setTaskStatus', 'addStartOrEndTime']),
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        async initUserTasks() {
            this.tasks = await this.getUserTasks(this.getCurrentUser.id)
            this.isDispo = true
        },
        async startTask(taskId) {
            try {
                await this.executeTaskAction(taskId, { start_time: this.todayDate() }, 'Task successfully started');
            } catch (error) {
                this.$errorNotyf(error)
            }
        },

        async restartTask(taskId) {
            try {
                await this.executeTaskAction(taskId, { end_time: null }, 'Successful task continuations');
            } catch (error) {
                this.$errorNotyf(error)
            }
        },

        executeTaskAction(taskId, actionData, successMessage) {
            this.setTaskStatus({ task_id: taskId, status: 'In progress' })
                .then(() => {
                    return this.addStartOrEndTime({ task_id: taskId, ...actionData })
                        .then(() => {
                            this.handleSuccess(successMessage);
                        })
                        .catch(error => {
                            this.$errorNotyf(error)
                        });
                })
                .catch(error => {
                    this.$errorNotyf(error)
                });
        },

        handleSuccess(message) {
            this.$successNotyf(message)
            this.initUserTasks();
        },
        async pauseTask(taskId) {
            try {
                await this.setTaskStatus({ task_id: taskId, status: 'On pause' })
                this.handleSuccess('Task successfully paused');
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async CompletedTask(taskId) {
            try {
                await this.setTaskStatus({ task_id: taskId, status: 'Completed' })
                await this.addStartOrEndTime({ task_id: taskId, end_time: this.todayDate() })
                this.handleSuccess('Task completed successfully');
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        todayDate() {
            const currentDate = new Date();
            return format(currentDate, 'yyyy-MM-dd');
        }
    },

}
</script>
<style>
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: #f8f8f8;
}

.loader h1 {
    font-size: 36px;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
</style>
    