<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.parteners.parteners') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('users_create')" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#new-partener-modal">
                        {{ $t('page.parteners.new_partener') }}
                    </button>
                </div>
            </div>
            <div class="modal fade" id="new-partener-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.new_partener') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3" >
                            <form @submit.prevent="addNewPartener" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="userFormData.name" class="form-control" id="validationCustom01" :placeholder="$t('page.users.name')"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_name') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom03" class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="userFormData.email" class="form-control" id="validationCustom03" placeholder="Email"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_email') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom08" class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="userFormData.address" class="form-control" id="validationCustom08" placeholder="Address">
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_address') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="userFormData.phone" class="form-control" id="validationCustom04" placeholder="Phone"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('general.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="userFormData.country" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id" >{{ country.name }} ({{ country.code }})</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.select_country') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('general.type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" v-model="userFormData.type" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option value="Individual">{{ $t('forms.individual') }}</option>
                                        <option value="Company">{{ $t('forms.company') }}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.select_type') }}
                                    </div>
                                </div>
                                <div v-if="userFormData.type == 'Company'" class="col-md-6">
                                    <label for="validationCustom06" class="form-label">{{ $t('page.parteners.choose_enterprise') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" v-model="userFormData.entreprise" id="validationCustom05">
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option v-for="entreprise in entreprises" :key="entreprise.id" :value="entreprise.id">{{ entreprise.name }}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.choose_enterprise') }}
                                    </div>
                                </div>
                                <div v-if="userFormData.type == 'Company'" class="col-md-6">
                                    <label for="validationCustom06" class="form-label">{{ $t('page.parteners.new_entreprise') }}</label>
                                    <button type="button" @click="showAddEnterpriseModal" class="btn btn-info">{{
                                        $t('page.parteners.add_new_entreprise') }}</button>
                                </div>
                                <div v-if="userFormData.type == 'Company'">          
                                    <div class="modal-body m-3" v-if="showEnterpriseModal">
                                        <form @submit.prevent="addNewEntreprise" class="row g-3 needs-validation" novalidate>
                                            <div class="col-md-6">
                                                <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.name" class="form-control"
                                                    id="validationCustom01" :placeholder="$t('general.name')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.name') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.phone" class="form-control"
                                                    id="validationCustom02" :placeholder="$t('general.phone')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.phone') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.email" class="form-control"
                                                    id="validationCustom03" :placeholder="$t('general.email')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.phone') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                                <input type="text" v-model="entrepriseFormData.address" class="form-control"
                                                    id="validationCustom04" :placeholder="$t('general.address')">
                                            </div>
                                            <div class="col-12">
                                                <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                                    <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                                    <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                                </button>
                                                <button v-show="!isStart" class="btn btn-primary" type="submit">{{
                                                    $t('page.parteners.add_new_entreprise') }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('page.parteners.add_new_partener') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="parteners.length" class="row">
                <div class="col-sm-8">
                    <div class="">
                        <div class="card-header pb-0">
                            <h5 class="card-title mb-0">{{ $t('page.parteners.lists_parteners') }}</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-hover my-0" id="datatables-reponsive">
                                <thead>
                                    <tr>
                                        <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.email') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.country') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.address') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.type') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="partener in parteners" :key="partener.id">
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ partener.name }}
                                            </div>
                                        </td>
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ partener.email }}
                                            </div>
                                        </td>
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ partener.country?.name }} ({{ partener.country.code }})
                                            </div>
                                        </td>
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ partener.address }}
                                            </div>
                                        </td>
                                        <td>
                                            <span v-if="partener.entreprise != null" class="text-success">{{ $t('forms.company') }}</span>
                                            <span v-else class="text-primary">{{ $t('forms.individual') }}</span>
                                        </td>
                                        <td class="table-action">
                                            <span v-if="$can('users_view')" class="btn btn-link view_user" :title="$t('general.view')" @click="userDetails(partener.id)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-eye align-middle btn-link">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                            </span>
                                            <span v-else>--</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="card" v-if="isUser">
                        <div class="row card-header mb-2 mb-xl-3">
                            <div class="col-auto d-none d-sm-block">
                                <h5 class="card-title">
                                    <span>{{ user?.name }}</span>
                                </h5>
                            </div>
    
                            <div class="col-auto text-end">
                                <router-link v-if="$can('user_roles_view')" :to="'user/'+user.id+'/permissions'" class="btn btn-primary btn-sm btn-sm rounded">
                                    {{ $t('page.parteners.define_roles') }}
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm mt-2 mb-4">
                                <tbody>
                                    <tr>
                                        <th>{{ $t('general.name') }}</th>
                                        <td class="text-end">{{ user?.name }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ $t('forms.company') }}</th>
                                        <td class="text-end">
                                            <span v-if="user?.entreprise != null">{{ user?.entreprise.name }}</span>
                                            <span v-else> --- </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{{ $t('general.email') }}</th>
                                        <td class="text-end">{{ user?.email }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ $t('general.phone') }}</th>
                                        <td class="text-end">{{ user?.phone }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{ $t('general.country') }}</th>
                                        <td class="text-end">{{ user?.country.name }} ({{ user?.country?.code }})</td>
                                    </tr>
                                    <tr>
                                        <th>{{ $t('general.status') }}</th>
                                        <td class="text-end">
                                            <span v-show="user?.status == 'active'" class="badge bg-success">{{ $t('general.active') }}</span>
                                            <span v-show="user?.status != 'active'" class="badge bg-warning">{{ $t('general.inactive') }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-if="$can('active_or_desactive_user')" colspan="2" class="text-end">
                                            <span v-show="user?.status == 'active'">
                                                <button @click="changeUserStatus()" class="btn btn-danger text-end" title="Desactivate"><i
                                                        class="fas fa-times"></i>
                                                        {{ $t('page.users.desactivate') }}</button></span>
                                            <span v-show="user?.status != 'active'">
                                                <button @click="changeUserStatus()" class="btn btn-primary text-end" title="Activate">
                                                    {{ $t('page.users.activate') }}</button></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else class="alert alert-secondary alert-dismissible" role="alert">
                        <div class="alert-icon">
                            <i class="far fa-fw fa-bell"></i>
                        </div>
                        <div class="alert-message">
                            <strong>{{ $t('page.parteners.get_details') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('page.parteners.partener_not_found') }}</h4>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                </div>
                <div class="row">
                    <span class="btn btn-outline-primary border" data-bs-toggle="modal"
                            data-bs-target="#new-entreprise-modal">{{ $t('page.parteners.create_entreprise') }}</span>
                    <span class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#new-partener-modal">
                        {{ $t('page.parteners.create_partener') }}</span>
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <h4>{{ $t('page.parteners.partener_not_found') }}</h4>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                    </div>
                </div>
        </div>
    </div>
</template>
 
<script>
import { mapActions } from 'vuex'
export default {
    name: 'partenersComponent',
    data() {
        return {
            isDispo: false,
            parteners: [],
            entreprises: [],
            showEnterpriseModal: false,
            user: null,
            isUser: false,
            isStart: false,
            userFormData:{
                name: null,
                email: null,
                country: '',
                phone: null,
                user_type: null, 
                password: null, 
                type: 'Individual',
                entreprise: null
            },
            entrepriseFormUpdateData: {},
            typeSubmitEntreprise: 'create',
            entrepriseFormData:{
                name: null,
                address : null,
                phone : null,
                email: null,
                status : 'active'
            },
            countries:[]
        }
    },
    async mounted(){
        await this.initPage()
        await this.loadCountries()
        let table = new DataTable('#datatables-reponsive'); // eslint-disable-line no-undef
        new Choices(document.querySelector(".choices-single-country")); // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('auth', ['getAllUsersByUserTypeName', 'registerUser', 'activateOrDeactivateUser', 'getCountries']),
        ...mapActions('entreprise', ['fetchEntreprises', 'addEntreprise']),
        ...mapActions('user_type', ['getUserTypeByName']),
        async initPage() {
            this.parteners = await this.getAllUsersByUserTypeName('Partener')
            this.entreprises = await this.fetchEntreprises()
            this.isDispo = true
        }, 
        async loadCountries(){
            let response = await this.getCountries()
            this.countries = response
        },
        async userDetails(userId){
            this.isUser = false
            this.user = null
            this.user = this.parteners.find(element => element.id === userId);
            this.isUser = true
        },
        showAddEnterpriseModal() {
          this.showEnterpriseModal = true
        },
        async addNewPartener(){
            this.userFormData.password = this.$genererMotDePasse(10)
            let user_type = await this.getUserTypeByName('Partener')
            if(this.userFormData.type == 'Individual'){
                this.userFormData.entreprise = null
            }
            this.userFormData.user_type = user_type.id
            this.isStart = true
            let response
            try {
                response = await this.registerUser(this.userFormData)
                this.$successNotyf(this.$t('message.partener_success'))
                this.userFormData.name = null,
                this.userFormData.email = null,
                this.userFormData.phone = null,
                this.userFormData.user_type = null, 
                this.userFormData.password = null
                this.userFormData.address = null
                this.initPage()
                jQuery('#new-partener-modal').modal('toggle');  // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initPage()
        },
        async addNewEntreprise(){
            try {
                this.isStart = true
                await this.addEntreprise(this.entrepriseFormData)
                this.$successNotyf(this.$t('message.entreprise_success'))
                this.entreprises = await this.fetchEntreprises()
                this.entrepriseFormData.name = null
                this.entrepriseFormData.address = null
                this.entrepriseFormData.email = null
                this.entrepriseFormData.phone = null
                this.showEnterpriseModal = false
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async changeUserStatus(){
            let userId = this.user.id
            await this.activateOrDeactivateUser({id: userId})
            this.initPage()
            this.userDetails(userId)
        },
    },
}
</script>
<style></style>
    