<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <div></div>
                            <div>
                                <div class="btn-group" role="group" aria-label="Navigation">
                                    <button type="button" :class="2 == currentPage ? 'active' : ''" class="btn btn-light"
                                        @click="changePage(2)">{{ $t('page.statistiques.performances') }}</button>
                                    <button type="button" :class="1 == currentPage ? 'active' : ''" class="btn btn-light me-2"
                                        @click="changePage(1)">{{ $t('page.statistiques.statistiques') }}</button>
                                    <!-- <button v-show="!isLoad" type="button" class="btn btn-sm btn-outline-info rounded"
                                        @click="sendAllUsersReport()">Get User Report</button> -->
                                    <button v-show="isLoad" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 1">
            <div class="row gy-5 g-xl-10 mb-5">
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="user-top-employe-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.top_employe') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="user-top-supervisor-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.statistiques') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 2">
            <div class="row p-3">
                <h1>{{ $t('page.statistiques.liste_utilisateurs') }}</h1>
                <table class="table table-striped datatables-reponsive-users" data-page-length='5' data-order='[[ 5, "DESC" ]]'>
                    <thead>
                        <tr>
                            <th>{{ $t('page.statistiques.name') }}</th>
                            <th>{{ $t('page.statistiques.phone') }}</th>
                            <th>{{ $t('page.statistiques.grades') }}</th>
                            <th>{{ $t('page.statistiques.cost') }}</th>
                            <th>{{ $t('page.statistiques.completed') }}</th>
                            <th>{{ $t('page.statistiques.in_progress') }}</th>
                            <th>{{ $t('page.statistiques.pending') }}</th>
                            <th>{{ $t('page.statistiques.performances') }}</th>
                            <th>{{ $t('page.statistiques.total_project') }}</th>
                            <th>{{ $t('page.statistiques.total_task') }}</th>
                            <th>{{ $t('page.statistiques.created_date') }}</th>
                            <th>{{ $t('page.statistiques.action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.user.id">
                            <td>{{ user.user.name }}</td>
                            <td>{{ user.user.phone }}</td>
                            <td>{{ user.user.grade }}</td>
                            <td><span class="badge bg-secondary">{{ formatNumber(user.user.cost) }}</span></td>
                            <td><span class="badge bg-success">{{ user.stats_by_status['Completed'] }} {{ $t('page.statistiques.tasks') }}</span></td>
                            <td><span class="badge bg-warning">{{ user.stats_by_status['In progress'] }} {{ $t('page.statistiques.tasks') }}</span></td>
                            <td><span class="badge bg-danger">{{ user.stats_by_status['Pending'] }} {{ $t('page.statistiques.tasks') }}</span></td>
                            <td>
                                <span v-if="user.average_points > 10" class="badge bg-primary">{{ user.average_points }}/20</span>
                                <span v-else class="badge bg-danger">{{ user.average_points }}/20</span>
                            </td>
                            <td>{{ user.total_projects }}</td>
                            <td>{{ user.total_tasks }}</td>
                            <td>{{ $formatDateDDMMYYYY(user.user.created_at) }}</td>
                            <td>
                                <router-link v-if="user.total_tasks > 0" class="btn btn-primary btn-sm"
                                    :to="{ name: 'user-statistic', params: { id: user.user.id } }">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-eye align-middle">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </router-link>
                                <button v-else disabled class="btn btn-ligth btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-eye align-middle">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Chart from 'chart.js/auto';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { reactive } from "vue";
export default {
    name: 'UserStatistique',
    data() {
        return {
            statistiques: {},
            project_stats: {},
            user_select: 'all',
            top_user: 3,
            users: [],
            nb_tasks: null,
            cost_total: null,
            tasks_by_project: null,
            general: [],
            currentPage: 2,
            projects: [],
            tasks: [],
            show_tasks: false,
            topUser:[],
            topSupervisor: [],
            isLoad: false
        }
    },
    async mounted() {
        await this.loadData()
        const options = {
            responsive: true,
            lengthChange: true,
            select: {
                style: "multi"
            },
            lengthMenu:[5,10,15,20,25, 50],
        };
        let projectTable = new DataTable('.datatables-reponsive-users', options);  // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('statistique', ['usersStats', 'topUserStats', 'topSupervisorStats']),
        ...mapActions('auth', ['sendUsersReports']),
        async loadData() {
            let userStateResponse = await this.usersStats(this.user_select)
            let userTopResponse = await this.topUserStats(this.top_user)
            let supervisorTopResponse = await this.topSupervisorStats(this.top_user)
            this.users = userStateResponse
            this.topUser = userTopResponse
            this.topSupervisor = supervisorTopResponse
            this.drawCharts()
        },
        async sendAllUsersReport(){
            this.isLoad = true
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1; // +1 car les mois sont indexés à partir de 0 (janvier = 0)
            const currentYear = currentDate.getFullYear();
            try {
                await this.sendUsersReports({month:currentMonth, year:currentYear})
                this.$successNotyf('User report successfully sent to your mail')
            } catch (error) {
                this.$errorNotyf(error)
                this.isLoad= false
            }
            this.isLoad= false
        },
        drawCharts() {
            this.drawUserTopChart()
            this.drawSupervisorTopChart()
        },
        drawUserTopChart() {
            if (this.topUserChart !== undefined) {
                this.topUserChart.destroy();
            }
            const topUserData = this.topUser;

            const labels = [];
            const data1 = [];
            const data2 = [];
            const data3 = [];
            const data4 = [];

            topUserData.forEach(item => {
                labels.push(item.user.name);
                data1.push(item.total_projects);
                data2.push(item.total_tasks);
                data3.push(item.total_points);
                data4.push(item.average_points);
            });
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Total Projets',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data1,
                    },
                    {
                        type: 'line',
                        label: 'Total Tasks',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data2,
                    },
                    {
                        type: 'line',
                        label: 'Total points',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data3,
                    },
                    {
                        type: 'line',
                        label: 'Average',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data4,
                    },
                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.topUserChart = new Chart(document.getElementById('user-top-employe-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        drawSupervisorTopChart() {
            if (this.topSupervisorChart !== undefined) {
                this.topSupervisorChart.destroy();
            }
            const topUserData = this.topSupervisor;

            const labels = [];
            const data1 = [];
            const data2 = [];
            const data3 = [];

            topUserData.forEach(item => {
                labels.push(item.name);
                data1.push(item.validated_tasks);
                data2.push(item.total_tasks_supervised);
                data3.push(item.non_validated_tasks);
            });
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Total Tasks validate',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data1,
                    },
                    {
                        type: 'line',
                        label: 'Total task to supervise',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data2,
                    },
                    {
                        type: 'line',
                        label: 'Tasks no validate',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data3,
                    },
                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.topSupervisorChart = new Chart(document.getElementById('user-top-supervisor-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        changePage(number) {
            this.currentPage = number
        },
        formatNumber(number) {
            const suffixes = ["", "K", "M", "B", "T"];
            const suffixNum = Math.floor(("" + number).length / 3);
            const shortNumber = parseFloat((suffixNum != 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(3));
            const suffix = suffixes[suffixNum];
            return shortNumber + suffix;
        },
        calculAvg(min, max) {
            if (max > 0) {
                let amount = ((min * this.statistiques.total_cost) / max)
                return ((amount * 100) / this.statistiques.total_cost)
            }
            return 0;
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
}
</script>
<style>
.div-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div-item {
    width: 100%;
}

.chart-container {
    height: 300px;
}
</style>