import axiosWithHeaders from '../services/api';


const state = {
    comments: []
  };
  
  const getters = {
    getComments: state => state.comments
  };
  
  const mutations = {
    setComments: (state, comments) => state.comments = comments
  };

const actions = {
  addComment({ commit }, commentData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-comment', commentData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getActivityComments({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`activity/${data.activity_id}/type/${data.type}/comments`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  removeComment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`remove-comment/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  