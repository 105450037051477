<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.device.devices') }}</strong></h3>
                </div>
                <div class="col-auto ms-auto text-end">
                    <div class="row">
                        <div class="col-auto m-0 p-0 me-1">
                            <input type="text" v-model="search" class="form-control" :placeholder="$t('general.recherche')">
                        </div>
                        <div class="col-auto m-0 p-0 me-1">
                            <select v-model="status" class="form-control mb-3">
                                <option value="all">{{ $t('page.device.all_status') }}</option>
                                <option :value="true">{{ $t('page.device.validate') }}</option>
                                <option :value="false">{{ $t('page.device.no_validate') }}</option>
                            </select>
                        </div>
                        <div class="col-auto m-0 p-0 me-1">
                            <select v-model="limit" @change="setLimit" class="form-control mb-3">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div v-if="$can('can_see_all_device') && !iCanSeeAllDevice"
                            class="col-auto d-none d-sm-block p-0 m-0">
                            <button @click="seeAllDevices" class="btn btn-success">{{ $t('page.device.all_devices')
                            }}</button>
                        </div>
                        <div v-if="$can('can_see_all_device') && iCanSeeAllDevice"
                            class="col-auto d-none d-sm-block p-0 m-0">
                            <button @click="initDevices" class="btn btn-success">{{ $t('page.device.my_devices') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="devices.length" class="row">
                <div class="card">
                    <table id="datatables-reponsive" class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('page.device.name') }}</th>
                                <th>{{ $t('page.device.key') }}</th>
                                <th>{{ $t('page.device.status') }}</th>
                                <th v-if="iCanSeeAllDevice">{{ $t('page.device.user') }}</th>
                                <th>{{ $t('page.device.last_login') }}</th>
                                <th>{{ $t('page.device.update_date') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="device in paginatedDevices" :key="device.id">
                                <td>
                                    {{ device?.name }}
                                </td>
                                <td class="d-none d-xl-table-cell">
                                    <input class="form-control" disabled type="text" :value="device?.token">
                                </td>
                                <td class="text-center">
                                    <div class="form-check form-switch">
                                        <input :disabled="!$can('can_set_device_status')"
                                            style="height: 1.5rem; width: 3rem;" @change="validateOrRejectDevice(device.id)"
                                            class="form-check-input" type="checkbox" :checked="device.status">
                                    </div>
                                </td>
                                <td v-if="iCanSeeAllDevice">
                                    {{ device?.user?.name }}
                                </td>
                                <td class="d-none d-xl-table-cell">
                                    <span class="badge bg-info text-dark">{{ device.last_login ?
                                        formatDate(device.last_login) : '-- -- ----' }}</span>
                                </td>
                                <td class="d-none d-xl-table-cell">
                                    <span class="badge bg-success">{{ device.updated_at ? formatDate(device.updated_at) :
                                        '-- -- ----' }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-end">
                        <button class="btn btn-sm btn-primary me-4" @click="prevPage" :disabled="currentPage === 1">
                            <i class="fas fa-angle-left"></i>
                        </button>
                        <span>{{ $t('page.device.page') }} {{ currentPage }} {{ $t('page.device.of') }} {{ totalPages
                        }}</span>
                        <button class="btn btn-sm btn-primary ms-4" @click="nextPage" :disabled="currentPage === totalPages">
                            <i class="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('page.device.loading') }}</h4>
                <div class="mt-4 mb-4">
                    <div class="spinner-grow text-dark me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-primary me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-secondary me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-success me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-danger me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-warning me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                    <div class="spinner-grow text-info me-2" role="status">
                        <span class="visually-hidden">{{ $t('page.device.loading') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'...'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'deviceComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false,
            status: 'all',
            limit: 5,
            devices: [],
            iCanSeeAllDevice: false,
            usersListFilter: {},
            currentPage: 1,
            totalPages: 1,
            search: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        paginatedDevices() {
            let deviceLists = this.devices;
            if (this.search) {
                deviceLists = deviceLists.filter(device => {
                    const searchLower = this.search.toLowerCase();

                    return (
                        device.name.toLowerCase().includes(searchLower) ||
                        device.token.toLowerCase().includes(searchLower) ||
                        (device.user && device.user.name.toLowerCase().includes(searchLower))
                    );
                });
            }
            if (this.status !== 'all') {
                deviceLists = deviceLists.filter(device => device.status === this.status);
            }
            return deviceLists;
        }

    },
    async mounted() {
        if (!this.getCurrentUser) {
            await this._currentUser()
        }
        this.initDevices()
    },
    methods: {
        ...mapActions('auth', ['_currentUser']),
        ...mapActions('device', ['getUserDevices', 'getDevices', 'setDeviceStatus']),
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY HH:mm") : null
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if(!this.iCanSeeAllDevice) this.initDevices()
                else this.seeAllDevices()
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if(!this.iCanSeeAllDevice) this.initDevices()
                else this.seeAllDevices()
            }
        },
        setLimit(){
            if(!this.iCanSeeAllDevice) this.initDevices()
            else this.seeAllDevices()
        },
        async initDevices() {
            try {
                let response = await this.getUserDevices({ status: this.status, limit: this.limit, page: this.currentPage })
                this.devices = response.devices
                this.totalPages = response.total_pages;
                this.iCanSeeAllDevice = false
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isDispo = true
        },
        async seeAllDevices() {
            this.isDispo = false
            try {
                let data = {
                    params: {
                        status: this.status,
                        limit: this.limit,
                        page: this.currentPage
                    },
                    users: this.usersListFilter
                }
                let response = await this.getDevices(data)
                this.devices = response.devices
                this.totalPages = response.total_pages;
                this.iCanSeeAllDevice = true
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isDispo = true
        },
        async validateOrRejectDevice(device) {
            try {
                let response = await this.setDeviceStatus(device)
                if (response.status == true) this.$successNotyf(this.$t('message.device_success'))
                else this.$successNotyf(this.$t('message.device_warning'), 'warning')
            } catch (error) {
                this.$errorNotyf(error)
            }
        }
    },

}
</script>
<style>
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: #f8f8f8;
}

.loader h1 {
    font-size: 36px;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
</style>
    