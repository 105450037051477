<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.services.service') }}</strong></h3>
                </div>
                <div class="col-auto ms-auto text-end">
                    <input type="text" v-model="search" class="form-control" :placeholder="$t('general.recherche')">
                </div>
                <div class="col-auto d-none d-sm-block p-0 m-0 me-2">
                    <button v-if="$can('services_create')" @click="changeSubmitType()" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#new-service-modal">{{ $t('page.services.new_service') }}</button>
                </div>
                <div v-if="$can('categories_create')" class="col-auto d-none d-sm-block p-0 m-0">
                    <button class="btn btn-success" @click="changeSubmitTypeCategorie()" data-bs-toggle="modal" data-bs-target="#new-categorie-modal">{{ $t('page.services.categorie') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-categorie-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.services.categorie') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addCategorie" class="row g-3 needs-validation">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.services.categorie_name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="categorieFormData.name" class="form-control"
                                        placeholder="Category name" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_name') }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('page.services.add_categorie') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="new-service-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.services.service') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addService" class="row g-3 needs-validation">
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">{{ $t('page.services.categorie') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="categorie" :value="serviceFormData.category"
                                        v-model="serviceFormData.category" id="validationCustom02" required>
                                        <option v-for="(categorie, key) in allCategories" :key="key" :value="categorie.id">
                                            {{ categorie.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_categorie') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">  {{ $t('page.services.service_name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="serviceFormData.name" class="form-control"
                                        id="validationCustom01" placeholder="Service name" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_name') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label"> {{ $t('page.services.type_service') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="type_service" :value="serviceFormData.type"
                                        v-model="serviceFormData.type" required>
                                        <option value="standard"> {{ $t('page.services.standard') }}</option>
                                        <option value="cyclique"> {{ $t('page.services.cyclique') }}</option>
                                    </select>
                                </div>
                                <div v-show="serviceFormData.type == 'cyclique'" class="col-md-12">
                                    <label class="form-label">{{ $t('page.services.nb_cycle') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="number" min="1" v-model="serviceFormData.cyclique_number" class="form-control"
                                        placeholder="Nombre de cycle au cours de l'année" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_name') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">Description <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <textarea v-model="serviceFormData.description" class="form-control" rows="5"
                                        id="validationCustom02" placeholder="Enter descriotion" required></textarea>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_description') }}
                                      
                                    </div>
                                </div>
                                <div v-if="type == 'update'" class="col-md-12">
                                    <label for="validationCustom05" class="form-label">Status<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" id="validationCustom05" name="status"
                                        v-model="serviceFormData.status" required>
                                        <option value="unarchive">Active</option>
                                        <option value="archive">Disabled</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_type') }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('page.services.add_new_service') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div v-if=!getFilteredServices.length>
                        <h4> {{ $t('page.services.no_service_found') }}</h4>
                        <div class="mt-4 mb-4">
                            <div class="spinner-grow text-dark me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-primary me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-secondary me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-success me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-danger me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-warning me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                            <div class="spinner-grow text-info me-2" role="status">
                                <span class="visually-hidden"> {{ $t('page.services.loading') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-6" v-for="service in getFilteredServices" :key="service.id">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title mb-0">
                                        <div class="flex-grow-1">
                                            <span class="float-end text-navy">
                                                <router-link v-if="$can('services_view')" class="btn btn-primary btn-sm rounded"
                                                    :to="{ name: 'service-detail', params: { id: service.id } }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-eye align-middle">
                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                        <circle cx="12" cy="12" r="3"></circle>
                                                    </svg>
                                                    {{ $t('general.view') }}
                                                </router-link>
                                            </span>
                                            <strong>{{ service.name }} (AB00{{ service.id }})
                                                <a href="#" v-if="$can('services_update')" data-bs-toggle="modal"
                                                    data-bs-target="#new-service-modal"
                                                    @click="changeSubmitType(service.id)"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-edit-2 align-middle">
                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                        </path>
                                                    </svg>
                                                </a>
                                            </strong>
                                        </div>
                                    </h5>
                                </div>
                                <div class="card-body bg-light">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <strong class="text-muted text-sm"> {{ $t('page.services.status') }}</strong>
                                            <strong class="float-end text-navy">
                                                <span v-show="service.status == 'archive'"
                                                class="badge bg-danger">{{ $t('page.services.close') }}</span>
                                                <span v-show="service.status == 'unarchive'"
                                                class="badge bg-success">{{ $t('page.services.open') }}</span>
                                            </strong>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <strong class="text-muted text-sm">{{ $t('page.services.categorie') }}</strong>
                                            <strong class="float-end text-navy">
                                                <span class="badge bg-info">{{ service.category?.name }}</span>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 card">
                    <div class="card-header">
                        <h6 class="card-subtitle text-muted">{{ $t('page.services.categorie') }}</h6>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('page.services.categorie') }}</th>
                                <th>{{ $t('page.services.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="categorie in allCategories" :key="categorie.id">
                                <td>
                                    <div class="text-muted">
                                        <strong>{{ categorie.name }}</strong>
                                    </div>
                                </td>
                                <td v-if="$can('categories_update')">
                                    <div class="text-end">
                                        <button @click="changeSubmitTypeCategorie(categorie.id)" data-bs-toggle="modal" data-bs-target="#new-categorie-modal" class="btn btn-sm btn-primary rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-2 align-middle">
                                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'servicesComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            isDispo: false,
            allServices: [],
            allCategories: [],
            search: null,
            serviceFormData: {
                name: null,
                description: null,
                creator: null,
                cyclique_number: 1,
                type: 'standard',
                category: null
            },
            categorieFormData: {
                name: null
            },
            isStart: false,
            type: 'create',
            type_cat: 'create',
            service_: null,
            categorie_: null,
            permissions: []
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        getFilteredServices() {
            if (this.search) {
                return this.allServices.filter(service => {
                    return this.search
                        .toLowerCase()
                        .split(" ")
                        .every(x => service.name.toLowerCase().includes(x))
                })
            }
            return this.allServices
        }
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initPage()
    },
    methods: {
        ...mapActions('service', ['services', 'createService', 'updateService']),
        ...mapActions('categorie', ['categories', 'createCategorie', 'updateCategorie']),
        ...mapActions('auth', ['_currentUser', 'userRoles']),
        changeSubmitType(serviceId = null) {
            if (serviceId != null) {
                this.service_ = this.allServices.find(element => element.id === serviceId);
                this.type = 'update'
                this.serviceFormData.name = this.service_.name
                this.serviceFormData.description = this.service_.description
                this.serviceFormData.creator = this.service_.creator.id
                this.serviceFormData.status = this.service_.status
                this.serviceFormData.category = this.service_.category ? this.service_.category.id : null
                this.serviceFormData.type = this.service_.type ? this.service_.type : 'standard'
                this.serviceFormData.cyclique_number = this.service_.cyclique_number ? this.service_.cyclique_number : 1
                this.serviceFormData.id = serviceId
            } else {
                this.service_ = null
                this.type = 'create'
                this.serviceFormData = {
                    name: null,
                    description: null,
                    creator: null,
                    category: null,
                    cyclique_number: 1,
                    type: 'standard',
                }
            }
        },
        changeSubmitTypeCategorie(categorieId = null) {
            if (categorieId != null) {
                this.categorie_ = this.allCategories.find(element => element.id === categorieId);
                this.type_cat = 'update'
                this.categorieFormData.name = this.categorie_.name
                this.categorieFormData.id = categorieId
            } else {
                this.categorie_ = null
                this.type_cat = 'create'
                this.categorieFormData = {
                    name: null
                }
            }
        },
        async initPage() {
            try {
                if (this.getCurrentUser) {
                    this.permissions = await this.userRoles(this.getCurrentUser.id)
                }
                this.allServices = await this.services()
                this.allCategories = await this.categories()
                this.isDispo = true
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async addService() {
            this.isStart = true

            let response, message
            try {
                if (this.type == 'create') {
                    this.serviceFormData.creator = this.getCurrentUser.id
                    response = await this.createService(this.serviceFormData)
                    message = 'Successfully created service.'
                } else {
                    response = await this.updateService(this.serviceFormData)
                    message = 'Successfully updated service.'
                }
                this.$successNotyf(message)
                this.serviceFormData.name = null,
                this.serviceFormData.description = null
                this.serviceFormData.type = 'standard'
                this.serviceFormData.cyclique_number = 1
                this.initPage()
                jQuery('#new-service-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initPage()
        },
        async addCategorie() {
            this.isStart = true

            let response, message
            try {
                if (this.type_cat == 'create') {
                    response = await this.createCategorie(this.categorieFormData)
                    message = 'Successfully created category.'
                } else {
                    response = await this.updateCategorie(this.categorieFormData)
                    message = 'Successfully updated category.'
                }
                this.$successNotyf(message)
                this.categorieFormData.name = null
                this.initPage()
                jQuery('#new-categorie-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initPage()
        }
    },
}
</script>
<style></style>
    