<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('menu.users') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('users_create')" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#new-user-modal">{{ $t('page.users.new_user') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-user-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.new_user') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addNewUser" class="row g-3">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="userFormData.name" class="form-control"
                                         placeholder="Name" required>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="userFormData.email" class="form-control"
                                        placeholder="Email" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="userFormData.phone" class="form-control"
                                        placeholder="Phone" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label"> {{ $t('page.users.superieur') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-sup" v-model="userFormData.superieur"
                                        required>
                                        <option selected value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="sup in allUsers" :key="sup.id" :value="sup.id">{{sup.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="userFormData.address" class="form-control"
                                         placeholder="Address" required>
                                </div>
                                <div class="col-md-6">
                                    <label  class="form-label">{{ $t('page.users.user_type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-type" v-model="userFormData.user_type"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="userT in userTypeFilter" :key="userT.id" :value="userT.id">{{
                                            userT.user_type }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.grade') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-grade" v-model="userFormData.grade"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="grade in listGrades" :key="grade.grade.id" :value="grade.grade.id">{{
                                            grade.grade.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="userFormData.country"
                                        required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{
                                            country?.name }} ({{ country?.code }})</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('general.save') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="update-user-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.new_user') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="_updateUserData" class="row g-3">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="updateUserData.name" class="form-control"
                                         placeholder="Name" required>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="updateUserData.email" class="form-control"
                                        placeholder="Email" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="updateUserData.phone" class="form-control"
                                        placeholder="Phone" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label"> {{ $t('page.users.superieur') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-sup" v-model="updateUserData.superieur">
                                        <option selected value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="sup in allUsers" :key="sup.id" :value="sup.id">{{sup.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="updateUserData.address" class="form-control"
                                         placeholder="Address">
                                </div>
                                <div class="col-md-6">
                                    <label  class="form-label">{{ $t('page.users.user_type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-type" v-model="updateUserData.userType"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="userT in userTypeFilter" :key="userT.id" :value="userT.id">{{
                                            userT.user_type }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.grade') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-grade" v-model="updateUserData.grade"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="grade in listGrades" :key="grade.grade.id" :value="grade.grade.id">{{
                                            grade.grade.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="updateUserData.country"
                                        required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{
                                            country?.name }} ({{ country?.code }})</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('general.save') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="row">
                    <div class="col-xl-8">
                        <table class="table table-striped" id="datatables-reponsive-users" style="width:100%">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.users.name') }} </th>
                                    <th>{{ $t('page.users.phone') }} </th>
                                    <th>{{ $t('page.users.country') }} </th>
                                    <th>{{ $t('page.users.user_type') }}</th>
                                    <th>{{ $t('page.users.action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in allUsers" :key="user.id">
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.phone }}</td>
                                    <td>{{ user.country.name }} ({{ user.country?.code }})</td>
                                    <td><span class="badge bg-info">{{ user.user_type.name }}</span></td>
                                    <td>
                                        <span v-if="$can('users_view')" class="btn btn-link view_user" title="View" @click="userDetails(user.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-eye align-middle btn-link">
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                        </span>
                                        <a href="#" v-if="$can('can_update_user_profile')" data-bs-toggle="modal" data-bs-target="#update-user-modal"
                                            @click="loadUserData(user)"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-2 align-middle">
                                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                </path>
                                            </svg>
                                        </a>
                                        <span v-else>--</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xl-4">
                        <div class="card" v-if="user_id != null">
                            <duv class="card-body">
                                <div class="row mb-xl-3">
                                    <div class="d-flex justify-content-between mb-3">
                                        <h5 class="card-title">
                                            <span>{{ user?.name }}</span>
                                        </h5>
                                        <div>
                                            <router-link v-if="$can('user_roles_view')" :to="'/user/' + user_id + '/permissions'" class="btn btn-primary btn-sm btn-sm rounded me-2">
                                                {{ $t('page.parteners.define_roles') }} 
                                            </router-link>
                                            <router-link v-if="$can('statistiques_view')" :to="'/user-statistic/' + user_id" class="btn btn-primary btn-sm btn-sm rounded">
                                               {{ $t('page.users.view_statistics') }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <th>{{ $t('page.users.name') }} </th>
                                            <td class="text-end">{{ user?.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('page.users.email') }} </th>
                                            <td class="text-end">{{ user?.email }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('page.users.phone') }} </th>
                                            <td class="text-end">{{ user?.phone }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('page.users.adresse') }}</th>
                                            <td class="text-end">{{ user?.address ? user?.address : '---' }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('page.users.country') }}</th>
                                            <td class="text-end">{{ user?.country?.name }} ({{ user?.country?.code }})</td>
                                        </tr>
                                        <tr v-if="$can('grades_view_all')">
                                            <th>{{ $t('page.users.grade') }} </th>
                                            <td class="text-end">{{ user?.grade ? user?.grade?.name : '---' }} ({{ user?.grade ? user?.grade.cost : '---' }})</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('general.status') }} </th>
                                            <td class="text-end">
                                                <span v-show="user?.status == 'active'" class="badge bg-success">Active</span>
                                                <span v-show="user?.status != 'active'" class="badge bg-warning">Inactive</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="$can('active_or_desactive_user')" colspan="2" class="text-end">
                                                <span v-show="user?.status == 'active'">
                                                    <button @click="changeUserStatus()" class="btn btn-danger text-end"
                                                        title="Desactivate"><i class="fas fa-times"></i>
                                                        {{ $t('page.users.desactivate') }} </button></span>
                                                <span v-show="user?.status != 'active'">
                                                    <button @click="changeUserStatus()" class="btn btn-primary text-end"
                                                        title="Activate">
                                                        {{ $t('page.users.activate') }} </button></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </duv>
                        </div>
                        <div v-else class="alert alert-secondary alert-dismissible" role="alert">
                            <div class="alert-icon">
                                <i class="far fa-fw fa-bell"></i>
                            </div>
                            <div class="alert-message">
                                <strong> {{ $t('page.users.string') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'usersComponent',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            allUsers: null,
            isDispo: false,
            user_id: null,
            user_: {},
            user: {},
            isStart: false,
            userFormData: {
                name: null,
                country: '',
                email: null,
                phone: null,
                user_type: null,
                password: null,
                address: null,
                superieur: '',
                grade: ''
            },
            countries: [],
            listGrades: [],
            userTypeFilter:[],
            updateUserData: {}
        };
    },
    async mounted() {
        await this.initPage()
        await this.loadCountries()
        await this.initGrades()
        const options = {
            responsive: true,
            lengthChange: true,
            lengthMenu:[5,10,15,20,25, 50],
        };
        new DataTable('#datatables-reponsive-users', options);  // eslint-disable-line no-undef
        new Choices(document.querySelector(".choices-single-country")); // eslint-disable-line no-undef
        new Choices(document.querySelector(".choices-single-type")); // eslint-disable-line no-undef
        new Choices(document.querySelector(".choices-single-sup")); // eslint-disable-line no-undef
        new Choices(document.querySelector(".choices-single-grade")); // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('auth', ['users', 'userStat', 'registerUser', 'activateOrDeactivateUser', 'getCountries', 'updateUser']),
        ...mapActions('grade', ['grades', 'grade']),
        ...mapActions('user_type', ['fetchUserTypes']),
        async initGrades() {
            let response = await this.grades()
            this.listGrades = response
        },
        async changeSubmitType(type, gradeId=null){
            this.typeSubmitGrade = type
            if(gradeId != null){
                let response = await this.grade(gradeId)
                const { id, name, cost } = response
                this.gradeFormUpdateData = { id, name, cost }
            }
        },
        loadUserData(user) {
            this.updateUserData.id = user.id;
            this.updateUserData.name = user.name;
            this.updateUserData.email = user.email;
            this.updateUserData.phone = user.phone;
            this.updateUserData.address = user.address;
            this.updateUserData.status = user.status;
            this.updateUserData.grade = user.grade?.id;
            this.updateUserData.userType = user.user_type?.id;
            this.updateUserData.country = user.country?.id;
            this.updateUserData.entreprise = user.entreprise?.id;
            this.updateUserData.superieur = user.superieur;
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        async initPage() {
            try {
                this.allUsers = await this.users()
            } catch (error) {
                this.this.$errorNotyf(error)
            }
            let filterName = 'Partener'
            this.allUsers = this.allUsers.filter(user => {
                return user.user_type.name !== filterName;
            });
            
            try {
                this.userTypes = await this.fetchUserTypes()
            } catch (error) {
                this.this.$errorNotyf(error)
            }
            this.userTypeFilter = this.userTypes.filter(type => {
                return type.user_type !== filterName;
            });
            this.isDispo = true
        },
        async loadCountries() {
            let response = await this.getCountries()
            this.countries = response
        },
        async userDetails(userId) {
            this.user_id = userId,
            this.user_ = await this.userStat(this.user_id)
            this.user = this.allUsers.find(element => element.id === userId);
        },
        async changeUserStatus() {
            let userId = this.user.id
            await this.activateOrDeactivateUser({ id: userId })
            this.initPage()
            this.userDetails(userId)
        },
        async addNewUser() {
            this.userFormData.password = this.$genererMotDePasse(10)
            try {
                this.isStart = true
                await this.registerUser(this.userFormData)
                this.handleSuccess('Successfully created user.')
                this.userFormData.name = null,
                this.userFormData.email = null,
                this.userFormData.phone = null,
                this.userFormData.user_type = null,
                this.userFormData.password = null
                this.userFormData.address = null
                this.userFormData.superieur = ''
                this.initPage()
                jQuery('#new-user-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initPage()
        },
        async _updateUserData(){
            try {
                this.isStart = true
                await this.updateUser(this.updateUserData)
                this.handleSuccess('Successfully updated user.')
                this.initPage()
                jQuery('#update-user-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        handleSuccess(message){
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    