<template>
  <div class="container-fluid p-0">
    <h1 class="h3 mb-3">{{ $t('page.tickets.details') }}</h1>
    <button @click="goBack" class="btn btn-secondary" :title="$t('general.back')">
      <i class="align-middle fas fa-fw fa-arrow-left"></i></button>
    <div class="row">
      <div class="col-md-8">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title mb-0">{{ $t('page.tickets.details') }}</h5>
          </div>
          <div v-if="ticket">
            <div class="card-body text-center">
              <h5 class="card-title mb-0">
                <strong>{{ $t('page.tickets.name') }}:</strong>{{ ticket.title }}</h5>
              <div class="mb-3">
                <strong>{{ $t('page.tickets.status') }}:</strong>
                <span class="badge" :class="{
                  'bg-success': ticket.status === 'Completed',
                  'bg-danger': ticket.status === 'Open',
                  'bg-warning': ticket.status === 'In Progress'
                }">{{ ticket.status }}</span>
              </div>
              <div v-if="canUpdateTicket">
                <a v-if="!isAssignedToCurrentUser" class="btn btn-warning btn-sm" href="#" @click="startEdit">Edit Ticket</a>
              </div>
            </div>
            <hr class="my-0" />
            <div class="card-body">
              <h5 class="h6 card-title">Description</h5>
              <ul class="list-unstyled mb-0">
                <li class="mb-1">{{ ticket.description }}</li>
              </ul>
            </div>
            <hr class="my-0" />
            <div class="card-body">
              <h5 class="h6 card-title">{{ $t('page.tickets.assignedto') }}</h5>
              <ul class="list-unstyled mb-0">
                <li class="mb-1" v-if="ticket.latest_assignment">
                  <span v-if="ticket.latest_assignment.assigned_to">
                    {{ $t('page.tickets.user') }}: {{ ticket.latest_assignment.assigned_to.name }}
                  </span>
                  <span v-else-if="ticket.latest_assignment.assigned_group">
                    {{ $t('page.tickets.usergroup') }}: {{ ticket.latest_assignment.assigned_group.name }}
                  </span>
                  <span v-else>{{ $t('page.tickets.notassigned') }}</span>
                </li>
              </ul>
            </div>
            <hr class="my-0" />
            <div class="card-body">
              <h5 class="h6 card-title">{{ $t('page.tickets.due_date') }}</h5>
              <ul class="list-unstyled mb-0">
                <li class="mb-1">{{ ticket.resolution_deadline }}</li>
              </ul>
            </div>
            <hr class="my-0" />
            <div class="card-body">
              <h5 class="h6 card-title">{{ $t('page.tickets.started') }}</h5>
              <ul class="list-unstyled mb-0">
                <li class="mb-1">{{ ticket.started_time }}</li>
              </ul>
            </div>
          </div>
          <div v-else>
            Loading ticket details...
          </div>
        </div>
      </div>
      <div v-if="isEditing" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">{{ $t('page.tickets.update') }}</h5>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-start">
              <form @submit.prevent="editTicket">
                <div class="mb-3">
                  <label for="title" class="form-label">{{ $t('page.tickets.name') }}</label>
                  <input v-model="editedTicket.title" type="text" class="form-control" id="title" required>
                </div>
                <div class="mb-3">
                  <label for="description" class="form-label">Description</label>
                  <textarea v-model="editedTicket.description" class="form-control" id="description" rows="3" required></textarea>
                </div>
                <div class="mb-3">
                  <label for="status" class="form-label">{{ $t('page.tickets.status') }}</label>
                  <select v-model="editedTicket.status" class="form-select" id="status" required>
                    <option value="Open">Open</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary">{{ $t('page.tickets.update') }}</button>
                <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="canAssignTicket" class="col-md-4">
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title mb-0">{{ $t('page.tickets.assign') }}</h5>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-start">
              <form @submit.prevent="assignTicket" class="flex-grow-1">
                <div class="mb-3">
                  <label class="form-label">{{ $t('page.tickets.assignedto') }}:</label>
                  <select v-model="assignmentType" class="form-select mb-3">
                    <option value="user">{{ $t('page.tickets.user') }}</option>
                    <option value="userType">{{ $t('page.tickets.usergroup') }}</option>
                  </select>
                </div>
                <div v-if="assignmentType === 'user'" class="mb-3">
                  <label class="form-label">{{ $t('page.tickets.userchoice') }}</label>
                  <select v-model="selectedUser" class="form-select mb-3">
                    <option selected value="">{{ $t('page.users.choice') }}...</option>
                    <option v-for="user in allUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                  </select>
                </div>
                <div v-if="assignmentType === 'userType'" class="mb-3">
                  <label class="form-label">{{ $t('page.tickets.usergroupchoice') }}</label>
                  <select v-model="selectedUserType" class="form-select mb-3">
                    <option selected value="">{{ $t('page.users.choice') }}...</option>
                    <option v-for="userT in userTypes" :key="userT.id" :value="userT.id">{{ userT.user_type }}</option>
                  </select>
                </div>
                <div>
                  <div v-if="isAssigned && !isCompletedTicket" class="mt-3">
                    <button @click="reassignTicket" class="btn btn-primary">{{ $t('page.tickets.reassign') }}</button>
                  </div>
                  <div v-else-if="!isAssigned" class="mt-3">
                    <button type="submit" class="btn btn-primary">{{ $t('page.tickets.assign') }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TicketDetail',
  data() {
    return {
      allUsers: null,
      ticket: [],
      selectedUser: null,
      selectedUserType: null,
      userTypes: [],
      assignmentType: 'user',
      isEditing: false,
      editedTicket: {},
    };
  },
  watch: {
    assignmentType(newValue) {
      if (newValue === 'user') {
        this.selectedUserType = null;
      } else {
        this.selectedUser = null;
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    canUpdateTicket() {
      return this.ticket.status !== 'Completed' && this.ticket.status !== 'In Progress';
    },
    canAssignTicket() {
      return this.ticket.status !== 'Completed' && !this.isAssignedToGroup;
    },
    isAssigned() {
      return this.ticket.latest_assignment && (this.ticket.latest_assignment.assigned_to || this.ticket.latest_assignment.assigned_group);
    },
    isAssignedToGroup() {
      return this.ticket.latest_assignment && this.ticket.latest_assignment.assigned_group;
    },
    isCompletedTicket() {
      return this.ticket.status === 'Completed';
    },
    isAssignedToCurrentUser() {
      return (this.ticket.latest_assignment && 
            ((this.ticket.latest_assignment.assigned_to && 
              this.ticket.latest_assignment.assigned_to.id === this.getCurrentUser.id) ||
             (this.ticket.latest_assignment.assigned_group && 
              this.ticket.latest_assignment.assigned_group.id === this.getCurrentUser.user_type.id)));
  }
  },

  methods: {
    ...mapActions('ticket', ['fetchTickets', 'fetchTicket', 'updateTicket', 'assignTicketToUser', 'assignTicketToUserType', 'reassignTicketToUser']),
    ...mapActions('auth', ['users', '_currentUser', 'getUsersByUserTypeName', ]),
    ...mapActions('user_type', ['fetchUserTypes']),

    async loadTicket() {
      try {
        const ticketId = this.$route.params.ticket_id;
        const response = await this.fetchTicket({ id: ticketId });
        this.ticket = response.data;
      } catch (error) {
        console.error('Error loading ticket:', error);
      }
    },

    async loadUsers() {
      try {
        this.allUsers = await this.users()
      } catch (error) {
        console.error('Error loading users:', error);
      }
    },
    async loadUserTypes() {
      try {
        this.userTypes = await this.fetchUserTypes()
      } catch (error) {
        console.error('Error loading user types:', error);
      }
    },
    async assignTicket() {
      try {
        if (this.assignmentType === 'user' && this.selectedUser) {
          await this.assignTicketToUser({
            ticket_id: this.$route.params.ticket_id,
            user_id: this.selectedUser
          });
        } else if (this.assignmentType === 'userType' && this.selectedUserType) {
          await this.assignTicketToUserType({
            ticket_id: this.$route.params.ticket_id,
            user_type_id: this.selectedUserType
          });
        }
        await this.loadTicket();
        this.$successNotyf('Ticket assigned successfully');
      } catch (error) {
        console.error('Error assigning ticket:', error);
        this.$errorNotyf('Failed to assign ticket');
      }
    },

    async reassignTicket() {
      try {
        if (this.assignmentType === 'user' && this.selectedUser) {
          await this.reassignTicketToUser({
            ticket_id: this.$route.params.ticket_id,
            user_id: this.selectedUser
          });
        } else if (this.assignmentType === 'userType' && this.selectedUserType) {
          await this.assignTicketToUserType({
            ticket_id: this.$route.params.ticket_id,
            user_type_id: this.selectedUserType
          });
        }
        await this.loadTicket();
        this.$successNotyf('Ticket assigned successfully');
      } catch (error) {
        console.error('Error assigning ticket:', error);
        this.$errorNotyf('Failed to assign ticket');
      }
    },
    startEdit() {
      this.editedTicket = { ...this.ticket };
      this.isEditing = true;
    },
    cancelEdit() {
      this.isEditing = false;
    },

    goBack() {
            window.history.go(-1);
        },

    async editTicket() {
      try {
        await this.updateTicket({
          ticket_id: this.$route.params.ticket_id,
          ...this.editedTicket
        });
        this.$successNotyf('Ticket updated successfully');
        this.isEditing = false;
        await this.loadTicket();
      } catch (error) {
        this.$errorNotyf('Failed to update ticket');
        console.error('Error updating ticket:', error);
      }
    },


  },
  mounted() {
    this.loadTicket();
    this.loadUsers();
    this.loadUserTypes();
  }
};
</script>
