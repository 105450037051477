<template>
  <div class="wrapper">
    <nav id="sidebar" class="sidebar js-sidebar">
      <div class="sidebar-content js-simplebar">
        <router-link class="sidebar-brand p-0 m-0 mt-3 ms-3" :to="{ name: 'home' }">
          <img src="/img/avatars/eruditLogo.png" style="width:80%" class="avatar img-fluid rounded" alt="" />
        </router-link>
        <ul class="sidebar-nav">
          <li class="sidebar-header">
            {{ $t('component.Layout.dashboard') }}
          </li>
          <li v-for="(menu, index) in menus" :key="index" v-show="checkAccess(menu) && menu.canSee"
            :class="activateUrl(menu.group) ? 'sidebar-item active' : 'sidebar-item'">
            <router-link v-if="!menu.haveChildren" class="sidebar-link" :to="{ name: menu.name }">
              <i :class="'align-middle me-2 ' + menu.icon"></i><span class="align-middle">{{ menu.title }}</span>
            </router-link>
            <a v-if="menu.haveChildren" :data-bs-target="'#charts' + index" data-bs-toggle="collapse"
              class="sidebar-link collapsed">
              <i :class="'align-middle me-2 ' + menu.icon"></i><span class="align-middle">{{ menu.title }}</span>
            </a>
            <ul v-if="menu.haveChildren" :id="'charts' + index" class="sidebar-dropdown list-unstyled collapse "
              data-bs-parent="#sidebar">
              <li :class="activateUrl(child.group) ? 'sidebar-item active' : 'sidebar-item'"
                v-for="(child, key) in menu.children" :key="key" v-show="checkAccess(child)">
                <router-link class="sidebar-link" :to="{ name: child.name }">
                  {{ child.title }}
                </router-link>
              </li>
            </ul>
            <span>
            </span>
          </li>
        </ul>
      </div>
    </nav>
    <div class="main">
      <nav class="navbar navbar-expand navbar-light navbar-bg">
        <a class="sidebar-toggle js-sidebar-toggle">
          <i class="hamburger align-self-center"></i>
        </a>
        <ul v-if="getCurrentUser?.user_type?.name != 'Partener'" class="navbar-nav d-none d-lg-flex me-4">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="resourcesDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <strong class="text-primary">{{ $t('component.Layout.evaluation') }}</strong>
            </a>
            <div class="dropdown-menu" aria-labelledby="resourcesDropdown">
              <router-link class="dropdown-item" :to="{ name: 'evaluation' }">
                <i class="align-middle me-1" data-feather="book-open"></i>
                {{ $t('component.Layout.auto_evaluation') }}
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'collaborator-evaluation' }">
                <i class="align-middle me-1" data-feather="edit"></i>
                {{ $t('component.Layout.collaborator') }}
              </router-link>
              <router-link v-show="checkAccess('Head', true)" class="dropdown-item" :to="{ name: 'head-evaluation' }">
                <i class="align-middle me-1" data-feather="edit"></i>
                {{ $t('component.Layout.list_evaluation') }}
              </router-link>
            </div>
          </li>
        </ul>
        <div v-if="$can('projects_create')">
          <router-link to="/add-project" class="btn btn-outline-danger">{{ $t('component.AuthPageComponent.HeadPageComponent.create_new_project') }}</router-link>
        </div>

        <div class="navbar-collapse collapse">
          <ul class="navbar-nav navbar-align">
            <li class="nav-item dropdown">
              <a class="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                <div class="position-relative">
                  <i class="align-middle far fa-fw fa-bell"></i>
                  <span class="indicator">{{ totalNotification }}</span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
                <div class="dropdown-menu-header">
                  {{ totalNotification }} {{ $t('component.Layout.new_notifications') }}
                </div>
                <div class="list-group">
                  <a v-for="(notification, index) in lastNotifications_" :key="index"
                    :href="'/notifications/' + getCurrentUser?.id" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <i class="align-middle far fa-fw fa-bell text-warning"></i>
                      </div>
                      <div class="col-10">
                        <div class="text-dark">{{ notification.title }}</div>
                        <div class="text-muted small mt-1">{{ notification.notification.slice(0, 50) }}</div>
                        <div class="text-muted small mt-1">{{ formatDateAgo(notification.created_at) }}</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="dropdown-menu-footer">
                  <router-link :to="'/notifications/' + getCurrentUser?.id" class="text-muted">
                    {{ $t('component.Layout.show_all_notifications') }}
                  </router-link>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-flag dropdown-toggle" href="#" id="languageDropdown" data-bs-toggle="dropdown">
                <img :src="currentLanguage.img" :alt="currentLanguage.title" />
              </a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
                <a v-for="lang in langs" :key="lang.code" @click="changeLang(lang)" class="dropdown-item" href="#">
                  <img :src="lang.img" :alt="$t(lang.title)" width="20" class="align-middle me-1" />
                  <span class="align-middle">{{ $t(lang.title) }}</span>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-icon pe-md-0 dropdown-toggle" href="#" data-bs-toggle="dropdown">
                <img src="/img/avatars/default.png" style="height: 30px; width:30px" class="avatar img-fluid rounded"
                  alt="{{ getCurrentUser ? getCurrentUser.username : '----' }}" />
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <router-link class="dropdown-item" :to="{ name: 'profile-user' }">
                  <i class="align-middle me-1" data-feather="user"></i> Profile ({{ getCurrentUser ? getCurrentUser.name :
                    '----' }})
                </router-link>
                <div class="dropdown-divider"></div>
                <a href="javascript;;" @click.prevent="logoutuser" class="dropdown-item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-log-out align-middle me-2">
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  {{ $t('component.Layout.log_out') }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <main class="content">
        <!-- Main content -->
        <router-view></router-view>
      </main>
      <footer class="footer">
        <div class="container-fluid">
          <div class="row text-muted">
            <div class="col-6 text-start">
              <p class="mb-0"><strong class="text-muted">{{ $t('component.Layout.app_name') }}</strong> &copy;
              </p>
            </div>
            <!-- <div class="col-6 text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Support</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Help Center</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Privacy</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Terms</a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
  
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AppLayout',
  data() {
    return {
      home: ['home', 'add-project'],
      projects: ['project-detail'],
      users: ['users', 'grade', 'user-type', 'entreprise', 'parteners'],
      services: ['services', 'add-service-task', 'service-detail'],
      tasks: ['tasks', 'task-detail', 'edit-task-config'],
      tickets: ['tickets', 'ticket-detail', 'add-ticket', 'edit-ticket'],
      settings: ['settings'],
      devices: ['devices'],
      collaborator: ['collaborator'],
      grade: ['grade'],
      partners: ['partners'],
      user_type: ['user_type'],
      entreprise: ['entreprise'],
      statistique_projects: ['statistique_projects'],
      statistique_users: ['statistique_users'],
      statistiques: ['statistique-user', 'statistique-project', 'statistique-country'],
      langs: {
        en: {
          'img': '/img/flags/us.png',
          'title': 'header.language.english',
          'code': 'en'
        },
        fr: {
          'img': '/img/flags/fr.png',
          'title': 'header.language.french',
          'code': 'fr'
        },
      },
      countries: [],
      lastNotifications_: [],
      totalNotification: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    menus() {
      return [
        {
          title: this.$t('menu.home'),
          icon: 'fas fa-fw fa-sliders-h',
          name: 'home',
          group: this.home,
          haveChildren: false,
          access: ['Partener', 'User', 'Supervisor', 'Head'],
          role: null,
          canSee: true
        },
        {
          title: this.$t('menu.account'),
          icon: 'far fa-fw fa-user',
          haveChildren: true,
          group: null,
          children: [
            {
              title: this.$t('menu.users'),
              group: this.collaborator,
              name: 'users',
              access: null,
              route: 'users',
              role: ['users_view_all'],
            },
            {
              title: this.$t('menu.entreprise'),
              group: this.entreprise,
              name: 'entreprise',
              access: null,
              route: 'entreprise',
              role: ['entreprises_view_all'],
            },
            {
              title: this.$t('menu.partners'),
              group: this.partners,
              name: 'parteners',
              access: null,
              route: 'parteners',
              role: ['partners_view_all'],
            },
            {
              title: this.$t('menu.grade'),
              group: this.grade,
              name: 'grade',
              access: null,
              route: 'grade',
              role: ['grades_view_all'],
            },
            {
              title: this.$t('menu.user_type'),
              group: this.user_type,
              name: 'user-type',
              access: null,
              route: 'user-type',
              role: ['user_types_view_all'],
            },
          ],
          access: null,
          role: ['users_view_all', 'entreprises_view_all', 'partners_view_all', 'grades_view_all', 'user_types_view_all'],
          canSee: true
        },
        {
          title: this.$t('menu.services'),
          icon: 'fas fa-fw fa-door-open',
          name: 'services',
          haveChildren: false,
          group: this.services,
          access: null,
          role: ['services_view_all'],
          canSee: true
        },
        {
          title: this.$t('menu.tasks'),
          icon: 'fas fa-fw fa-tasks',
          name: 'tasks',
          haveChildren: false,
          group: this.tasks,
          access: ['User', 'Supervisor', 'Head'],
          role: null,
          canSee: true
        },
        {
          title: this.$t('menu.requests'),
          icon: 'fas fa-fw fa-ticket',
          name: 'tickets',
          haveChildren: false,
          group: this.tickets,
          access: ['Partener', 'User', 'Supervisor', 'Head'],
          role: ['tickets_view_all', 'tickets_view', 'tickets_create', 'tickets_update'],
          canSee: true
        },
        {
          title: this.$t('menu.statistiques'),
          icon: 'fas fa-fw fa-chart-pie',
          haveChildren: true,
          group: null,
          role: null,
          children: [
            {
              title: this.$t('menu.projects'),
              group: this.statistique_projects,
              name: 'statistique-project',
              route: 'statistique-project',
              access: ['Head'],
              role: [],
            },
            {
              title: this.$t('menu.users'),
              group: this.statistique_users,
              name: 'statistique-user',
              route: 'statistique-user',
              access: ['Head'],
              role: [],
            },
          ],
          access: ['Head'],
          canSee: true
        },
        {
          title: this.$t('menu.settings'),
          icon: 'fas fa-fw fa-sun',
          name: 'settings',
          haveChildren: false,
          group: this.settings,
          access: ['Head'],
          canSee: true,
          role: null,
        },
        {
          title: this.$t('menu.devices'),
          icon: 'fas fa-fw fa-sun',
          name: 'devices',
          haveChildren: false,
          group: this.devices,
          access: ['User', 'Supervisor', 'Head'],
          canSee: this.$can('can_see_device'),
          role: null,
        },
      ]
    },
    currentLanguage() {
      return this.langs[this.$i18n.locale]
    },
  },
  async mounted() {
    if (!this.getCurrentUser) {
      await this._currentUser()
    }
    await this.currentUserPermissions()
    await this.initlastNotification()
  },
  methods: {
    ...mapActions('auth', ['logout', '_currentUser']),
    ...mapActions('notification', ['lastNotifications']),
    ...mapActions('permission', ['currentUserPermissions']),
    async initlastNotification() {
      let this_user = this.getCurrentUser
      let response = await this.lastNotifications(this_user.id)
      this.lastNotifications_ = response.notifications
      this.totalNotification = response.total_notifications
    },
    async logoutuser() {
      await this.logout()
        .then(() => {
          this.$successNotyf('See you soon. Disconnection successfully completed')
          this.$router.push({ name: 'login' })
        })
        .catch((error) => {
          this.isStart = false
          this.$errorNotyf(error)
        })
    },
    formatDateAgo(dateToFormat) {
      const momentDate = moment(dateToFormat);
      const now = moment();
      const diffDuration = moment.duration(now.diff(momentDate));

      let formattedDate = '';

      const years = diffDuration.years();
      const months = diffDuration.months();
      const days = diffDuration.days();
      const hours = diffDuration.hours();
      const minutes = diffDuration.minutes();

      if (years > 0) {
        formattedDate += `${years} an${years > 1 ? 's' : ''} `;
      }
      if (months > 0) {
        formattedDate += `${months} mois `;
      }
      if (days > 0) {
        formattedDate += `${days} jour${days > 1 ? 's' : ''} `;
      }
      if (hours > 0) {
        formattedDate += `${hours}h `;
      }
      formattedDate += `${minutes}min ago`;
      return formattedDate;
    },

    changeLang(lang) {
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
    },
    activateUrl(group) {
      if (group)
        return group.includes(this.$route.name)
      else return false
    },
    checkAccess(menu, useMenu = false) {
      if (useMenu) {
        if (this.getCurrentUser?.user_type.name == menu) {
          return true
        }
      } else {
        if (menu.access == null) {
          for (let index = 0; menu.role.length > index; index++) {
            if (this.$can(menu.role[index])) return true
          }
          return false
        } else {
          const userTypes = {
            Partener: 1,
            User: 2,
            Supervisor: 3,
            Head: 4,
          }
          const userTypeLevel = userTypes[this.getCurrentUser?.user_type.name]
          const menuAccessLevel = userTypes[menu.access[0]]
          return userTypeLevel >= menuAccessLevel
        }
      }
      // return true
    },
  },
}
</script>

<style></style>
  