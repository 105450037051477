import axiosWithHeaders from '../services/api';

const state = {
    serviceDocs: [],
    serviceDoc: null,
};

const getters = {
    getServiceDocs: (state) => state.serviceDocs,
    getServiceDoc: (state) => state.serviceDoc,
};

const mutations = {
    setServiceDocs: (state, serviceDocs) => {
        state.serviceDocs = serviceDocs;
},
setServiceDoc: (state, serviceDoc) => {
        state.serviceDoc = serviceDoc;
    },
};

const actions = {
  getServiceDocs({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('service/docs')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getServiceDoc({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`service/doc/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addServiceDoc({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('create-service/doc', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateServiceDoc({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-service/doc/${data.id}`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getServiceDocLists({ commit }, service_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`services/${service_id}/service/docs`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getServiceDocListUnarchives({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('service/docs/unarchives')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateServiceDocStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-service-doc-status/${data.document_id}`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  downloadFile({ commit }, data) {
    axiosWithHeaders({
      method: 'get',
      url: 'download/' + data.doc_id + '/type/' + data.type,
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.type}_${data.doc_id}.pdf`);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.error(error);
      // Afficher un message d'erreur à l'utilisateur
    });
  }
  
  
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
