<template>
    <div class="container-fluid p-0">
        <div class="card m-4">
            <form @submit.prevent="_updateProject" class="needs-validation p-4">
                <div class="card-header">
                    <h5 class="card-title mb-0 pb-0">
                        <div class="flex-grow-1">
                            <strong>{{ $t('forms.edit_project') }}</strong>
                        </div>
                        <div class="col-auto ms-auto text-end mt-n4">
                            <button v-show="!isStart" class="btn btn-primary me-2" type="submit" id="add_project">
                                {{ $t('forms.edit_project') }}
                            </button>
                            <button v-show="isStart" class="btn btn-primary me-2" type="submit" id="add_project">
                                <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                            </button>
                            <button @click="goBack" class="btn btn-secondary" title="Back"><i
                                    class="align-middle fas fa-fw fa-arrow-left"></i></button>
                        </div>
                    </h5>
                </div>
                <div class="card-body h-100">
                    <div class="row g-3 ">
                        <div class="col-sm-12 pt-2">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="validationCustom01" class="form-label">{{ $t('forms.project_name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" class="form-control" name="name" v-model="projectFormData.name"
                                        id="validationCustom01" :placeholder="$t('forms.project_name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.project_name_description') }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="validationCustom02" class="form-label">{{ $t('forms.service_categorie') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select @change="initServiceSelect()" class="form-select" name="categorie" :disabled="project?.service?.type=='cyclique'"
                                        v-model="category" id="validationCustom02" required>
                                        <option value="all">{{ $t('forms.all_categorie') }}</option>
                                        <option v-for="(categorie, index) in allCategories" :key="index"
                                            :value="categorie.id">{{ categorie.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.service_categorie_description') }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="validationCustom02" class="form-label">{{ $t('forms.service_type') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="service_type" v-model="projectFormData.service" :disabled="project?.service?.type=='cyclique'"
                                        id="validationCustom02" required>
                                        <option value="">{{ $t('general.choice') }}</option>
                                        <option v-for="(service, key) in services" :key="key" :value="service.id">{{
                                            service.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.service_type_description') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 pt-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('general.start_date') }}</label>
                                    <input type="datetime-local" class="form-control"
                                        v-model="projectFormData.start_date" name="start_date" :disabled="project?.service?.type=='cyclique'"
                                        :placeholder="$t('genral.start_date')" :min="getMinDate()">
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('general.end_date') }}</label>
                                    <input type="datetime-local" class="form-control" id="validationCustom04"
                                        name="end_date" v-model="projectFormData.end_date" :disabled="project?.service?.type=='cyclique'"
                                        :placeholder="$t('general.end_date')" :min="getMinDate()">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 pt-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('general.status') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" id="validationCustom05" name="status"
                                        v-model="projectFormData.status" required>
                                        <option selected value="Pending">{{ $t('forms.pending') }}</option>
                                        <option value="In progress">{{ $t('forms.open') }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('forms.project_manager') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="project_manager" v-model="projectFormData.manager"
                                        id="validationCustom02" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}</option>
                                        <option v-for="(manager, key) in managers" :key="key" :value="manager.id">{{
                                            manager.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="validationCustom07" class="form-label">{{ $t('forms.description') }}<i
                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                            <textarea class="form-control" name="description" rows="5" v-model="projectFormData.description"
                                id="validationCustom07" :placeholder="$t('forms.enter_description')" required></textarea>
                            <div class="invalid-feedback">
                                {{ $t('forms.description_description') }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment'
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'AddProjectComponent',
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    data() {
        return {
            services: null,
            managers: null,
            project: {},
            category: 'all',
            allCategories: [],
            projectFormData: {
                project: this.$route.params.id,
                name: '',
                service: null,
                manager: '',
                description: null,
                status: this.$t('forms.pending'),
                start_date: null,
                end_date: null,
                date_assigned: null,
                partener: null,
                validator: null,
                open_date: null
            },
            isStart: false,
        };
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initProject()
        await this.initServiceSelect()
        await this.initSelects()
    },
    methods: {
        ...mapActions('service', ['getServiceUnarchives']),
        ...mapActions('auth', ['getUsersByUserTypeName', '_currentUser', 'userRoles']),
        ...mapActions('project', ['updateProject', 'getProject']),
        ...mapActions('categorie', ['categories', 'getListeServiceCategories']),
        initJs() {
            var forms = document.querySelectorAll('.needs-validation')
            // Loop over them and prevent submission
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    form.addEventListener('submit', function (event) {
                        if (!form.checkValidity()) {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                        form.classList.add('was-validated')
                    }, false)
                })
        },
        async initProject() {
            try {
                if (this.$route.params.id) {
                    let project_id = this.$route.params.id
                    this.project = await this.getProject(project_id)
                    this.formatData()
                }
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        formatData() {
            this.projectFormData.name = this.project.name
            this.projectFormData.service = this.project.service.id
            this.projectFormData.manager = this.project.manager ? this.project.manager.id : null
            this.projectFormData.description = this.project.description
            this.projectFormData.start_date = this.project.start_date ? this.formatDateTimeLocal(this.project.start_date) : null
            this.projectFormData.end_date = this.project.end_date ? this.formatDateTimeLocal(this.project.end_date) : null
            this.projectFormData.leader = this.project.leader.id
            this.category = this.project.service.category.id
        },
        async initServiceSelect() {
            if (this.category == 'all') {
                this.services = await this.getServiceUnarchives()
            } else {
                this.services = await this.getListeServiceCategories(this.category)
            }
        },
        goBack() {
            window.history.go(-1);
        },
        async initSelects() {
            try {
                this.managers = await this.getUsersByUserTypeName('Supervisor')
                this.allCategories = await this.categories()
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        formatDateTimeLocal(date) {
            return date ? moment(date).format("YYYY-MM-DDTHH:mm") : null;
        },
        getMinDate() {
            const now = new Date()
            const year = now.getFullYear()
            const month = String(now.getMonth() + 1).padStart(2, '0')
            const day = String(now.getDate()).padStart(2, '0')
            const hours = String(now.getHours()).padStart(2, '0')
            const minutes = String(now.getMinutes()).padStart(2, '0')
            return `${year}-${month}-${day}T${hours}:${minutes}`
        },
        async _updateProject() {
            this.isStart = true
            const currentDate = new Date();
            const formattedDate = format(currentDate, 'yyyy-MM-dd');
            if (this.projectFormData.status != 'Pending') {
                this.projectFormData.validator = this.getCurrentUser.id
            }
            this.projectFormData.open_date = this.projectFormData.start_date
            if (this.projectFormData.manager != null) {
                this.projectFormData.date_assigned = formattedDate
            }
            if (this.getCurrentUser.user_type.name == 'Partener') {
                this.projectFormData.partener = this.getCurrentUser.id
            }
            try {
                let response = await this.updateProject(this.projectFormData)
                this.$successNotyf('Successfully updated project.')
                this.$router.push({ name: 'project-detail', params: { id: response.id } });
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
        }
    }
}
</script>
<style></style>
    