<template>
	<main class="d-flex w-100 h-100">
		<div class="container d-flex flex-column">
			<div class="row vh-100">
				<div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
					<div class="d-table-cell align-middle">
						<div class="card">
							<div class="card-body">
								<div class="text-center">
									<img src="/img/avatars/eruditLogo.png" alt="logo" class="img-fluid" width="150" height="150">
								</div>
								<div class="text-center mt-4">
									<h1 class="h2">{{ $t('auth.welcome') }} <strong class="text-warning">{{ $t('auth.planwise') }}</strong></h1>
									<p class="lead">
										{{ $t('auth.connecter') }}
									</p>
								</div>
								<hr class="text-danger">
								<div class="m-sm-4">
									<form @submit.prevent="handleSubmit">
										<div class="mb-3">
											<label class="form-label">{{ $t('auth.email_phone') }}</label>
											<input class="form-control form-control-lg" v-model="loginData.email_or_phone"
												type="text" name="email_or_phone" placeholder="Enter your email or phone" />
										</div>
										<div class="mb-3">
											<label class="form-label">{{ $t('auth.password') }}</label>
											<input class="form-control form-control-lg" v-model="loginData.password"
												type="password" name="password" placeholder="Enter your password" />
											<small>
												<router-link class="text-primary" :to="{ name: 'reset-password'}">
													{{ $t('auth.forgot_password') }}
												</router-link>
											</small>
										</div>
										<div>
											<label class="form-check">
												<input class="form-check-input" type="checkbox" value="remember-me"
													name="remember-me" checked>
												<span class="form-check-label">
													{{ $t('auth.remember_me') }}
												</span>
											</label>
										</div>
										<div class="text-center mt-3">
											<button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
												<div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
												<div class="spinner-border spinner-border-sm text-warning" role="status"></div>
											</button>
											<button v-show="!isStart" type="submit" class="btn btn-lg btn-primary">{{ $t('auth.sign_in') }}</button>
										</div>
									</form>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script>
import { mapActions } from 'vuex'
export default {
	name: 'PageConnexion',
	data() {
		return {
			loginData: {
				email_or_phone: '',
				password: '',
			},
			errors: [],
			verifySecurity: false,
			isStart: false,
			notif: null
		};
	},
	async mounted() {
        await this.verifySec()
    },
	methods: {
		...mapActions('auth', ['login', 'checked']),
		async handleSubmit() {
			this.isStart = true
			await this.login(this.loginData)
			.then((response) => {
				this.$successNotyf(response.message)
				this.$router.push({ name: 'home'});
			})
			.catch((error) => {
				this.$errorNotyf(error)
				this.errors.push(error);
			});
			this.isStart = false
		},
		async verifySec(){
			let response = await this.checked()
			if(response.checked){
				this.notif = window.notyf.open({
					type: 'default',
					message: 'Securtity high is activated on this app. We advice you to use your work computer to connect',
					dismissible: true,
					duration: '10000',
					position: {x: 'right', y: 'bottom'}
				});
			}
		},
	},
};
</script>