<template>
    <div class="card">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>{{ $t('user_month_stat.client') }}</th>
                    <th>{{ $t('user_month_stat.project') }}</th>
                    <th>{{ $t('user_month_stat.duration') }}</th>
                    <th>{{ $t('user_month_stat.tasks') }}</th>
                    <th>{{ $t('user_month_stat.niveau') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in dataMonth" :key="item.user.user_id">
                    <td>{{ getClientName(item.user) }}</td>
                    <td>{{ getProjectName(item.projects[0].project) }}</td>
                    <td>
                        <ul>
                            <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                                {{ task.duration }} {{ $t('general.hours') }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                                {{ task.task_name }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                            {{ task.niveau }}
                        </li>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="bg-secondary text-white">
                    <td colspan="4">Total d'heure:</td>
                    <td><strong>{{ calculateTotalDuration() }} {{ $t('general.hours') }}</strong></td>
                </tr>
                <tr class="bg-warning">
                    <td colspan="4">{{ $t('user_month_stat.general_moyen') }}</td>
                    <td><strong class="text-dark">{{ calculateAverage() }} /20</strong></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
    
<script>
import moment from 'moment';

export default {
    name: 'HelloComponent',
    props: {
        dataMonth: {
            type: Array,
            required: true
        }
    },
    methods:{
        getClientName(user) {
            if (user.entreprise_name) {
                return user.entreprise_name;
            } else {
                return `${user.name}`;
            }
        },
        getProjectName(project) {
            return project.project_name;
        },
        calculateTotalDuration() {
            let totalDuration = 0;
            for (const item of this.dataMonth) {
                for (const task of item.projects[0].tasks) {
                    totalDuration += task.duration;
                }
            }
            return totalDuration;
        },
        calculateAverage() {
            let totalDuration = this.calculateTotalDuration();
            let tasksCount = 0;
            for (const item of this.dataMonth) {
                tasksCount += item.projects[0].tasks.length;
            }
            if (tasksCount > 0) {
                return (totalDuration / tasksCount).toFixed(2);
            } else {
                return "0.00"; // Retourne "0.00" si tasksCount est égal à 0
            }
        }
    }
}
</script>
<style></style>
    