<template>
    <div class="container-fluid p-0">
        <div class="card">
            <div class="row mt-2 mb-1 m-1">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>Settings</strong> - <span v-show="currentPage == 1"
                            class="text-secondary">Système</span><span v-show="currentPage == 2"
                            class="text-secondary">Rapport Evaluation</span></h3>
                </div>
                <div class="col-auto ms-auto text-end mt-n1">
                    <div class="btn-group" role="group" aria-label="Navigation">
                        <button type="button" :class="1 == currentPage ? 'active btn-primary' : 'btn-secondary'" class="btn"
                            @click="changePage(1)">SYSTEME</button>
                        <button  type="button" :class="2 == currentPage ? 'active btn-primary' : 'btn-secondary'" class="btn"
                            @click="changePage(2)">RAPPORT D'EVALUATION</button>
                    </div>
                    <!-- <div>
                    <router-link class="btn btn-outline-secondary" :to="{ name: 'audittrail'}">
                        <i class="align-middle me-1" data-feather="user"></i> Audit Trails
                    </router-link>
                </div> -->
                </div>
            </div>
        </div>
        <div v-show="currentPage == 1">
            <div class="row">
                <div class="col-md-3 col-xl-2">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title mb-0">SYSTEME</h5>
                        </div>

                        <div class="list-group list-group-flush" role="tablist">
                            <a class="list-group-item list-group-item-action active" data-bs-toggle="list" href="#project"
                                role="tab">
                                Projects
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#task" role="tab">
                                Tasks
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#ticket" role="tab">
                                Tickets
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#document"
                                role="tab">
                                Documents
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#general"
                                role="tab">
                                General
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#comment"
                                role="tab">
                                Comments
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#performance"
                                role="tab">
                                Performances
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#security"
                                role="tab">
                                Security
                            </a>
                            <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#cron" role="tab">
                                Cron
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-9 col-xl-10">
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="project" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter projects</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.project" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input @click="setcheckedParams(param.id)" class="form-check-input"
                                                            type="checkbox" :value="param.checked" v-model="param.checked">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="task" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter tasks</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.task" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ticket" role="tabpanel">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Parameter ticket</h5>
                                </div>
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.ticket" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table><br>
                                    <h5 class="card-title">Criticality Settings</h5>
                                
                                    <div v-if="!showForm">
                                      <div v-for="crit in criticalities" :key="crit.id" class="mb-3">
                                        <strong>{{ crit.name }}:</strong> {{ crit.max_resolution_time }} days
                                      </div>
                                      <button @click="showForm = true" class="btn btn-primary">Modify</button>
                                    </div>
                                    <form v-else @submit.prevent="saveSettings">
                                        <div v-for="crit in criticalities" :key="crit.id" class="mb-3">
                                        <label :for="'crit-' + crit.id" class="form-label">{{ crit.name }}</label>
                                        <input 
                                            :id="'crit-' + crit.id"
                                            v-model="crit.max_resolution_time"
                                            type="number"
                                            class="form-control"
                                            min="1"
                                            required
                                        >
                                        </div>
                                        <button type="submit" class="btn btn-primary">Save </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    

                        <div class="tab-pane fade" id="document" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter documents</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.document" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="general" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter General</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.general" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="comment" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter comments</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.comment" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="performance" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="flex-grow-1 me-6">
                                            <h5 class="card-title">Parameter performances</h5>
                                        </div>
                                    </div>

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.performance" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="security" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Parameter security</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.security" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'simple')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="cron" role="tabpanel">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="flex-grow-1 me-6">
                                            <h5 class="card-title">Parameter Cron</h5>
                                        </div>
                                    </div>

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Checked</th>
                                                <th>Fréquence</th>
                                                <th>Heure</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(param, index) in params.cron" :key="index">
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <label class="form-check-label">
                                                            {{ param.param_name }}
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox"
                                                            :value="param.checked" v-model="param.checked"
                                                            @click="setcheckedParams(param.id)">
                                                    </div>
                                                </td>
                                                <td>
                                                    <select class="form-select" aria-label="Frequency"
                                                        :value="param.frequency" v-model="param.frequency">
                                                        <option v-for="(freq, freqIndex) in FREQUENCE_CHOICES"
                                                            :key="freqIndex" :value="freq[0]">{{ freq[1] }}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="time" class="form-control" placeholder="Heure"
                                                            aria-label="Heure" v-model="param.time">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#params-update-modal"
                                                            @click="initFormData(param.id, 'cron')"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit-2 align-middle">
                                                                <path
                                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage == 2">
            <div class="row">
                <div class="col-md-3 col-xl-2">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title mb-0">EVALUATION</h5>
                        </div>

                        <div class="list-group list-group-flush" role="tablist">
                            <a class="list-group-item list-group-item-action active" data-bs-toggle="list" href="#nouvelle"
                                role="tab">
                                Nouvelle
                            </a>
                            <a v-show="collaboratorDatas.length" class="list-group-item list-group-item-action" data-bs-toggle="list"
                                href="#collaborateur" role="tab">
                                Collaborateur
                            </a>
                            <a v-show="supervisorDatas.length" class="list-group-item list-group-item-action" data-bs-toggle="list" href="#superviseur"
                                role="tab">
                                Superviseur
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-9 col-xl-10">
                    <div class="tab-content">
                        <div class="tab-pane fade" id="collaborateur" role="tabpanel">
                            <div v-for="(rebriqueData, rebriqueIndex) in collaboratorDatas" :key="rebriqueIndex"
                                class="card mb-3">
                                <div class="chat-messages card-body">
                                    <h5 class="card-title">{{ 'Rebrique ' + (rebriqueIndex + 1) }}</h5>
                                    <div class="row">
                                        <div class="col-sm-11">
                                            <input v-model="rebriqueData.name" type="text" class="form-control"
                                                placeholder="Nom de la rebrique" required />
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="text-end">
                                                <button @click="editRebrique(rebriqueData)" type="button"
                                                    class="btn btn-secondary" title="Modifier la rebrique">
                                                    <i class="align-middle fas fa-fw fa-pencil"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="(template, templateIndex) in rebriqueData.templates" :key="templateIndex"
                                        class="mb-3">
                                        <hr>
                                        <div class="row g-3">
                                            <div class="col-sm-6">
                                                <input v-model="template.question" type="text" class="form-control"
                                                    placeholder="Question" required />
                                            </div>
                                            <div class="col-sm-3">
                                                <select v-model="template.using_by" class="form-select" required>
                                                    <option value="sup_and_col">Superviseur et Collaborateur</option>
                                                    <option value="collaborator">Collaborateur uniquement</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-3">
                                                <select v-model="template.type_field" class="form-select" required>
                                                    <option value="input">Input</option>
                                                    <option value="textarea">Textarea</option>
                                                    <option value="select">Select</option>
                                                    <!-- <option value="checkbox">CheckBox</option> -->
                                                    <option value="radio">Radio</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input-group">
                                                    <input v-model="template.data" type="text" class="form-control"
                                                        placeholder="Valeur possible" />
                                                    <span class="input-group-text" data-bs-toggle="popover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Saisissez les valeurs séparées par des virgules (exemple: 1,2,3,4,5)">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-5">
                                                <div class="input-group">
                                                    <textarea v-model="template.notes" class="form-control" rows="1"
                                                        placeholder="Notes"></textarea>
                                                    <span class="input-group-text" data-bs-toggle="popover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Ajoutez plus d'explications à la question ou valeur par défaut. Exemple : 1 à 5 niveau croissant du rendement">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-1">
                                                <div class="form-check form-switch mt-1">
                                                    <input class="form-check-input" title="Utilisable" type="checkbox" v-model="template.is_active" id="flexSwitchCheckChecked" @click="setStatus(template.id)">
                                                    <label class="form-check-label" for="flexSwitchCheckChecked">Etat</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="form-check form-switch mt-1">
                                                    <input class="form-check-input" title="Utilisable" type="checkbox" v-model="template.justify" id="flexSwitchCheckChecked" @click="setJustity(template.id)">
                                                    <label class="form-check-label" for="flexSwitchCheckChecked">Justifiable</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start">
                                        <button @click="addEditTemplateCol(rebriqueIndex)" type="button"
                                            class="btn btn-primary">Ajouter un template</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="superviseur" role="tabpanel">
                            <div v-for="(rebriqueData, rebriqueIndex) in supervisorDatas" :key="rebriqueIndex"
                                class="card mb-3">
                                <div class="chat-messages card-body">
                                    <h5 class="card-title">{{ 'Rebrique ' + (rebriqueIndex + 1) }}</h5>
                                    <div class="row">
                                        <div class="col-sm-11">
                                            <input v-model="rebriqueData.name" type="text" class="form-control"
                                                placeholder="Nom de la rebrique" required />
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="text-end">
                                                <button @click="editRebrique(rebriqueData)" type="button"
                                                    class="btn btn-secondary" title="Modifier la rebrique">
                                                    <i class="align-middle fas fa-fw fa-pencil"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="(template, templateIndex) in rebriqueData.templates" :key="templateIndex"
                                        class="mb-3">
                                        <hr>
                                        <div class="row g-3">
                                            <div class="col-sm-6">
                                                <input v-model="template.question" type="text" class="form-control"
                                                    placeholder="Question" required />
                                            </div>
                                            <div class="col-sm-3">
                                                <select v-model="template.using_by" class="form-select" required>
                                                    <option value="sup_and_col">Superviseur et Collaborateur</option>
                                                    <option value="supervisor">Superviseur uniquement</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-3">
                                                <select v-model="template.type_field" class="form-select" required>
                                                    <option value="input">Input</option>
                                                    <option value="textarea">Textarea</option>
                                                    <option value="select">Select</option>
                                                    <!-- <option value="checkbox">CheckBox</option> -->
                                                    <option value="radio">Radio</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input-group">
                                                    <input v-model="template.data" type="text" class="form-control"
                                                        placeholder="Valeur possible" />
                                                    <span class="input-group-text" data-bs-toggle="popover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Saisissez les valeurs séparées par des virgules (exemple: 1,2,3,4,5)">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-5">
                                                <div class="input-group">
                                                    <textarea v-model="template.notes" class="form-control" rows="1"
                                                        placeholder="Notes"></textarea>
                                                    <span class="input-group-text" data-bs-toggle="popover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Ajoutez plus d'explications à la question ou valeur par défaut. Exemple : 1 à 5 niveau croissant du rendement">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-1">
                                                <div class="form-check form-switch mt-1">
                                                    <input class="form-check-input" title="Utilisable" type="checkbox" v-model="template.is_active" id="flexSwitchCheckChecked" @click="setStatus(template.id)">
                                                    <label class="form-check-label" for="flexSwitchCheckChecked">Etat</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-1">
                                                <div class="form-check form-switch mt-2">
                                                    <input class="form-check-input" title="Utilisable" type="checkbox" v-model="template.justify" id="flexSwitchCheckChecked" @click="setJustity(template.id)">
                                                    <label class="form-check-label" for="flexSwitchCheckChecked">Justifiable</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start">
                                        <button @click="addEditTemplateSup(rebriqueIndex)" type="button"
                                            class="btn btn-primary">Ajouter un template</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="nouvelle" role="tabpanel">
                            <div class="container">
                                <h3>Ajouter des Rebriques et Questions</h3>
                                <hr>
                                <form @submit.prevent="submitForm">
                                    <div v-for="(rebrique, rebriqueIndex) in rebriqueFormData" :key="rebriqueIndex"
                                        class="card mb-3">
                                        <div class="chat-messages card-body">
                                            <h5 class="card-title">Rebrique {{ rebriqueIndex + 1 }}</h5>
                                            <div class="row">
                                                <div class="col-sm-11">
                                                    <input v-model="rebrique.name" type="text" class="form-control"
                                                        placeholder="Nom de la rebrique" required />
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="text-end">
                                                        <button @click="removeRebrique(rebriqueIndex)" type="button"
                                                            class="btn btn-secondary" title="Supprimer la rebrique">
                                                            <i class="align-middle fas fa-fw fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(template, templateIndex) in rebrique.templates"
                                                :key="templateIndex" class="mb-3">
                                                <hr>
                                                <div class="row g-3">
                                                    <div class="col-sm-6">
                                                        <input v-model="template.question" type="text" class="form-control"
                                                            placeholder="Question" required />
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <select v-model="template.using_by" class="form-select" required>
                                                            <option value="sup_and_col">Superviseur et Collaborateur
                                                            </option>
                                                            <option value="supervisor">Superviseur uniquement</option>
                                                            <option value="collaborator">Collaborateur uniquement</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <select v-model="template.type_field" class="form-select" required>
                                                            <option value="input">Input</option>
                                                            <option value="textarea">Textarea</option>
                                                            <option value="select">Select</option>
                                                            <!-- <option value="checkbox">CheckBox</option> -->
                                                            <option value="radio">Radio</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="input-group">
                                                            <input v-model="template.data" type="text" class="form-control"
                                                                placeholder="Valeur possible" />
                                                            <span class="input-group-text" data-bs-toggle="popover"
                                                                data-bs-placement="top"
                                                                data-bs-content="Saisissez les valeurs séparées par des virgules (exemple: 1,2,3,4,5)">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <div class="input-group">
                                                            <textarea v-model="template.notes" class="form-control" rows="1"
                                                                placeholder="Notes"></textarea>
                                                            <span class="input-group-text" data-bs-toggle="popover"
                                                                data-bs-placement="top"
                                                                data-bs-content="Ajoutez plus d'explications à la question ou valeur par défaut. Exemple : 1 à 5 niveau croissant du rendement">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-1">
                                                        <div class="form-check form-switch mt-1">
                                                            <input class="form-check-input" title="Utilisable" type="checkbox" v-model="template.justify" id="flexSwitchCheckChecked" @click="setStatus(template.id)">
                                                            <label class="form-check-label" for="flexSwitchCheckChecked">Justifiable</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <div class="text-end">
                                                            <button @click="removeTemplate(rebriqueIndex, templateIndex)"
                                                                type="button" class="btn btn-outline-danger"
                                                                title="Supprimer le template">
                                                                <i class="align-middle fas fa-fw fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <button @click="addTemplate(rebriqueIndex)" type="button"
                                                    class="btn btn-primary">Ajouter un
                                                    template</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button @click="addLigneRebrique" type="button" class="btn btn-primary me-2">Ajouter
                                            une
                                            rebrique</button>
                                        <button type="submit" class="btn btn-success">Soumettre</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="params-update-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Parameter</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <form v-if="type == 'cron'" @submit.prevent="updateParamter" class="row g-3 needs-validation">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">Notification Title <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="updateCronFormData.notification_title" class="form-control"
                                        id="validationCustom01" placeholder="Name" required>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">Notification Content <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <textarea class="form-control" name="description" rows="5"
                                        v-model="updateCronFormData.notification_content" id="validationCustom07"
                                        placeholder="Enter descriotion" required></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label for="validationCustom02" class="form-label">Frequency <i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <select class="form-select" aria-label="Frequency" :value="updateCronFormData.frequency"
                                    v-model="updateCronFormData.frequency">
                                    <option v-for="(freq, freqIndex) in FREQUENCE_CHOICES" :key="freqIndex"
                                        :value="freq[0]">{{ freq[1] }}</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label for="validationCustom02" class="form-label">Time <i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <input type="time" class="form-control" placeholder="Heure" aria-label="Heure"
                                    v-model="updateCronFormData.time">
                            </div>
                            <div class="col-12 text-end">
                                <button v-show="isLoad" class="btn btn-primary" type="submit" id="add_project">
                                    <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                    <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                </button>
                                <button v-show="!isLoad" class="btn btn-primary" type="submit">Save</button>
                            </div>
                        </form>
                        <form v-else @submit.prevent="updateParamter" class="row g-3 needs-validation">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">Notification Title <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="updateFormData.notification_title" class="form-control"
                                        id="validationCustom01" placeholder="Name" required>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">Notification Content <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <textarea class="form-control" name="description" rows="5"
                                        v-model="updateFormData.notification_content" id="validationCustom07"
                                        placeholder="Enter descriotion" required></textarea>
                                </div>
                            </div>
                            <div class="col-12 text-end">
                                <button v-show="isLoad" class="btn btn-primary" type="submit" id="add_project">
                                    <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                    <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                </button>
                                <button v-show="!isLoad" class="btn btn-primary" type="submit">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
const userHostname = window.location;
export default {
    name: 'SettingsPage',
    data() {
        return {
            params: [],
            showForm: false,
            criticalities: [],
            FREQUENCE_CHOICES: [
                ['Days', 'Days'],
                ['Week', 'Week'],
                ['Month', 'Month'],
                ['Quarter', 'Quarter'],
                ['Semester', 'Semester'],
                ['Year', 'Year'],
            ],
            isLoad: false,
            type: 'sipmle',
            updateFormData: {
                id: null,
                notification_title: '',
                notification_content: ''
            },
            updateCronFormData: {
                id: null,
                notification_title: '',
                notification_content: '',
                frequency: '',
                time: ''
            },
            collaboratorDatas: [],
            supervisorDatas: [],
            currentPage: 1,
            templates: [],
            rebriqueFormData: [
                {
                    name: 'General',
                    templates: [
                        {
                            using_by: 'sup_and_col',
                            question: null,
                            data: null,
                            notes: null,
                            justify: false,
                            type_field: 'input'
                        }
                    ]
                }
            ]
        };
    },
    async mounted() {
        await this.initAppParams()
        await this.initEvaluationsTemplate()
        this.criticalities = await this.getCriticalities()
        // Enable Bootstrap Popover
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('auth', ['getCriticalities', 'updateCriticalitySettings']),
        ...mapActions('parameter', ['getAppParams', 'checkedParams', 'updateParamsContent', 'setCronParams', 'getParam']),
        ...mapActions('evaluation', ['addRebrique', 'getEvaluationsTemplateByUsingByRebrique', 'updateRebrique', 'setTemplateEvaluationStat', 'setTemplateEvaluationJustify']),
        async initAppParams() {
            let response = await this.getAppParams()
            this.params = response
        },
        changePage(number) {
            this.currentPage = number
        },
        async initEvaluationsTemplate() {
            let response = await this.getEvaluationsTemplateByUsingByRebrique()
            this.supervisorDatas = response.supervisor_evaluation_templates
            this.collaboratorDatas = response.collaborator_evaluation_templates
        },
        async saveSettings() {
            try {
                await this.updateCriticalitySettings(this.criticalities);
                this.$successNotyf('Criticality settings updated successfully');
                this.showForm = false;
            } catch (error) {
                this.$errorNotyf('Failed to update criticality settings');
            }
        },
        addLigneRebrique() {
            this.rebriqueFormData.push({
                name: 'General',
                templates: [
                    {
                        using_by: 'sup_and_col',
                        question: null,
                        data: null,
                        notes: null,
                        justify: false,
                        type_field: 'input',
                    },
                ],
            });
        },
        setStatus(id){
            this.isLoad = true
            try {
                if(id) this.setTemplateEvaluationStat(id)
                this.successResponse('Questions stat set to successful')
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        setJustity(id){
            this.isLoad = true
            try {
                if(id) this.setTemplateEvaluationJustify(id)
                this.successResponse('Questions justify set to successful')
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        async editRebrique(rebriqueData){
            this.isLoad = true
            try {
                await this.updateRebrique(rebriqueData)
                this.successResponse('Rebriques et Questions edit to successful')
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        errorResponse(error){
            this.$errorNotyf(error)
        },
        successResponse(message){
            this.$successNotyf(message)
        },
        addTemplate(rebriqueIndex) {
            this.rebriqueFormData[rebriqueIndex].templates.push(this.formatData('sup_and_col'));
        },
        addEditTemplateCol(rebriqueIndex) {
            this.collaboratorDatas[rebriqueIndex].templates.push(this.formatData('collaborator'));
        },
        addEditTemplateSup(rebriqueIndex) {
            this.supervisorDatas[rebriqueIndex].templates.push(this.formatData('supervisor'));
        },
        formatData(default_type){
            return {
                id: null,
                using_by: default_type,
                question: null,
                data: null,
                is_active: true,
                justify: false,
                notes: null,
                type_field: 'input',
            }
        },
        removeTemplate(rebriqueIndex, templateIndex) {
            if (this.rebriqueFormData[rebriqueIndex].templates.length > 1) {
                this.rebriqueFormData[rebriqueIndex].templates.splice(templateIndex, 1);
            }
        },
        removeRebrique(rebriqueIndex) {
            if (this.rebriqueFormData.length > 1) {
                this.rebriqueFormData.splice(rebriqueIndex, 1);
            }
        },
        async submitForm() {
            this.isLoad = true
            try {
                await this.addRebrique(this.rebriqueFormData)
                this.$successNotyf('Rebriques et Questions add to successful')
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
        async initFormData(id, type) {
            this.type = type
            let response = await this.getParam(id)
            this.updateFormData.id = this.updateCronFormData.id = response.id
            this.updateFormData.notification_title = this.updateCronFormData.notification_title = response.notification_title
            this.updateFormData.notification_content = this.updateCronFormData.notification_content = response.notification_content
            if (this.type == 'cron') {
                this.updateCronFormData.frequency = response.frequency
                this.updateCronFormData.time = response.time
            }
        },
        async setcheckedParams(id) {
            this.isLoad = true
            try {
                await this.checkedParams(id)
                this.$successNotyf('Parameter set to successful')
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
        async updateParamter() {
            this.isLoad = true
            try {
                if (this.type == 'simple') {
                    await this.updateParamsContent(this.updateFormData)
                } else {
                    await this.setCronParams(this.updateCronFormData)
                    this.updateCronFormData.frequency = ''
                    this.updateCronFormData.time = ''
                }
                await this.initAppParams()
                this.updateFormData.id = this.updateCronFormData.id = null
                this.updateFormData.notification_title = this.updateCronFormData.notification_title = ''
                this.updateFormData.notification_content = this.updateCronFormData.notification_content = ''
                this.$successNotyf('Parameter set to successful')
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
    }
}
</script>