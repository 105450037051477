import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {

  addGrade({ commit, dispatch, state }, gradeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-grade', gradeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateGrade({ commit, dispatch, state }, gradeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-grade/'+gradeData.id, gradeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  grades({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('grades')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  grade({ commit, dispatch, state }, gradeId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('grade/' + gradeId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
