<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <div class="col-auto d-sm-block">
                        <h3><strong>{{ $t('page.services.service') }}</strong></h3>
                    </div>
                    <div class="col-auto ms-auto text-end mt-n1">
                        <router-link v-if="$can('can_configurate_service_task')" class="btn btn-primary rounded me-2"
                            :to="{ name: 'add-service-task', params: { id: service.id } }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-eye align-middle">
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                            {{ $t('page.service-detail.edit_task') }}
                        </router-link>
                        <button class="btn btn-success my-1 me-2" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightDocument" aria-controls="offcanvasRight">{{ $t('page.service-detail.list_document') }}</button>
                        <button @click="goBack" class="btn btn-secondary" title="Back"><i class="align-middle fas fa-fw fa-arrow-left"></i></button>
                    </div>
                </div>
            </div>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightDocument"
                aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">{{ $t('page.service-detail.list_document') }}</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <p>{{ $t('page.service-detail.list') }}</p>
                    <table class="table" v-if=documents.length>
                        <thead>
                            <tr>
                                <th>{{ $t('general.name') }}</th>
                                <th>{{ $t('general.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="document in documents" :key="document.id">
                                <td><i class="align-middle me-2 fas fa-fw fa-comment-alt"
                                        @click="viewDocDescription(document.id, 'doc')" data-bs-toggle="modal"
                                        data-bs-target="#decription-doc"></i> {{ document.name }}</td>
                                <td class="table-action">
                                    <div class="form-check form-switch" v-if="$can('can_set_service_doc')">
                                        <span>
                                            <input v-if="(document.status == 'archive') && ($can('can_set_service_doc'))"
                                                @click="changeDocServiceStatus(document.id, document.status)"
                                                class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
                                            <input v-else @click="changeDocServiceStatus(document.id, document.status)"
                                                class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                                checked>
                                        </span>
                                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-2 align-middle">
                                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                            </svg></a>
                                    </div>
                                    <div v-else>...</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="alert alert-primary alert-dismissible" role="alert">
                        <div class="alert-icon">
                            <i class="far fa-fw fa-bell"></i>
                        </div>
                        <div class="alert-message">
                            {{ $t('page.service-detail.no_documents') }}
                        </div>
                    </div>
                    <button v-if="$can('can_configurate_service_doc')" data-bs-toggle="modal" data-bs-target="#new-service-doc-modal"
                        class="btn btn-outline-primary rounded">
                        <i class="fas fa-plus"></i> {{ $t('page.service-detail.edit_list_doc') }}
                    </button>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title mb-0">
                        <div class="flex-grow-1">
                            <span class="float-end text-navy">
                                <div class="flex-grow-1">
                                    <span class="badge bg-warning me-2">{{ service.category.name }}</span>
                                    <strong class="float-end text-navy">
                                        <span v-show="service.status == 'archive'" class="badge bg-danger">Close</span>
                                        <span v-show="service.status == 'unarchive'"
                                            class="badge bg-success">Open</span>
                                    </strong>
                                </div>
                            </span>
                            <strong>{{ service.name }} (AB00{{ service.id }})</strong>
                        </div>
                    </h5>
                </div>
                <div class="card-body h-100">
                    <div class="d-flex align-items-start">
                        <div class="flex-grow-1">
                            <h4>{{ $t('general.description') }}</h4>
                        </div>
                    </div>
                    <div class="card p-2 mt-2">
                        <div class="card p-2 mt-2">
                            {{ $t('general.periodicity') }} : {{ service.type }} <br>
                            {{ $t('general.frequency') }} : {{ service.cyclique_number }}
                        </div><br>
                        {{ service.description }}
                    </div>
                    <hr>
                </div>
            </div>
            <div class="modal fade" id="new-service-doc-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.service-detail.edit_list_doc') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="saveServiceDoc" class="row g-3">
                                <div v-for="(doc_add, key) in documentFormData" :key="key" class="card m-1">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-11">
                                                <label class="form-label">{{ $t('page.service-detail.document_name') }}<i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="doc_add.name" class="form-control"
                                                    placeholder="Document name" required>
                                            </div>
                                            <div class="col-md-1 pt-4">
                                                <span @click="dropStoreLine(key)" class="btn btn-sm btn-danger"><i
                                                        class="align-middle fas fa-fw fa-trash"></i></span>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="form-label">{{ $t('general.description') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <textarea v-model="doc_add.description" class="form-control"
                                                    rows="2" required
                                                    placeholder="Enter the document description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="col-sm-12 m-0">
                                    <span @click="addLigne" class="btn btn-outline-success"><i class="fas fa-plus"></i>{{ $t('page.service-detail.add_document') }}</span>
                                </div>
                                <div class="col-sm-12 text-end">
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('forms.save_list_docs') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="decription-doc" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('forms.document') }} : {{ this_document?.name }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form class="row g-3">
                                <div class="card m-0">
                                    <div class="card-body">
                                        <p>{{ this_document?.description }}</p>
                                        {{ $t('general.status') }} : <span v-if="this_document?.status == 'unarchive'"
                                            class="text-end badge bg-primary">{{ $t('general.unarchive') }}</span>
                                        <span v-else class="text-end badge bg-warning">{{ $t('general.archive') }}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="edit-service-task" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.service-detail.new_service_task') }}</h5>
                            <button type="button" class="btn-close btn btn-danger me-4" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="updateServiceTask_" class="row g-3">
                                <div class=" m-1">
                                    <div class="row g-3">
                                        <div class="col-md-6">
                                            <label class="form-label">{{ $t('page.service-detail.task_name') }}<i
                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                            <input type="text" class="form-control" v-model="serviceTaskFormData.name"
                                                placeholder="Task name" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">{{ $t('general.severity') }}<i
                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                            <select class="form-select" v-model="serviceTaskFormData.severity"
                                                required>
                                                <option selected>{{ $t('general.choice') }}...</option>
                                                <option value="Low">{{ $t('general.low') }}</option>
                                                <option value="Medium">{{ $t('general.medium') }}</option>
                                                <option value="High">{{ $t('general.high') }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">{{ $t('forms.min_et') }}<i
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    :title="$t('forms.min_et_description')"
                                                    class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></label>
                                            <input type="number" v-model="serviceTaskFormData.minimal_time" min="1"
                                                class="form-control" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">{{ $t('forms.max_et') }}<i
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    :title="$t('forms.max_et_description')"
                                                    class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></label>
                                            <input type="number" v-model="serviceTaskFormData.maximal_time" min="1"
                                                class="form-control" required>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="form-label">{{ $t('general.description') }} <i
                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                            <textarea class="form-control" v-model="serviceTaskFormData.description"
                                                rows="3" placeholder="Enter descriotion"
                                                required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-end">
                                    <button v-show="isStart" class="btn btn-primary" type="submit">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status">
                                        </div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" class="btn btn-primary" type="submit">{{ $t('general.save') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card flex-fill p-4">
                    <div class="card-header">

                        <h5 class="card-title mb-0">{{ $t('page.service-detail.task_list') }}</h5>
                    </div>
                    <table class="table table-hover my-0" id="datatables-reponsive">
                        <thead>
                            <tr>
                                <th class="d-md-table-cell">{{ $t('general.id') }}</th>
                                <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                <th class="d-xl-table-cell">{{ $t('general.severity') }}</th>
                                <th class="d-xl-table-cell">
                                    <span>
                                        {{ $t('page.service-detail.min_time') }}
                                    </span>
                                </th>
                                <th class="d-xl-table-cell">
                                    <span>
                                        {{ $t('page.service-detail.max_time') }}
                                    </span>
                                </th>
                                <th class="d-xl-table-cell"><span
                                        title="Checkbox to enable or disable the task">{{ $t('general.state') }}</span></th>
                                <th class="d-xl-table-cell">{{ $t('general.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="task in tasks" :key="task.id">
                                <td><i data-bs-toggle="modal" data-bs-target="#decription-doc"
                                        class="btn btn-link align-middle me-2 fas fa-fw fa-comment-alt"
                                        @click="viewDocDescription(task.id, 'task')"></i> AB0{{ task.id }}</td>
                                <td class="d-xxl-table-cell">
                                    <div class="text-muted">
                                        {{ task.name }}
                                    </div>
                                </td>
                                <td class="d-xxl-table-cell">
                                    <span v-show="task.severity == 'Low'" class="badge bg-primary">{{ task.severity
                                    }}</span>
                                    <span v-show="task.severity == 'Medium'" class="badge bg-warning">{{ task.severity
                                    }}</span>
                                    <span v-show="task.severity == 'High'" class="badge bg-danger">{{ task.severity
                                    }}</span>
                                </td>
                                <td class="d-xxl-table-cell">
                                    <div class="text-muted">
                                        {{ task.minimal_time }} {{ $t('general.hours') }}
                                    </div>
                                </td>
                                <td class="d-xxl-table-cell">
                                    <div class="text-muted">
                                        {{ task.maximal_time }} {{ $t('general.hours') }}
                                    </div>
                                </td>
                                <td class="d-xxl-table-cell">
                                    <div class="form-check form-switch">
                                        <input v-if="task.status == 'archive'" :disabled="!$can('can_set_service_task')"
                                            @click="changeTaskServiceStatus(task.id, task.status)" class="form-check-input"
                                            type="checkbox" id="flexSwitchCheckChecked">
                                        <input v-else @click="changeTaskServiceStatus(task.id, task.status)" :disabled="!$can('can_set_service_task')"
                                            class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                    </div>
                                </td>
                                <td class="table-action">
                                    <span @click="addServiceTaskDetail(task.id)" class="btn-link" data-bs-toggle="modal" v-if="$can('service_tasks_update')"
                                        data-bs-target="#edit-service-task"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-2 align-middle">
                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                        </svg></span>
                                    <span v-else>...</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'Vide'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'ServiceDetail',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            documents: null,
            tasks: null,
            service: null,
            isDispo: false,
            this_document: {},
            documentFormData: [
                {
                    service: this.$route.params.id,
                    creator: null,
                    name: null,
                    description: null
                }
            ],
            serviceTaskFormData: {},
            serviceTask: {}
        };
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    async mounted() {
        await this.serviceDetail()
        let table = new DataTable('#datatables-reponsive'); // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('service', ['getService']),
        ...mapActions('service_task', ['getServiceTasks', 'updateServiceTaskStatus', 'updateServiceTask']),
        ...mapActions('service_doc', ['getServiceDocLists', 'addServiceDoc', 'updateServiceDocStatus']),
        goBack() {
            window.history.go(-1);
        },
        async updateServiceTask_() {
            this.isStart = true
            let response
            try {
                response = await this.updateServiceTask(this.serviceTaskFormData)
                this.$successNotyf('Successfully updated service task.')
                jQuery('#edit-service-task').modal('toggle'); // eslint-disable-line no-undef
                this.serviceTaskFormData = {}
                await this.serviceDetail()
                this.isStart = false
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        addServiceTaskDetail(taskId) {
            this.serviceTask = this.tasks.find(element => element.id === taskId);
            this.serviceTaskFormData = {
                id: taskId,
                service: this.serviceTask.service,
                creator: this.serviceTask.creator,
                name: this.serviceTask.name,
                description: this.serviceTask.description,
                severity: this.serviceTask.severity,
                minimal_time: this.serviceTask.minimal_time,
                maximal_time: this.serviceTask.maximal_time,
                status: this.serviceTask.status
            }
        },
        viewDocDescription(id, type) {
            if (type == 'doc')
                this.this_document = this.documents.find(element => element.id === id);
            else
                this.this_document = this.tasks.find(element => element.id === id);
        },
        async serviceDetail() {
            try {
                let service_id = this.$route.params.id
                this.service = await this.getService(service_id)
                this.tasks = await this.getServiceTasks(this.service.id)
                this.documents = await this.getServiceDocLists(this.service.id)
                this.isDispo = true
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async changeTaskServiceStatus(serviceTaskId, status) {
            let data
            if (status == 'archive') {
                data = {
                    task_id: serviceTaskId,
                    status: 'unarchive'
                }
            } else {
                data = {
                    task_id: serviceTaskId,
                    status: 'archive'
                }
            }
            await this.updateServiceTaskStatus(data)
        },
        async changeDocServiceStatus(documentId, status){
            let data
            if (status == 'archive') {
                data = {
                    document_id: documentId,
                    status: 'unarchive'
                }
            } else {
                data = {
                    document_id: documentId,
                    status: 'archive'
                }
            }
            await this.updateServiceDocStatus(data).then(data => {
                this.$successNotyf('Successfully')
            })
        },
        async saveServiceDoc() {
            this.isStart = true
            for (let i = 0; i < this.documentFormData.length; i++) {
                this.documentFormData[i].creator = this.getCurrentUser.id;
            }
            try {
                await this.addServiceDoc(this.documentFormData)
                this.$successNotyf('Successfully created service document.')
                this.serviceDetail()
                jQuery('#new-service-doc-modal').modal('toggle'); // eslint-disable-line no-undef
                this.documentFormData = [{
                    service: this.$route.params.id,
                    creator: null,
                    name: null,
                    description: null
                }]
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        addLigne() {
            this.documentFormData.push(
                {
                    service: this.$route.params.id,
                    creator: null,
                    name: null,
                    description: null
                }
            )
        },
        dropStoreLine(key) {
            if (this.documentFormData.length >= 2) {
                this.documentFormData.splice(key, 1)
            }
        },
    }
}
</script>
<style></style>
    