import axiosWithHeaders from '../services/api';


const state = {
    auditTrails: []
  };
  
  const getters = {
    getAuditTrails: (state) => state.auditTrails
  };
  
  const mutations = {
    setAuditTrails: (state, auditTrails) => {
      state.auditTrails = auditTrails;
    },
    addAuditTrail: (state, auditTrail) => {
      state.auditTrails.unshift(auditTrail);
    }
  };
  

const actions = {
  addAuditTrail({ commit }, auditTrailData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-audit-trail', auditTrailData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  logAuditTrails({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('audit-trails')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  userLogAuditTrails({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`list-audit-trail/user/${userId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
