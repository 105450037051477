import axiosWithHeaders from '../services/api';


  const state = {
    categories: []
  };
  
  const getters = {
    categories: state => state.categories
  };
  
  const mutations = {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    }
  };
  

const actions = {
    createCategorie({ commit }, data) {
        return new Promise((resolve, reject) => {
          axiosWithHeaders.post('categorie-create', data)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
    },
    getCategorie({ commit }, categorieId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`categorie/${categorieId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    getListeServiceCategories({ commit }, categorieId) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get(`categorie-service-lists/${categorieId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    categories({ commit }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get('categories')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    updateCategorie({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`categorie-update/${data.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
