import { createStore } from 'vuex';
import auth from './auth';
import project from './project';
import service from './service';
import categorie from './categorie';
import service_doc from './service_doc';
import service_task from './service_task';
import comment from './comment';
import entreprise from './entreprise';
import task_assignment from './task_assignment';
import ticket from './ticket';
import user_role from './user_role';
import user_type from './user_type';
import audit_trail from './audit_trail';
import permission from './permission';
import grade from './grade';
import statistique from './statistique';
import notification from './notification';
import parameter from './parameter';
import evaluation from './evaluation';
import device from './device';

export default createStore({
  modules: {
    auth,
    user_role,
    user_type,
    permission,
    grade,
    service,
    categorie,
    service_doc,
    service_task,
    entreprise,
    project,
    task_assignment,
    ticket,
    comment,
    audit_trail,
    statistique,
    notification,
    parameter,
    evaluation,
    device
  },
});
