<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <div class="flex-grow-1 me-6">
                                <input type="text" id="date-range" class="form-control" placeholder="Choose a date range..."
                                    v-model="selectedDates" />
                            </div>
                            <div>
                                <div class="btn-group" role="group" aria-label="Navigation">
                                    <button type="button" :class="2 == currentPage ? 'active' : ''" class="btn btn-light"
                                        @click="changePage(2)">{{ $t('page.statistiques.performances') }}</button>
                                    <button type="button" :class="1 == currentPage ? 'active' : ''" class="btn btn-light"
                                        @click="changePage(1)">{{ $t('page.statistiques.statistiques') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="w-100">
                <div class="row">
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h5 class="card-title">{{ $t('page.statistiques.pending') }}</h5>
                                </div>
                                <h1 class="mt-1 mb-3">{{ statistiques.pending_projects }}</h1>
                                <div class="mb-0">
                                    <span
                                        :class="(statistiques.pending_projects / statistiques.total_projects) * 100 < 50 ? 'badge badge-danger-light' : 'badge badge-success-light'">
                                        <i class="mdi mdi-arrow-bottom-right"></i>
                                        AVG : {{ ((statistiques.pending_projects / statistiques.total_projects) *
                                            100).toFixed(2) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h5 class="card-title">{{ $t('page.statistiques.in_progress') }}</h5>
                                </div>
                                <h1 class="mt-1 mb-3">{{ statistiques.progress_projects }}</h1>
                                <div class="mb-0">
                                    <span
                                        :class="(statistiques.progress_projects / statistiques.total_projects) * 100 < 50 ? 'badge badge-danger-light' : 'badge badge-success-light'">
                                        <i class="mdi mdi-arrow-bottom-right"></i>
                                        AVG : {{ ((statistiques.progress_projects / statistiques.total_projects) *
                                            100).toFixed(2) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h5 class="card-title">{{ $t('page.statistiques.completed') }}</h5>
                                </div>
                                <h1 class="mt-1 mb-3">{{ statistiques.completed_projects }}</h1>
                                <div class="mb-0">
                                    <span
                                        :class="(statistiques.completed_projects / statistiques.total_projects) * 100 < 50 ? 'badge badge-danger-light' : 'badge badge-success-light'">
                                        <i class="mdi mdi-arrow-bottom-right"></i>
                                        AVG : {{ ((statistiques.completed_projects / statistiques.total_projects) *
                                            100).toFixed(2) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h5 class="card-title">{{ $t('page.statistiques.total_project') }}</h5>
                                </div>
                                <h1 class="mt-1 mb-3">{{ statistiques.projects }}</h1>
                                <div class="mb-0">
                                    <span
                                        :class="calculAvg(statistiques.projects, statistiques.total_projects) < 50 ? 'badge badge-danger-light' : 'badge badge-success-light'">
                                        <i class="mdi mdi-arrow-bottom-right"></i>
                                        AVG : {{ calculAvg(statistiques.projects,
                                            statistiques.total_projects).toFixed(2) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h5 class="card-title">{{ $t('page.statistiques.total_cost') }}</h5>
                                </div>
                                <h1 class="mt-1 mb-3">$ {{ statistiques.cost ? formatNumber(statistiques.cost) : 0 }}</h1>
                                <div class="mb-0">
                                    <span
                                        :class="calculAvg(statistiques.cost, statistiques.total_cost) < 50 ? 'badge badge-danger-light' : 'badge badge-success-light'">
                                        <i class="mdi mdi-arrow-bottom-right"></i>
                                        AVG : {{ calculAvg(statistiques.cost, statistiques.total_cost).toFixed(2) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 1">
            <div class="row gy-5 g-xl-10 mb-5">
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="project-cost-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.stasts_gene') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="user-count-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.type_user') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="category-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.catégories') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="grade-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.grades') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="service-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.services') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="cost-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.projects') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="task-cost-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.tasks') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 2">
            <div class="row p-3">
                <div class="card p-3" v-show="show_tasks">
                    <div class="card-body">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <h5 class="card-title">{{ $t('page.statistiques.liste_tache') }}</h5>
                            <button type="button" class="btn btn-sm btn-danger"
                                @click="toggleTasks()">
                                -
                            </button>
                        </div>
                        <table v-show="tasks.length" class="table table-striped">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.statistiques.name') }}</th>
                                    <th>{{ $t('page.statistiques.assigne') }}</th>
                                    <th>{{ $t('page.statistiques.status') }}</th>
                                    <th title="Validated date">{{ $t('page.statistiques.validated') }}</th>
                                    <th title="Supervisor note">{{ $t('page.statistiques.note') }}</th>
                                    <th>{{ $t('page.statistiques.cost') }}</th>
                                    <th title="Estimated duration">{{ $t('page.statistiques.duration') }}</th>
                                    <th>{{ $t('page.statistiques.start_date') }}</th>
                                    <th>{{ $t('page.statistiques.end_date') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="task in tasks" :key="task.id">
                                    <td>{{ task.name }}</td>
                                    <td>{{ task.assigne ? task.assigne.name : 'No assigne' }}</td>
                                    <td>
                                        <span v-if="task.status == 'Completed'" class="badge bg-success">{{ task.status }}</span>
                                        <span v-else-if="task.status == 'In progress'" class="badge bg-primary">{{ task.status }}</span>
                                        <span v-else class="badge bg-warning">{{ task.status }}</span>
                                    </td>
                                    <td><span class="badge bg-secondary">{{ task.validated_date ? $formatDateDDMMYYYYHHMM(task.validated_date) : 'No validated' }}</span></td>
                                    <td>{{ task.point }}/20</td>
                                    <td>{{ formatNumber(task.cost) }}</td>
                                    <td>{{ task.estimated_duration }}  {{ $t('page.statistiques.days') }}</td>
                                    <td>{{ $formatDateDDMMYYYY(task.start_date) }}</td>
                                    <td>{{ $formatDateDDMMYYYY(task.end_date) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!tasks.length">
                            <div class="alert alert-info alert-dismissible" role="alert">
                                <div class="alert-icon">
                                    <i class="far fa-fw fa-bell"></i>
                                </div>
                                <div class="alert-message">
                                    <strong>{{ $t('page.statistiques.no_task') }}</strong>{{ $t('page.statistiques.project_no_task') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="!show_tasks" class="card p-4">
                    <h5 class="card-title">{{ $t('page.statistiques.no_task') }}</h5>
                    <div>
                        <table class="table table-striped datatables-reponsive-projects">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.statistiques.name') }}</th>
                                    <th>{{ $t('page.statistiques.status') }}</th>
                                    <th>{{ $t('page.statistiques.cost') }}</th>
                                    <th title="Customer note">{{ $t('page.statistiques.note') }}</th>
                                    <th>{{ $t('page.statistiques.start_date') }}</th>
                                    <th>{{ $t('page.statistiques.end_date') }}</th>
                                    <th>{{ $t('page.statistiques.nombre_tache') }}</th>
                                 <th>{{ $t('page.statistiques.action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in projects" :key="project.id">
                                    <td>{{ project.name }}</td>
                                    <td>
                                        <span v-if="project.status == 'Completed'" class="badge bg-success">{{ project.status }}</span>
                                        <span v-else-if="project.status == 'In progress'" class="badge bg-primary">{{ project.status }}</span>
                                        <span v-else class="badge bg-warning">{{ project.status }}</span>
                                    </td>
                                    <td>{{ formatNumber(project.cost) }}</td>
                                    <td>{{ project.point }}/20</td>
                                    <td>{{ $formatDateDDMMYYYY(project.start_date) }}</td>
                                    <td>{{ $formatDateDDMMYYYY(project.end_date) }}</td>
                                    <td>{{ project.nb_tasks }}</td>
                                    <td>
                                        <button v-if="show_tasks" type="button" class="btn btn-sm btn-danger"
                                            @click="toggleTasks(project)">
                                            <span >-</span>
                                        </button>
                                        <button v-else type="button" class="btn btn-sm btn-primary"
                                            @click="toggleTasks(project)">
                                            +
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Chart from 'chart.js/auto';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
export default {
    name: 'ProjectStatistique',
    data() {
        return {
            statistiques: {},
            project_stats: {},
            startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            project_select: 'all',
            users: null,
            nb_tasks: null,
            cost_total: null,
            tasks_by_project: null,
            general: [],
            currentPage: 2,
            projects: [],
            tasks: [],
            show_tasks: false
        }
    },
    async mounted() {
        await this.loadData()
        // Initialisation du sélecteur de plage de dates
        flatpickr('#date-range', {
            mode: 'range',

            onChange: (selectedDates, dateStr, instance) => {
                const start = selectedDates[0]
                const end = selectedDates[1]
                if (start && end) {
                    this.startDate = moment(start).format('YYYY-MM-DD')
                    this.endDate = moment(end).format('YYYY-MM-DD')

                    this.loadData()
                }
            },
        });
        const options = {
            responsive: true,
            lengthChange: true,
            select: {
                style: "multi"
            },
            lengthMenu:[5,10,15,20,25, 50],
        };
        let projectTable = new DataTable('.datatables-reponsive-projects', options);  // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('statistique', ['stats', 'statsProject', 'getGeneralStats']),
        async loadData() {
            let statistiquesResponse = await this.stats({ startDate: this.startDate, endDate: this.endDate })
            let generalResponse = await this.getGeneralStats({ startDate: this.startDate, endDate: this.endDate })
            let project_statReponse = await this.statsProject({ project: this.project_select, startDate: this.startDate, endDate: this.endDate })
            this.statistiques = statistiquesResponse
            this.project_stats = project_statReponse
            this.general = generalResponse
            this.users = this.project_stats.users
            this.nb_tasks = this.project_stats.nb_tasks
            this.cost_total = this.project_stats.cost_total
            this.tasks_by_project = this.project_stats.tasks_by_project
            this.projects = this.project_stats.tasks_by_project
            this.drawCharts()
        },
        changePage(number) {
            this.currentPage = number
        },
        toggleTasks(project=null) {
            this.show_tasks = !this.show_tasks;
            if (this.show_tasks) {
                this.tasks = project.tasks;
            } else {
                this.tasks = [];
            }
        },
        drawCharts() {
            this.drawProjectCostChart()
            this.drawCategoryCostChart()
            this.drawServiceCostChart()
            this.drawGradeCostChart()
            this.drawUserCountChart()
            this.drawCostChart()
            this.drawTaskCostChart()
        },
        formatNumber(number) {
            const suffixes = ["", "K", "M", "B", "T"];
            const suffixNum = Math.floor(("" + number).length / 3);
            const shortNumber = parseFloat((suffixNum != 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(3));
            const suffix = suffixes[suffixNum];
            return shortNumber + suffix;
        },
        drawProjectCostChart() {
            const chartElement = document.getElementById('project-cost-chart')

            // Vérifier si l'élément DOM existe
            if (chartElement !== null) {
                // Détruire le graphique existant s'il y en a un
                const existingChart = Chart.getChart(chartElement)
                if (existingChart !== undefined) {
                    existingChart.destroy()
                }

                const labels = Object.keys(this.cost_total)
                const data = Object.values(this.cost_total)
                const chartData = {
                    labels,
                    datasets: [{
                        label: 'Statistique Général',
                        borderColor: this.getRandomColor(),
                        borderWidth: 1,
                        data,
                        fill: true,
                    }]
                }

                const chartOptions = {
                    scales: {
                        y: {
                            beginAtZero: true,
                        }
                    }
                }

                this.projectCostChart = new Chart(chartElement, {
                    type: 'line',
                    data: chartData,
                    options: chartOptions
                })
            }
        },
        drawServiceCostChart() {
            if (this.serviceCostChart !== undefined) {
                this.serviceCostChart.destroy();
            }
            const Grades = this.general.services;

            const labels = [];
            const data = [];

            Grades.forEach(item => {
                labels.push(item.name);
                data.push(item.total_projects);
            });
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Total Projet',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data,
                    },
                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.serviceCostChart = new Chart(document.getElementById('service-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        drawGradeCostChart() {
            if (this.gradeCostChart !== undefined) {
                this.gradeCostChart.destroy();
            }
            const Grades = this.general.grades;

            const labels = [];
            const data1 = [];
            const data2 = [];

            Grades.forEach(item => {
                labels.push(item.name);
                data1.push(item.total_cost);
                data2.push(item.total_users);
            });
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Number',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data1,
                    },
                    {
                        type: 'bar',
                        label: 'Cost',
                        backgroundColor: this.getRandomColor(),
                        data: data2,
                    },
                ]
            }
            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.gradeCostChart = new Chart(document.getElementById('grade-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        drawCategoryCostChart() {
            if (this.categoryCostChart !== undefined) {
                this.categoryCostChart.destroy();
            }
            const Grades = this.general.categories;

            const labels = [];
            const data = [];

            Grades.forEach(item => {
                labels.push(item.name);
                data.push(item.total_services);
            });
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Number',
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data,
                    },
                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.categoryCostChart = new Chart(document.getElementById('category-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        drawUserCountChart() {
            if (this.UserCostChart !== undefined) {
                this.UserCostChart.destroy();
            }
            const UsersTypes = this.general.user_types;

            const labels = [];
            const data = [];

            UsersTypes.forEach(item => {
                labels.push(item.name);
                data.push(item.total_users);
            });

            const chartData = {
                labels,
                datasets: [{
                    label: 'Number',
                    type: 'line',
                    borderColor: this.getRandomColor(),
                    fill: true,
                    data: data,
                }]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.UserCostChart = new Chart(document.getElementById('user-count-chart'), {
                type: 'bar',
                data: chartData,
                options: chartOptions
            })
        },
        drawCostChart() {
            if (this.costChart_ !== undefined) {
                this.costChart_.destroy();
            }
            const labels = Object.keys(this.tasks_by_project).map((key) => {
                return this.tasks_by_project[key].name
            })
            const data = Object.keys(this.tasks_by_project).map((key) => {
                return this.tasks_by_project[key].cost
            })
            const data1 = Object.keys(this.tasks_by_project).map((key) => {
                return this.tasks_by_project[key].nb_tasks
            })

            const chartData = {
                labels,
                datasets: [{
                    label: 'Cost',
                    type: 'line',
                    borderColor: this.getRandomColor(),
                    fill: true,
                    data: data,
                },
                {
                    type: 'bar',
                    label: 'Nb Tasks',
                    fill: true,
                    backgroundColor: this.getRandomColor(),
                    data: data1,
                }]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.costChart_ = new Chart(document.getElementById('cost-chart'), {
                type: 'bar',
                data: chartData,
                options: chartOptions
            })
        },
        drawTaskCostChart() {
            if (this.TaskcostChart !== undefined) {
                this.TaskcostChart.destroy();
            }
            const taskStats = this.general.taskStats;
            const data = [];
            const data1 = [];
            const data2 = [];
            const labels = []

            taskStats.forEach(item => {
                labels.push(item.status);
                data.push(item.count);
                data1.push(item.total_cost);
                data2.push(item.average_cost);
            });


            const chartData = {
                labels,
                datasets: [
                    {
                        label: 'Count',
                        type: 'bar',
                        backgroundColor: this.getRandomColor(),
                        fill: true,
                        data: data,
                    },
                    {
                        type: 'line',
                        label: 'Total Cost',
                        fill: true,
                        borderColor: this.getRandomColor(),
                        data: data1,
                    },
                    {
                        type: 'line',
                        label: 'Average Cost',
                        fill: true,
                        borderColor: this.getRandomColor(),
                        data: data2,
                    },

                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.TaskcostChart = new Chart(document.getElementById('task-cost-chart'), {
                type: 'bar',
                data: chartData,
                options: chartOptions
            })
        },
        calculAvg(min, max) {
            if (max > 0) {
                let amount = ((min * this.statistiques.total_cost) / max)
                return ((amount * 100) / this.statistiques.total_cost)
            }
            return 0;
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
}
</script>
<style>
.div-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div-item {
    width: 100%;
}

.chart-container {
    height: 300px;
}
</style>