<script>
import { mapGetters } from 'vuex';

export default {
  name: "PermissionsVue",
  data() {
    return {
      permission_list: [],
    };
},
  computed: {
    ...mapGetters('permission', ['getPermissions']),
  },
  methods: {
    $can(permissionKey) {
        if (this.getPermissions.length) {
            
          const permission = this.getPermissions.find(
            (perm) => perm.permission_key === permissionKey
          );
          return permission ? permission.has_permission : false;
        }
        return false;
    },
  },
};
</script>
