import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {

  getAppParams({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('app-params')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  editAppParams({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('app-params-edit', params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  checkedParams({ commit, dispatch, state }, param_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('checked-params/' + param_id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateParamsContent({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-params-content/' + params.id, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setCronParams({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('set-params-cron/' + params.id, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getParam({ commit, dispatch, state }, param) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-params/' + param)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
