<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <div class="col-auto d-none d-sm-block">
                        <h3 v-if="getCurrentUser?.user_type.name == 'Head'">
                            <strong>
                                <router-link :to="{ name: 'project-detail', params: { id: project?.id } }">
                                    {{ project.name }}
                                </router-link> - {{ task.service_task.name }}</strong></h3>
                        <h3 v-else><strong>{{ project.name }} - {{ task.service_task.name }}</strong></h3>
                    </div>
                    <div class="col-auto ms-auto text-end mt-n1">
                        <button @click="goBack" class="btn btn-secondary" title="Back"><i
                                class="align-middle fas fa-fw fa-arrow-left"></i></button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-3 col-xxl d-flex">
                    <div class="card flex-fill">
                        <div class="card-body">
                            <div class="row">
                                <div class="col mt-0">
                                    <h5 class="card-title">{{ $t('page.task-detail.duration') }}</h5>
                                </div>

                                <div class="col-auto">
                                    <div class="stat" style="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor"
                                            class="bi bi-stopwatch" viewBox="0 0 16 16">
                                            <path
                                                d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                            <path
                                                d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <h4 class="mt-0 mb-1">{{ project.stats.total_hours }} {{ $t('page.task-detail.hours') }}</h4>

                            <div class="mb-0">
                                <span class="text-muted">{{ $t('page.task-detail.status') }} : </span>
                                <span class="text-muted">
                                    <span v-if="project.status == 'Closed'" class="badge bg-success">{{ project.status
                                    }}</span>
                                    <span class="badge bg-warning">{{ project.status }}</span>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-xxl d-flex">
                    <div class="card flex-fill">
                        <div class="card-body">
                            <div class="row">
                                <div class="col mt-0">
                                    <h5 class="card-title">{{ $t('page.task-detail.remaining_duration') }}</h5>
                                </div>

                                <div class="col-auto">
                                    <div class="stat" style="background: #F7931A; color: white;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                            class="bi bi-train-freight-front" viewBox="0 0 16 16">
                                            <path
                                                d="M5.065.158A1.5 1.5 0 0 1 5.736 0h4.528a1.5 1.5 0 0 1 .67.158l3.237 1.618a1.5 1.5 0 0 1 .83 1.342V13.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V3.118a1.5 1.5 0 0 1 .828-1.342L5.065.158ZM2 9.372V13.5A1.5 1.5 0 0 0 3.5 15h4V8h-.853a.5.5 0 0 0-.144.021L2 9.372ZM8.5 15h4a1.5 1.5 0 0 0 1.5-1.5V9.372l-4.503-1.35A.5.5 0 0 0 9.353 8H8.5v7ZM14 8.328v-5.21a.5.5 0 0 0-.276-.447l-3.236-1.618A.5.5 0 0 0 10.264 1H5.736a.5.5 0 0 0-.223.053L2.277 2.67A.5.5 0 0 0 2 3.118v5.21l1-.3V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3.028l1 .3Zm-2-.6V5H8.5v2h.853a1.5 1.5 0 0 1 .431.063L12 7.728ZM7.5 7V5H4v2.728l2.216-.665A1.5 1.5 0 0 1 6.646 7H7.5Zm-1-5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Zm-3 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm9 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM5 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm7 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <h4 class="mt-0 mb-1">{{ project.stats.remaining_hours }} {{ $t('page.task-detail.hours') }}</h4>

                            <div class="mb-0">
                                <span class="text-muted text-primary">{{ project.stats.progress }}% {{ $t('page.task-detail.time') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-xxl d-flex">
                    <div class="card flex-fill">
                        <div class="card-body">
                            <div class="row">
                                <div class="col mt-0">
                                    <h5 class="card-title">{{ $t('page.task-detail.date') }}</h5>
                                </div>

                                <div class="col-auto">
                                    <div class="stat" style="background: #345D9D; color: white;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-calendar-week" viewBox="0 0 16 16">
                                            <path
                                                d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                            <path
                                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <h4 class="mt-0 mb-1">{{ $t('page.task-detail.end') }}: {{ formatDate(project.end_date) ? formatDate(project.end_date) :
                                '-- -- ----- ' }}</h4>

                            <div class="mb-0">
                                <span class="text-muted">{{ $t('page.task-detail.start') }}: {{ formatDate(project.start_date) ?
                                    formatDate(project.start_date) : '-- -- ----' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-xxl d-flex">
                    <div class="card flex-fill">
                        <div class="card-body">
                            <div class="row">
                                <div class="col mt-0">
                                    <h5 class="card-title">{{ $t('page.task-detail.users') }}</h5>
                                </div>

                                <div class="col-auto">
                                    <div class="stat" style="background: #627EEA; color: white;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                            class="bi bi-person" viewBox="0 0 16 16">
                                            <path
                                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <h4 class="mt-0 mb-1">{{ project.stats.num_users }} {{ $t('page.task-detail.users') }}</h4>

                            <div class="mb-0">
                                <span class="text-muted"><span class="badge bg-success">{{ project.stats.completed_task
                                }}
                                        {{ $t('page.task-detail.task_finished') }}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title mb-0">
                                <div class="flex-grow-1">
                                    <strong>{{ $t('page.task-detail.task') }}({{ task.service_task.name }}) </strong><br />
                                </div>
                            </h5>
                        </div>
                        <div class="card-body h-100">
                            <div class="d-flex align-items-start">
                                <div class="flex-grow-1">
                                    <strong>{{ $t('page.task-detail.description') }}</strong><br />
                                </div>
                            </div>
                            <div class="card p-2 mt-1">
                                <p>{{ task.service_task.description }}</p>
                                <span>{{ $t('page.task-detail.estimated_duration') }}: <span class="text-primary">{{ task.estimated_duration }} {{ $t('page.task-detail.hours') }}</span></span>
                                <div v-if="task.depend" class="form-check mt-2">
                                    <input class="form-check-input" type="checkbox" id="dependencyCheckbox" @click="removeOrAddDependance" v-model="task.can_execute_dependent_before">
                                    <label class="form-check-label" for="dependencyCheckbox">Execute dependent task first</label>
                                </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-start">
                                <div class="flex-grow-1">
                                    <strong>{{ $t('page.task-detail.assigne') }}:</strong>
                                    <strong class="float-end text-navy">
                                        {{ task.assigne ? task.assigne.name : '---' }} <br>
                                        <span>
                                            <button v-if="(!task.assigne) && ($can('can_assign_task_to_user'))" data-bs-toggle="modal" @click="changeType('user')"
                                                data-bs-target="#add-user-to-task-modal"
                                                class="btn btn-sm btn-info rounded borded">{{ $t('page.task-detail.assigned_task') }}</button>
                                            <button v-if="(task.status != 'Completed') && task.assigne && ($can('can_assign_task_to_user'))"
                                                data-bs-toggle="modal" @click="changeType('user')"
                                                data-bs-target="#add-user-to-task-modal"
                                                class="btn btn-sm btn-outline-warning rounded borded">{{ $t('page.task-detail.Reassigned_task') }}</button>
                                        </span>
                                    </strong>
                                </div>
                            </div>
                            <div class="d-flex align-items-start pt-2">
                                <div class="flex-grow-1">
                                    <strong class="text-muted text-sm">{{ formatDate(task.start_date) }} - {{
                                        formatDate(task.end_date) }}</strong>
                                    <strong class="float-end text-navy">
                                        <span class="text-muted">
                                            <span v-show="task.status == 'Pending'" class="badge bg-danger">{{
                                                task.status.toUpperCase() }}</span>
                                            <span v-show="task.status == 'In progress'" class="badge bg-warning">{{
                                                task.status.toUpperCase() }}</span>
                                            <span v-show="task.status == 'Completed'" class="badge bg-success">{{
                                                task.status.toUpperCase() }}</span>
                                            <span v-show="task.status == 'Rejected'" class="badge bg-danger">{{
                                                task.status.toUpperCase() }}</span>
                                            <span v-show="task.status == 'On pause'" class="badge bg-warning">{{
                                                task.status.toUpperCase() }}</span>
                                        </span>
                                    </strong>
                                </div>
                            </div>
                            <hr />
                            <div v-if="(task.can_execute_dependent_before) && (task.depend)">
                                <div class="d-flex align-items-start">
                                    <div class="flex-grow-1">
                                        <strong>{{ $t('page.task-detail.depend') }}:</strong>
                                        <strong class="float-end text-navy">
                                            {{ task.depend ? task.dependent.service_task?.name : '---' }}
                                        </strong>
                                    </div>
                                </div>
                                <div class="d-flex align-items-start pt-2">
                                    <div class="flex-grow-1">
                                        <strong class="text-muted text-sm">
                                            {{ task.depend ? formatDateTimeLocal(task.dependent.start_date) : '---' }}
                                            - {{ task.depend ? formatDateTimeLocal(task.dependent.end_date) : '---' }}
                                        </strong>
                                        <strong class="float-end text-navy">
                                            <span class="text-muted">
                                                <span class="badge bg-success">{{ task.depend ?
                                                    task.dependent?.status?.toUpperCase() : '---' }}</span>
                                            </span>
                                        </strong>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="d-flex align-items-start">
                                <div class="flex-grow-1">
                                    <strong>{{ $t('page.task-detail.supervisor') }}:</strong>
                                    <strong class="float-end text-navy">
                                        {{ task.supervisor ? task.supervisor.name : '---' }}<br>
                                        <button v-if="(!task.supervisor) && ($can('can_assign_task_to_manager'))" @click="changeType('supervisor')"
                                            data-bs-toggle="modal" data-bs-target="#add-user-to-task-modal"
                                            class="btn btn-sm btn-primary rounded borded">{{ $t('page.task-detail.add_supervisor') }}</button>
                                    </strong>
                                </div>
                            </div>
                            <!-- <div class="d-flex align-items-start pt-2">
                                <div class="flex-grow-1">
                                    <strong class="text-muted text-sm">Deadline : {{ formatDate(task.validation_deadline) }}
                                    </strong>
                                    <strong class="float-end text-navy">
                                        <span class="text-muted">
                                            <span class="badge bg-danger">{{ task.validation_duration }} heures
                                            </span>
                                        </span>
                                    </strong>
                                </div>
                            </div> -->
                            <hr />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="col-auto ms-auto text-end mb-n1">
                        <button @click="changeSection(1)" :class="section == 1 ? 'btn-primary' : 'btn-light bg-white'"
                            class="btn me-2">  {{ $t('page.task-detail.historique') }}  </button>
                        <button @click="changeSection(2)" :class="section == 2 ? 'btn-primary' : 'btn-light bg-white'"
                            class="btn">{{ $t('page.task-detail.discussions') }}</button>
                    </div>
                    <div v-show="section == 2" class="card">
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{ $t('page.task-detail.discussions') }}</h5>
                        </div>
                        <div class="card-body h-100">
                            <div v-if="comments.length" class="chat-messages p-4">
                                <div v-for="comment in comments" :key="comment.id">
                                    <div v-if="comment.user.id != getCurrentUser.id" class="chat-message-right pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                                            <div class="font-weight-bold mb-1"><strong>{{ comment.user.name }}</strong></div>
                                            {{ comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(comment.created_at) }}</div>
                                        </div>
                                    </div>

                                    <div v-else class="chat-message-left pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                                            {{ comment.comment }}
                                            <div class="font-weight-bold mb-1"><strong>{{ $t('page.task-detail.you') }}</strong></div>
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(comment.created_at) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-messages p-4" v-else>
                                <div class="alert alert-warning alert-dismissible" role="alert">
                                    <div class="alert-icon">
                                        <i class="far fa-fw fa-bell"></i>
                                    </div>
                                    <div class="alert-message">
                                        <strong>{{ $t('page.task-detail.no_comment') }}</strong>{{ $t('page.task-detail.add_comment') }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="task.supervisor" class="flex-grow-0 py-3 px-4 border-top">
                                <div class="input-group">
                                    <textarea v-model="commentFormData.comment" class="form-control" rows="2"
                                        placeholder="Enter your comment ..."></textarea>
                                </div>
                                <div class="mb-3 text-end">
                                    <button @click="sendCommentTask" class="btn btn-primary mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-send align-middle">
                                            <line x1="22" y1="2" x2="11" y2="13"></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                        {{ $t('page.task-detail.send') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="section == 1" class="card">
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{ $t('page.task-detail.historique') }}</h5>
                        </div>
                        <div class="card-body h-100">
                            <div v-if="taskHistories.length" class="chat-messages p-4">
                                <div v-for="(history, key) in taskHistories" :key="key">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <small class="float-end text-navy">{{ formatDateAgo(history.date) }}</small>
                                            {{ history.history_text }}<strong> {{ history.name }}</strong><br />
                                            <small class="text-muted">Le : {{ formatDateTimeLocal(history.date)
                                            }}</small><br />

                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                            <div class="chat-messages p-4" v-else>
                                <div class="alert alert-info alert-dismissible" role="alert">
                                    <div class="alert-icon">
                                        <i class="far fa-fw fa-bell"></i>
                                    </div>
                                    <div class="alert-message">
                                        <strong>{{ $t('page.task-detail.no_history') }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="add-user-to-task-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content bg-light">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ $t('page.task-detail.assignment_task') }}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body m-3">
                                <form @submit.prevent="updateTaskConfig" class="row g-3 needs-validation">
                                    <div v-if="typeSubmit == 'user'" class="card m-1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="form-label">
                                                    <span>{{ $t('page.task-detail.select_user') }}</span>
                                                </label>
                                                <select v-model="assigneFormData.user"
                                                    class="form-control choices-single-depends" required>
                                                    <option selected value="" class="text-muted">{{ $t('page.task-detail.select_user') }}...</option>
                                                    <option v-for="user in allUsers" :key="user.id" :value="user.id">{{
                                                        user.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label">
                                                    <span>{{ $t('page.task-detail.start_date') }}</span>
                                                </label>
                                                <input :disabled="assigneFormData.start_date" type="datetime-local"
                                                    v-model="assigneFormData.start_date" class="form-control"
                                                    placeholder="Start date" required
                                                    :min="assigneFormData.start_date ? getMinDate() : ''">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label">
                                                    <span>{{ $t('page.task-detail.end_date') }}</span>
                                                </label>
                                                <input type="datetime-local" v-model="assigneFormData.end_date"
                                                    class="form-control" placeholder="End date" required
                                                    :min="getMinDate()">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="card m-1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="form-label">
                                                    <span>{{ $t('page.task-detail.select_supervisor') }}</span>
                                                </label>
                                                <select v-model="supervisorFormData.supervisor"
                                                    class="form-control choices-single-depends" required>
                                                    <option selected value="" class="text-muted">Select Supervisor...
                                                    </option>
                                                    <option v-for="supervisor in supervisors" :key="supervisor.id"
                                                        :value="supervisor.id">{{ supervisor.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-label">
                                                    <label for="validation_deadline"><span>{{ $t('page.task-detail.deadline') }}</span></label>
                                                    <input
                                                    type="datetime-local"
                                                    id="validation_deadline"
                                                    v-model="supervisorFormData.validation_deadline"
                                                    :min="minValidationDeadline"
                                                    class="form-control"
                                                    @input="calculateValidationDuration"
                                                    required
                                                    >
                                                </div>
                                                <div class="form-label">
                                                    <label><span>{{ $t('page.task-detail.deadline_duration') }}</span></label>
                                                    <input
                                                    type="number"
                                                    v-model="supervisorFormData.validation_duration"
                                                    class="form-control"
                                                    readonly
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 text-end">
                                        <button v-show="isStart" class="btn btn-primary">
                                            <div class="spinner-border spinner-border-sm text-danger me-2" role="status">
                                            </div>
                                            <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                        </button>
                                        <button class="btn btn-primary" type="submit">Save tasks</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'Warning'" :message="'Please wait'"></empty-page-component>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
export default {
    name: 'ProjectDetail',
    components: {
        EmptyPageComponent
    },
    data() {
        return {
            project: null,
            task: null,
            user: {},
            isDispo: false,
            comments: [],
            commentFormData: {
                user: null,
                activity_id: this.$route.params.id,
                type: 'task',
                comment: null
            },
            assigneFormData: {
                user: null,
                start_date: null,
                end_date: null,
                task_id: this.$route.params.id
            },
            supervisorFormData: {
                supervisor: null,
                task_id: this.$route.params.id,
                validation_deadline: null,
                validation_duration: 0,
            },
            allUsers: [],
            supervisors: [],
            typeSubmit: 'user',
            taskHistories: [],
            section: 1
        };
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        minValidationDeadline() {
            return this.task ? moment(this.task.end_date).add(24, 'hour').format('YYYY-MM-DDTHH:mm') : '';
        }
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        this.initProjectDetails()
        this.initTaskHistory()
        this.initComment()
    },
    methods: {
        ...mapActions('auth', ['_currentUser', 'getUsersByUserTypeName', 'users']),
        ...mapActions('project', ['getProject']),
        ...mapActions('task_assignment', ['getTask', 'assigneeTaskToUser', 'addSupervisorToTask', 'taskHistory', 'updateTaskDependanceState']),
        ...mapActions('comment', ['getActivityComments', 'addComment']),
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        getMinDate() {
            const now = new Date()
            const year = now.getFullYear()
            const month = String(now.getMonth() + 1).padStart(2, '0')
            const day = String(now.getDate()).padStart(2, '0')
            const hours = String(now.getHours()).padStart(2, '0')
            const minutes = String(now.getMinutes()).padStart(2, '0')
            return `${year}-${month}-${day}T${hours}:${minutes}`
        },
        goBack() {
            window.history.go(-1);
        },
        changeSection(number) {
            this.section = number
        },
        formatDateAgo(dateToFormat) {
            const momentDate = moment(dateToFormat);
            const now = moment();
            const diffDuration = moment.duration(now.diff(momentDate));

            let formattedDate = '';

            const years = diffDuration.years();
            const months = diffDuration.months();
            const days = diffDuration.days();
            const hours = diffDuration.hours();
            const minutes = diffDuration.minutes();

            if (years > 0) {
                formattedDate += `${years} an${years > 1 ? 's' : ''} `;
            }
            if (months > 0) {
                formattedDate += `${months} mois `;
            }
            if (days > 0) {
                formattedDate += `${days} jour${days > 1 ? 's' : ''} `;
            }
            if (hours > 0) {
                formattedDate += `${hours}h `;
            }
            formattedDate += `${minutes}min ago`;
            return formattedDate;
        },
        calculateValidationDuration() {
            if (this.supervisorFormData.validation_deadline && this.task.end_date) {
                const deadline = new Date(this.supervisorFormData.validation_deadline);
                const endDate = new Date(this.task.end_date);
                if (deadline <= endDate) {
                this.supervisorFormData.validation_duration = 0;
                this.$errorNotyf('Validation deadline must be after the task end date');
                } else {
                const durationMs = deadline - endDate;
                this.supervisorFormData.validation_duration = Math.max(0, Math.round(durationMs / (1000 * 60 * 60)));
                }
            } else {
                this.supervisorFormData.validation_duration = 0;
            }
        },
        async removeOrAddDependance(){
            let task_id = this.$route.params.id
            try {
                await this.updateTaskDependanceState(task_id)
                this.$successNotyf('Successful')
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async initProjectDetails() {
            try {
                let task_id = this.$route.params.id
                this.task = await this.getTask(task_id)
                this.project = await this.getProject(this.task.project.id)
                this.allUsers = await this.users()
                let filterName = 'Partener'
                this.allUsers = this.allUsers.filter(user => {
                    return user.user_type.name !== filterName;
                });
                this.users = await this.getUsersByUserTypeName('User')
                this.supervisors = await this.getUsersByUserTypeName('Supervisor')
                this.assigneFormData.user = this.task.assigne ? this.task.assigne.id : null
                this.assigneFormData.start_date = this.task.start_date ? this.formatDateTimeLocal2(this.task.start_date) : null
                this.assigneFormData.end_date = this.task.end_date ? this.formatDateTimeLocal2(this.task.end_date) : null
                this.isDispo = true
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async initTaskHistory() {
            try {
                this.taskHistories = await this.taskHistory(this.$route.params.id)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async initComment() {
            try {
                let data = {
                    activity_id: this.$route.params.id,
                    type: 'task'
                }
                this.comments = await this.getActivityComments(data)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        formatDateTimeLocal(date) {
            return date ? moment(date).format("DD-MM-YYYY HH:mm") : null;
        },
        formatDateTimeLocal2(date) {
            return date ? moment(date).format("YYYY-MM-DD HH:mm") : null;
        },
        formatCommentDate(date) {
            let formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
            return formattedDate.format('DD-MM-YYYY h:mm');
        },
        async sendCommentTask() {
            this.commentFormData.user = this.getCurrentUser.id
            try {
                await this.addComment(this.commentFormData)
                this.commentFormData.comment = null
                this.initComment()
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        changeType(type) {
            this.typeSubmit = type
        },
        async updateTaskConfig() {
            this.isStart = true
            try {
                if (this.typeSubmit == 'supervisor') {
                    await this.addSupervisorToTask(this.supervisorFormData)
                    this.supervisorFormData.supervisor = null
                    this.task = await this.getTask(this.supervisorFormData.task_id)
                    this.supervisorFormData.validation_deadline = null
                    this.supervisorFormData.validation_duration = null
                } else {
                    await this.assigneeTaskToUser(this.assigneFormData)
                    this.task = await this.getTask(this.assigneFormData.task_id)
                }
                jQuery('#add-user-to-task-modal').modal('toggle'); // eslint-disable-line no-undef
                this.$successNotyf('Assignment successfully completed')
                    this.supervisorFormData.validation_deadline = null
                    this.supervisorFormData.validation_duration = null
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        }
    }
}
</script>
<style></style>
    