<template>
    <div>
        <div class="row text-center container">
            <div class="alert" role="alert">
                <div class="alert-message">
                    <h5>{{ this_today }}</h5>
                    <h1>Hello, {{ getCurrentUser.name }}</h1>
                    {{ $t('component.AuthPageComponent.UserPageComponent.manage_your') }}
                    <div class="row mt-4 rounded">
                        <div class="col-sm-3"></div>
                        <div class="card col-sm-6 pt-2 pb-2">
                            <div class="btn-group row">
                                <div class="col-6">
                                    <select class="form-select flex-grow-1" v-model="period">
                                        <option value="week">   {{ $t('component.AuthPageComponent.UserPageComponent.week') }}</option>
                                        <option value="month">   {{ $t('component.AuthPageComponent.UserPageComponent.month') }}</option>
                                        <option value="year">   {{ $t('component.AuthPageComponent.UserPageComponent.year') }}</option>
                                    </select>
                                </div>
                                <div class="col-6 pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check2-all" viewBox="0 0 16 16">
                                        <path
                                            d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                    </svg>
                                    {{ completedTasksCount }}    {{ $t('component.AuthPageComponent.UserPageComponent.task_completed') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill p-4">
                    <div class="card-header">
                        <h5 class="card-title mb-0">   {{ $t('component.AuthPageComponent.UserPageComponent.my_performances') }}</h5>
                    </div>
                    <div v-show="!filteredTasks.length">
                        <div class="alert alert-info alert-dismissible" role="alert">
                            <div class="alert-icon">
                                <i class="far fa-fw fa-bell"></i>
                            </div>
                            <div class="alert-message">
                                <strong>   {{ $t('component.AuthPageComponent.UserPageComponent.no_task_found') }}</strong>
                            </div>
                        </div>
                    </div>
                    <div v-show="filteredTasks.length">
                        <table id="datatables-buttons" class="table table-hover my-0">
                            <thead>
                                <tr>
                                    <th>   {{ $t('component.AuthPageComponent.UserPageComponent.task') }}</th>
                                    <th>   {{ $t('component.AuthPageComponent.UserPageComponent.project') }}</th>
                                    <th class="d-xl-table-cell">   {{ $t('component.AuthPageComponent.UserPageComponent.date') }}</th>
                                    <th class="d-xl-table-cell">{{ $t('general.duration') }}</th>
                                    <th>{{ $t('general.status') }}</th>
                                    <th>{{ $t('general.action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="task in filteredTasks" :key="task.task.id">
                                    <td>{{ task.task.service_task.name }}</td>
                                    <td>{{ task.task.project.name }}</td>
                                    <td class="d-xxl-table-cell">
                                        <div class="text-muted">
                                            {{ $t('general.end') }}: {{ task.task.end_date }}
                                        </div>
                                        <strong>{{ $t('general.start') }}: {{ task.task.start_date }}</strong>
                                    </td>
                                    <td class="d-xl-table-cell">{{ task.details.duration }} hours</td>
                                    <td>
                                        <span v-show="task.task.is_validated" class="badge bg-success"> {{ $t('component.AuthPageComponent.UserPageComponent.validated') }}</span>
                                        <span v-show="!task.task.is_validated" class="badge bg-warning"> {{ $t('component.AuthPageComponent.UserPageComponent.pending') }}</span>
                                    </td>
                                    <td class="d-md-table-cell">
                                        <router-link class="btn btn-link" title="View" :to="{ name: 'task-detail', params: { id: task.task.id }}">
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-eye align-middle btn-link">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'UserPageComponent',
    data() {
        return {
            this_today: null,
            tasks: [],
            period: 'week'
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        filteredTasks() {

            // Vérifier si tasks est un tableau
            if (!Array.isArray(this.tasks)) {
                return [];
            }

            const today = new Date();

            const now = new Date(); // obtenir la date actuelle
            const dayOfWeek = now.getDay(); // obtenir le jour de la semaine (0 = dimanche, 1 = lundi, etc.)
            const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // déterminer le nombre de jours à ajouter ou soustraire pour obtenir le lundi
            const startOfWeek = new Date(now); // créer une nouvelle date à partir de la date actuelle
            startOfWeek.setDate(now.getDate() + diff); // ajouter ou soustraire le nombre de jours pour obtenir le lundi

            // filtrer les tâches en fonction de la période sélectionnée
            const filtered = this.tasks.filter(task => {
                const start = new Date(task.task.start_date);
                const end = new Date(task.task.end_date);

                if (this.period === 'week') {
                    const endOfWeek = new Date(startOfWeek);
                    endOfWeek.setDate(startOfWeek.getDate() + 6);
                    return start >= startOfWeek && end <= endOfWeek;
                }

                if (this.period === 'month') {
                    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    return start >= startOfMonth && end <= endOfMonth;
                }

                if (this.period === 'year') {
                    const startOfYear = new Date(today.getFullYear(), 0, 1);
                    const endOfYear = new Date(today.getFullYear(), 11, 31);
                    return start >= startOfYear && end <= endOfYear;
                }

                return true;
            });

            return filtered;
        },
        completedTasksCount() { // Appeler la fonction countCompletedTasks() et passer filteredTasks comme argument
            return this.countCompletedTasks(this.filteredTasks);
        }
    },
    async mounted() {
        let date = moment();
        let formattedDate = date.format('dddd D MMMM YYYY');
        this.this_today = formattedDate
        await this.initTasks()
    },
    methods: {
        ...mapActions('task_assignment', ['getUserTasks']),
        async initTasks() {
            this.tasks = await this.getUserTasks(this.getCurrentUser.id)
        },
        countCompletedTasks(filteredTasks) {
            if (!Array.isArray(filteredTasks)) {
                return 0;
            }
            const completedTasks = filteredTasks.filter(task => task.task.status === 'Completed');
            return completedTasks.length;
        }
    }
}
</script>
<style></style>
    