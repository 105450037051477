<template>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h3><strong>{{ $t('page.permissions.permissions') }}</strong></h3>
                                <span>{{ $t('page.permissions.permission_texte') }}</span>
                            </div>
                            <div>
                                <div class="btn-group" role="group" aria-label="Navigation">
                                    <button @click="goBack" class="btn btn-secondary" title="Back"><i
                                            class="align-middle fas fa-fw fa-arrow-left"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDispo">
            <div class="card">
                <div class="accordion" id="accordionPermission">
                    <div v-for="(permissions, category, index) in listPermissions" :key="category" class="accordion-item">
                        <h2 class="accordion-header" :id="category">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#' + category.toUpperCase()" aria-expanded="false"
                                :aria-controls="category">
                                {{ formatPermissionName(category?.toUpperCase()) }}
                            </button>
                        </h2>
                        <div :id="category.toUpperCase()" class="accordion-collapse collapse"
                            :class="index == 0 ? 'show' : ''" :aria-labelledby="category"
                            data-bs-parent="#accordionPermission">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-sm-4" v-for="permission in permissions"
                                        :key="permission.permission_key">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" v-if="$can('can_add_or_remove_user_role')"
                                                @click="addOrRemoveUserRole(permission.id, permission.has_permission)"
                                                type="checkbox" :id="permission.permission_key"
                                                v-model="permission.has_permission">
                                            <input class="form-check-input" v-else disabled
                                                type="checkbox" :id="permission.permission_key"
                                                v-model="permission.has_permission">
                                            <label class="form-check-label" :for="permission.permission_key">{{
                                                formatPermissionName(permission.permission_name) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-dark me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-secondary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-success me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-danger me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-warning me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
                <div class="spinner-grow text-info me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'usersPermissions',
    data() {
        return {
            isDispo: false,
            items: ['view_all', 'view', 'create', 'update', 'delete'],
            listPermissions: [],
        };
    },
    mounted() {
        this.initPage()
    },
    methods: {
        ...mapActions('auth', ['userStat', 'getUserPermissions']),
        ...mapActions('user_role', ['addUserRole']),
        async initPage() {
            let response = await this.getUserPermissions(this.$route.params.id)
            this.listPermissions = response
            this.isDispo = true
        },
        formatPermissionName(permissionName) {
            const words = permissionName.split('_');
            const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
            return capitalizedWords.join(' ');
        },
        async addOrRemoveUserRole(roleId, has_permission) {
            let data = {
                user_id: this.$route.params.id,
                permission_id: roleId
            }
            try {
                let response = await this.addUserRole(data)
                let type = 'success'
                if(has_permission){
                    type = 'warning'
                }
                this.$successNotyf(response.message, type)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        goBack() {
            window.history.go(-1);
        },
        handlePermissionChange(item, permissionId) {
            const index = this.items.findIndex(i => i.id === item.id);
            if (index > -1) {
                const itemPermissions = this.items[index].permissions;
                const permissionIndex = itemPermissions.indexOf(permissionId);
                if (permissionIndex > -1) {
                    // permission already exists,so remove it
                    itemPermissions.splice(permissionIndex, 1);
                } else {
                    // permission doesn't exist, add it
                    itemPermissions.push(permissionId);
                }
                // update the item in the items array
                this.items.splice(index, 1, item);
            }
        }
    }
};
</script>
  