import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {

  stats({ commit, dispatch, state }, statistiqueData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`stats?startDate=${statistiqueData.startDate}&endDate=${statistiqueData.endDate}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  statsProject({ commit, dispatch, state }, statistiqueData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`stats-project?project=${statistiqueData.project}&startDate=${statistiqueData.startDate}&endDate=${statistiqueData.endDate}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getGeneralStats({ commit, dispatch, state }, statistiqueData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`stats-general?startDate=${statistiqueData.startDate}&endDate=${statistiqueData.endDate}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  usersStats({ commit, dispatch, state }, user) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`users-stats?user=${user}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  topUserStats({ commit, dispatch, state }, number) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`top-users-stats?top=${number}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  topSupervisorStats({ commit, dispatch, state }, number) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`top-supervisors-stats?top=${number}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
