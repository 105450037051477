import axios from 'axios';
import { BASE_API_URL } from '../services/api';
import axiosWithHeaders from '../services/api';
import router from '../router/index'

const state = {
};

const getters = {
};

const mutations = {
};

const formatParams = (data) => {
  const { status, limit, page } = data;
  let queryParams = [];

  if (status) {
    queryParams.push(`status=${status}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }else{
    queryParams.push(`limit=all`);
  }

  if (page) {
    queryParams.push(`page=${page}`);
  }else{
    queryParams.push(`page=1`);
  }

  const queryString = queryParams.join('&');
  const url = queryString ? `?${queryString}` : '';
  return url;
};

const actions = {
  getUserDevices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user-devices${formatParams(data)}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getDevices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`all-devices${formatParams(data.params)}`, data.users)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  setDeviceStatus({commit}, device_id){
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('set-device-status/'+ device_id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
